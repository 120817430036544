import React, { ChangeEvent, FC, ReactNode, useRef, useState } from 'react';
import { AppToolTip } from 'src/components/AppToolTip';
import { isDark, isTouchDevice, shadeColor } from 'src/utils';
import { HexColorPicker } from 'react-colorful';
import { useClickOutside } from 'src/hooks/useClickOutside';
import { CategoryType } from 'src/config/types';
import { ReactComponent as TrashIcon } from 'src/assets/icons/kit/trash.svg';
import cn from 'classnames';
import './styles.css';

interface ZoneTagProps {
  category: CategoryType & { color?: string; icon?: ReactNode };
  fullWidth?: boolean;
  fitWidth?: boolean;
  isEditMode?: boolean;
  isActive?: boolean;
  index?: number;
  onClick?: (category: CategoryType) => void;
  onChange?: (category: CategoryType, index: number) => void;
  withColor?: boolean;
  classNames?: string;
  size?: 'big' | 'small';
  noShadow?: boolean;
  whiteText?: boolean;
  colorBottom?: boolean;
}

export const ZoneTag: FC<ZoneTagProps> = ({
  category,
  index,
  fullWidth,
  fitWidth,
  isActive,
  onChange,
  isEditMode,
  onClick,
  size = 'big',
  withColor,
  classNames,
  noShadow,
  whiteText,
  colorBottom,
}) => {
  const isTouch = isTouchDevice();
  const pickerRef = useRef<HTMLDivElement | null>(null);

  const [hover, setHover] = useState(false);
  const [active, setActive] = useState(false);
  const [isPickerOpened, setIsPickerOpened] = useState(false);
  const [categoryColor, setCategoryColor] = useState(category?.color || '#D8DCDE');
  const [inputValue, setInputValue] = useState(category?.name || '');

  const handleOpenPicker = () => setIsPickerOpened(true);
  const handleClosePicker = () => setIsPickerOpened(false);

  useClickOutside(pickerRef, handleClosePicker);

  const isEdit = Boolean(isEditMode);
  const isNew = category.flag === 'insert';

  const handleClick = () => {
    onClick && !isEdit && onClick(category);
  };

  const handleRemove = () => {
    if (onChange && typeof index === 'number') {
      onChange({ ...category, flag: 'delete' }, index);
    }
  };

  const handleChangeText = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleBlur = () => {
    if (onChange && typeof index === 'number') {
      onChange({ ...category, name: inputValue, flag: isNew ? category.flag : 'update' }, index);
    }
    setActive(false);
  };

  const handleChangeColor = (color: string) => {
    setCategoryColor(color);
    if (onChange && typeof index === 'number') {
      onChange({ ...category, color, flag: isNew ? category.flag : 'update' }, index);
    }
  };

  const color = withColor ? '#FF5500' : category?.color || '#FF5500';
  const darkerColor = shadeColor(color, -40);

  return (
    <div
      onClick={handleClick}
      className={cn(
        {
          'cursor-pointer': Boolean(onClick) && !isEdit,
          'w-full dark:bg-blue-200 dark:text-white': isEdit,
          'dark:border-blue-200 ': isEdit && !active,
          'hover:border-orange-100 dark:hover:border-orange-100': isEdit && !active && !isTouch,
          'border-grey-400': isEdit && !active,
          'border-orange-100': isEdit && active,
          'w-fit': !isEdit,
          'max-w-[5rem] dark:bg-blue-600': !isEdit && !Boolean(fullWidth),
          'max-w-fit w-full': !isEdit && Boolean(fitWidth),
          truncate: !isEdit && !withColor,
          'text-extra-xs': size === 'big',
          'text-ultra-xs': size === 'small',
        },
        'rounded border flex font-gothampro-500 transition-full relative',
        classNames,
      )}
      style={{
        ...(!isEdit && {
          color:
            (hover && !isTouch) || isActive
              ? whiteText
                ? isDark(color)
                  ? '#FFF'
                  : '#000'
                : darkerColor
              : color,
          borderColor: color,
          backgroundColor: (hover && !isTouch) || isActive ? color : 'transparent',
          boxShadow: isActive && !noShadow ? `3px 3px 3px 0px ${darkerColor} inset` : '',
        }),
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {category?.icon ? (
        <div
          className="h-6 w-6 min-w-[1.5rem] truncate border-r center bg-white transition-full"
          style={{
            borderColor: color,
            boxShadow: isActive ? `3px 3px 3px 0px ${darkerColor} inset` : '',
          }}
        >
          {category.icon}
        </div>
      ) : null}
      {!isEdit ? (
        <>
          <div
            className={cn(
              {
                'px-3 min-h-[1.5rem] h-[1.5rem]': size === 'big',
                'px-1.5 min-h-[1rem] h-[1rem]': size === 'small',
              },
              'truncate w-full flex items-center',
            )}
          >
            <AppToolTip checkWidth classNames="text-center w-full h-full" text={category.name} />
          </div>
          {withColor ? (
            <div
              className="h-full w-6 rounded-r"
              style={{ backgroundColor: category.color || '#7AA0CE' }}
            />
          ) : null}
        </>
      ) : null}

      {isEdit ? (
        <>
          <input
            className="px-3 py-1.5 w-full bg-transparent"
            onChange={handleChangeText}
            value={inputValue}
            onClick={() => setActive(true)}
            onBlur={handleBlur}
          />
          {withColor ? (
            <div
              onClick={handleOpenPicker}
              className="h-full square cursor-pointer relative"
              style={{ backgroundColor: categoryColor }}
            ></div>
          ) : null}
          <div
            className="h-full square center cursor-pointer ml-auto bg-orange-100 rounded-r"
            onClick={handleRemove}
          >
            <TrashIcon className="w-2.5 h-2.5 fill-white" />
          </div>
        </>
      ) : null}
      {isPickerOpened ? (
        <div
          ref={pickerRef}
          className={cn(
            {
              'bottom-7': !Boolean(colorBottom),
              'top-7': Boolean(colorBottom),
            },
            'absolute z-2300 right-2',
          )}
        >
          <HexColorPicker className="absolute w-10" color={color} onChange={handleChangeColor} />
        </div>
      ) : null}
    </div>
  );
};
