import { FC } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { setIsFavouritesActive } from 'src/store/common';
import { AppToolTip } from 'src/components/AppToolTip';

import { ReactComponent as StarIcon } from 'src/assets/icons/kit/star.svg';
import cn from 'classnames';

export const FavouritesButton: FC = () => {
  const { isFavouritesActive } = useAppSelector(state => state.common);

  const dispatch = useAppDispatch();

  const handleSetIsFavouritesActive = () => {
    dispatch(setIsFavouritesActive(!isFavouritesActive));
  };

  return (
    <AppToolTip
      text="Избранное"
      classNames={
        'h-7 w-7 min-w-7 px-1.5 rounded-full bg-yellow-100 flex group ' +
        'items-center justify-center gap-1 transition-full cursor-pointer'
      }
      onClick={handleSetIsFavouritesActive}
    >
      <StarIcon
        className={cn(
          {
            'fill-orange-100': isFavouritesActive,
            'fill-white dark:fill-blue-300': !isFavouritesActive,
          },
          'w-4 h-4 min-w-[1rem] ml-[1px] transition-full t-lg:group-hover:fill-orange-100',
        )}
      />
    </AppToolTip>
  );
};
