import { FC, Fragment, useEffect, useState } from 'react';
import { ZoneTag } from 'src/components/Zones/ZoneTag';
import { Button } from 'src/components/Button';
import { useAuth } from 'src/hooks/useAuth';
import { fetchAllInfo } from 'src/store/categories/actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { CategoryType, PolygonsFilterType } from 'src/config/types';
import CategoriesService from 'src/services/CategoriesService';
import { sleep } from 'src/utils';

interface OwnersFilterProps {
  handleSelect: (id: number, filterName: keyof PolygonsFilterType) => void;
  smoothScrollTo: (id: string) => void;
  tempFilter: PolygonsFilterType;
}

export const OwnersFilter: FC<OwnersFilterProps> = ({
  handleSelect,
  tempFilter,
  smoothScrollTo,
}) => {
  const dispatch = useAppDispatch();
  const { user } = useAuth();
  const { owners } = useAppSelector(state => state.categories);

  const [tempOwners, setTempOwners] = useState<CategoryType[]>(owners);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [showAll, setShowAll] = useState<boolean>(false);

  const handleScroll = async () => {
    await sleep(100);
    smoothScrollTo('filter-owner');
  };

  const handleOffEditMode = () => setIsEditMode(false);
  const handleShawAll = async () =>
    setShowAll(prev => {
      !prev && handleScroll();

      return !prev;
    });

  useEffect(() => {
    setTempOwners(owners);
  }, [owners]);

  const handleSaveTempOwners = async () => {
    const ownerWithFlag: CategoryType[] = [];
    try {
      tempOwners.forEach(owner => {
        if (!owner.name || !owner.flag) return;
        if (owner?.flag === 'delete' && !owner?.id) return;

        ownerWithFlag.push(owner);
      });

      setTempOwners([]);
      await CategoriesService.insertOwners(ownerWithFlag);

      dispatch(fetchAllInfo(!!user?.is_colorpicker));
    } catch (e) {
      console.log(e.message);
    } finally {
      handleOffEditMode();
    }
  };

  const handleOnEditMode = () => {
    setTempOwners(owners);
    setIsEditMode(true);
    handleScroll();
  };

  const handleChangeTempOwners = (category: CategoryType, index: number) => {
    const { id, name, flag } = category;
    setTempOwners(prev => {
      const temp = [...prev];
      temp[index] = {
        ...(id && { id }),
        ...(flag && { flag }),
        name,
      };
      return temp;
    });
  };

  const handleAddTempOwner = () => {
    !isEditMode && handleOnEditMode();
    setTempOwners(prev => [
      ...prev,
      {
        name: '',
        flag: 'insert',
      },
    ]);
  };

  return (
    <div className="w-full flex flex-col gap-4">
      <div
        className={
          't-sm:grid pr-2 t-sm:grid-cols-3 flex flex-wrap gap-x-2 t-sm:gap-x-4 gap-y-2.5 font-gothampro-500'
        }
      >
        {(showAll || isEditMode ? tempOwners : tempOwners.slice(0, 8)).map((owner, i) => {
          if (owner.flag === 'delete' || (!owner.name && !owner.flag)) {
            return <Fragment key={`owner-filter-${owner.name}-${i}`} />;
          }

          const isActive = tempFilter?.owners?.includes(owner.id || 0);

          return (
            <ZoneTag
              withColor={!!user?.is_colorpicker}
              colorBottom={i <= 2}
              isActive={isActive}
              isEditMode={isEditMode}
              onChange={handleChangeTempOwners}
              fitWidth
              index={i}
              onClick={() => owner.id && handleSelect(owner.id, 'owners')}
              key={`owner-filter-${owner.name}-${i}`}
              category={{ ...owner, color: '#FF5500' }}
            />
          );
        })}

        {!isEditMode && (
          <ZoneTag
            isActive
            noShadow
            whiteText
            fullWidth
            withColor={!!user?.is_colorpicker}
            onClick={handleShawAll}
            category={{ name: showAll ? 'Скрыть' : 'Показать все', color: '#FF5500' }}
          />
        )}
      </div>

      {user?.isManager ? (
        <div className="w-full flex t-sm:flex-row flex-col justify-center gap-2 t-sm:gap-5">
          <Button
            variant="orange"
            classNames="w-full t-sm:w-44"
            onClick={isEditMode ? handleSaveTempOwners : handleOnEditMode}
          >
            {isEditMode ? 'Сохранить' : 'Редактировать'}
          </Button>
          <Button variant="blue" classNames="w-full t-sm:w-44" onClick={handleAddTempOwner}>
            Добавить правообладателя
          </Button>
        </div>
      ) : null}
    </div>
  );
};
