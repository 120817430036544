import { FC, useRef, useState } from 'react';
import { useClickOutside } from 'src/hooks/useClickOutside';
import { LayersList } from 'src/components/LayersList';

import { ReactComponent as ArrowIcon } from 'src/assets/icons/kit/arrow.svg';
import cn from 'classnames';

interface LayersSelectProps {
  classNames?: string;
}

export const LayersSelect: FC<LayersSelectProps> = ({ classNames }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setIsOpen(prev => !prev);
  };

  useClickOutside(ref, () => setIsOpen(false));

  return (
    <div
      className={cn(
        'mx-auto w-full cursor-pointer relative t-lg:w-52 d-lg:w-60 t-lg:absolute',
        't-lg:right-44 w-full z-2000 shadow-lg',
        classNames,
      )}
      ref={ref}
    >
      <div
        className={cn(
          {
            rounded: !isOpen,
            'rounded-t bg-white': isOpen,
          },
          'flex items-center font-gothampro-400 w-full h-11 px-5 bg-white',
          'dark:bg-blue-200 dark:text-white',
        )}
        onClick={handleOpen}
      >
        <div className="w-full">Слои</div>
        <ArrowIcon
          className={cn(
            { 'rotate-180': !isOpen },
            'w-4 h-4 min-w-[1rem] dark:stroke-path-white transition-full ml-auto',
          )}
        />
      </div>

      {isOpen ? (
        <div
          className={
            'absolute z-2100 rounded-b top-full max-h-[14rem] left-0 w-full ' +
            'bg-white overflow-hidden rounded-b dark:bg-blue-200 shadow-lg'
          }
        >
          <div className="w-full max-h-[14rem] overflow-auto scrollbar-hide flex flex-col">
            <LayersList />
          </div>
        </div>
      ) : null}
    </div>
  );
};
