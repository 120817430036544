import { FC } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { setIsClustersActive } from 'src/store/common';
import { AppToolTip } from 'src/components/AppToolTip';

import { ReactComponent as ClusterIcon } from 'src/assets/icons/kit/cluster.svg';
import cn from 'classnames';

export const ClusterChangeButton: FC = () => {
  const { isClustersActive } = useAppSelector(state => state.common);

  const dispatch = useAppDispatch();

  const handleSetIsFavouritesActive = () => dispatch(setIsClustersActive(!isClustersActive));

  return (
    <AppToolTip
      text="Кластеры"
      classNames={
        'w-7 h-7 min-w-7 rounded-full bg-green-300 px-2 flex group ' +
        'items-center gap-1 transition-full cursor-pointer justify-center'
      }
      onClick={handleSetIsFavouritesActive}
    >
      <ClusterIcon
        className={cn(
          {
            'fill-yellow-100': isClustersActive,
            'fill-white dark:fill-blue-300': !isClustersActive,
          },
          'w-4 h-4 min-w-[1rem] -ml-0.5 transition-full t-lg:group-hover:fill-yellow-100',
        )}
      />
    </AppToolTip>
  );
};
