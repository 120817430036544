import { setIsNewPolygonFormOpen, setOpenedDropBlock, setResetForm } from 'src/store/common';
import { setFlyTo } from 'src/store/map';
import { stringToCoords } from 'src/utils';
import { addCnToUrl, getFetchReestrImageType, getReestrPolygon } from 'src/utils/helpers';
import { setNotSavedDrawFavoritePolygonId, setResetPolygon } from 'src/store/polygons/index';
import { FetchReestrPolygonsParamsParams, PolygonType } from 'src/config/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import ReestrService from 'src/services/ReestrService';
import PolygonsService from 'src/services/PolygonsService';

export const fetchReestrPolygons = createAsyncThunk(
  'reestrPolygons/fetchAll',
  async (params: FetchReestrPolygonsParamsParams, thunkAPI) => {
    try {
      const response = await PolygonsService.getAllReestrPolygons(params);
      return response?.results?.features || response?.features;
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось загрузить реестровые полигоны');
    }
  },
);

export const fetchDrawPolygons = createAsyncThunk('drawPolygons/fetchAll', async (_, thunkAPI) => {
  try {
    const response = await PolygonsService.getAllDrawPolygons();
    return response?.results?.features || response?.features;
  } catch (e) {
    return thunkAPI.rejectWithValue('Не удалось загрузить кастомные полигоны');
  }
});

export const fetchFavoritePolygons = createAsyncThunk(
  'polygons/fetchAllFavorites',
  async (_, thunkAPI) => {
    try {
      const response = await PolygonsService.allPolygonsInWork();
      return response?.results?.features || response?.features;
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось загрузить избранные полигоны');
    }
  },
);

export const fetchReestrPolygonById = createAsyncThunk(
  'reestrPolygon/fetchById',
  async (id: string, thunkAPI) => {
    try {
      return await PolygonsService.getReestrPolygonById({ id });
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось загрузить полигон');
    }
  },
);

export const fetchDrawPolygonById = createAsyncThunk(
  'drawPolygon/fetchById',
  async (id: string, thunkAPI) => {
    try {
      return await PolygonsService.getDrawPolygonById({ id });
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось загрузить полигон');
    }
  },
);

const getSavedPolygon = async (cn: string): Promise<PolygonType | null> => {
  try {
    return await PolygonsService.getReestrPolygonByCN({ cn });
  } catch (e) {
    return null;
  }
};

export const fetchSelectedPolygon = createAsyncThunk(
  'selectedPolygon/fetchById',
  async (
    params: { cn?: string; text?: string; coords?: number[]; selectedLayers: number[] },
    thunkAPI,
  ) => {
    try {
      let polygon: PolygonType | null;
      let cn = params?.cn || '';

      if (params.text) {
        const searchedInfo = await ReestrService.searchText({ text: params.text });
        cn = searchedInfo?.features?.[0]?.attrs?.cn || '';
      }

      if (params.coords) {
        const searchedInfo = await ReestrService[getFetchReestrImageType(params.selectedLayers)]({
          text: `${params.coords[0]} ${params.coords[1]}`,
        });
        cn = searchedInfo?.feature?.attrs?.cn || '';
      }

      const savedPolygon = await getSavedPolygon(cn);
      if (savedPolygon?.id) {
        polygon = {
          geometry: savedPolygon.geometry,
          id: savedPolygon.id,
          ...savedPolygon.properties,
        };
      } else {
        const reestrInfo = await ReestrService.getReestrInfo({ cn });
        polygon = reestrInfo?.feature?.attrs
          ? await getReestrPolygon(
              reestrInfo,
              params.selectedLayers,
              reestrInfo?.additional_numbers,
            )
          : null;
      }

      thunkAPI.dispatch(setResetForm(true));
      thunkAPI.dispatch(setResetPolygon(true));
      thunkAPI.dispatch(setOpenedDropBlock({ info: true }));
      thunkAPI.dispatch(setIsNewPolygonFormOpen(true));
      thunkAPI.dispatch(setFlyTo(polygon?.center ? stringToCoords(polygon?.center) : null));
      thunkAPI.dispatch(setNotSavedDrawFavoritePolygonId(null));
      addCnToUrl(polygon?.cadaster_number);

      return polygon;
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось загрузить полигон');
    }
  },
);

export const fetchInfoData = createAsyncThunk(
  'polygons/fetchInfoData',
  async (categoryIds: number[], thunkAPI) => {
    try {
      return await PolygonsService.getPolygonsInfo({ categoryIds });
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось загрузить данные о полигонах');
    }
  },
);
