import { FC, ReactNode, useEffect } from 'react';
import { useWindowDimensions } from 'src/hooks/useWindowDimension';
import { fetchAllInfo } from 'src/store/categories/actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { Navigation } from 'src/components/Navigation';
import { useAuth } from 'src/hooks/useAuth';

interface ProtectedContainerProps {
  children?: ReactNode;
}

export const ProtectedContainer: FC<ProtectedContainerProps> = ({ children }) => {
  const { isLoadingAllInfo, statuses } = useAppSelector(state => state.categories);

  const { width } = useWindowDimensions();
  const { user, getUser } = useAuth();
  const dispatch = useAppDispatch();

  useEffect(() => {
    getUser().then();
  }, []);

  useEffect(() => {
    if (!user) return;

    (async () => {
      if (!isLoadingAllInfo && !statuses?.length) {
        await dispatch(fetchAllInfo(!!user?.is_colorpicker));
      }
    })();
  }, [isLoadingAllInfo, statuses, user]);

  return width >= 1024 ? (
    <div className="w-full h-full px-8 flex gap-6">
      <div className="flex flex-col gap-1.5">
        <Navigation />
      </div>
      <div className="h-full w-full overflow-auto">{children}</div>
    </div>
  ) : (
    <div className="w-full h-full overflow-auto">{children}</div>
  );
};
