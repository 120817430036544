import { FC, useEffect, useRef, useState } from 'react';
import { Search } from 'src/components/Search';
import { ImportExcelButton } from 'src/components/Excel/ImportExcelButton';
import { ExportExcelButton } from 'src/components/Excel/ExportExcelButton';
import { Loader } from 'src/components/Loader';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { setIsBurgerOpen } from 'src/store/common';
import { useClickOutside } from 'src/hooks/useClickOutside';
import { useAuth } from 'src/hooks/useAuth';
import { useLocation } from 'react-router';
import { Zones } from 'src/components/Zones';
import { ZonesFavorite } from 'src/components/ZonesFavorite';
import { ObjectInfo } from 'src/components/ObjectInfo';

import { ReactComponent as ArrowIcon } from 'src/assets/icons/kit/arrow.svg';
import { ReactComponent as ExitIcon } from 'src/assets/icons/kit/exit.svg';
import { ReactComponent as UserIcon } from 'src/assets/icons/kit/user.svg';

import cn from 'classnames';
import { Link } from 'react-router-dom';

interface HeaderBurgerProps {
  handleChangeSearch: (str: string, noSearch?: boolean) => void;
  handleLogout: () => void;
}

export const HeaderBurger: FC<HeaderBurgerProps> = ({ handleChangeSearch, handleLogout }) => {
  const {
    filteredPolygonsCount,
    favoritePolygons,
    isLoadingFavoritePolygons,
    isLoadingReestrPolygons,
    isLoadingDrawPolygons,
  } = useAppSelector(state => state.polygons);
  const { isFavouritesActive } = useAppSelector(state => state.common);
  const refBlock = useRef<HTMLDivElement>(null);

  const { user } = useAuth();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const isMap = pathname === '/map';
  const isOnlyDashboard = pathname === '/dashboard';

  const [selectedBurger, setSelectedBurger] = useState<'zones' | 'new-zones' | 'info' | ''>('');

  const handleCloseBurger = () => dispatch(setIsBurgerOpen(false));
  const handleCancelSelectedBurger = () => setSelectedBurger('');

  useClickOutside(ref, handleCloseBurger);

  const mobileButtonClassNames =
    'w-full h-10 px-2.5 flex items-center cursor-pointer text-sm text-white font-gothampro-500';

  const mobileButtonCounter = (count: number, isLoading: boolean) => (
    <div
      className={
        'rounded-xl px-2 font-gothampro-500 text-white bg-blue-800 text-center h-6 ' +
        'center text-xs ml-auto'
      }
    >
      {isLoading ? <Loader classNames="w-4 h-4" stroke="#FFFFFF" strokeWidth="8" /> : count}
    </div>
  );

  const selectedItems = {
    zones: { classNames: 'bg-orange-100', label: 'Объекты', element: <Zones /> },
    'new-zones': {
      classNames: 'bg-yellow-100',
      label: 'Необработанные объекты',
      element: <ZonesFavorite />,
    },
    info: { classNames: 'bg-blue-100', label: 'Информация об объекте', element: <ObjectInfo /> },
  };

  const [showTopArrow, setShowTopArrow] = useState(false);

  const handleScroll = () => {
    if (!refBlock?.current) return;
    const { offsetHeight, scrollTop } = refBlock.current;
    setShowTopArrow(scrollTop > offsetHeight / 3);
  };

  const scrollTop = () => {
    if (!refBlock?.current) return;

    refBlock.current.scroll({ behavior: 'smooth', top: 0 });
  };

  useEffect(() => {
    if (!refBlock?.current) return;

    refBlock.current.addEventListener('scroll', handleScroll);
  }, [selectedBurger]);

  return (
    <>
      <div
        ref={ref}
        className={cn(
          { 'w-72 rounded-b-lg': !selectedBurger, 'w-full': selectedBurger },
          'z-2400 bg-white dark:bg-blue-800 t-lg:hidden absolute top-16 right-0 shadow-2xl',
        )}
        style={{ height: selectedBurger ? 'calc(100dvh - 64px)' : 'auto' }}
      >
        {selectedBurger ? (
          <>
            <div
              className={cn(selectedItems[selectedBurger].classNames, mobileButtonClassNames)}
              onClick={handleCancelSelectedBurger}
            >
              <ArrowIcon className="w-4 h-4 -rotate-90 mr-2 stroke-path-white" />
              {selectedItems[selectedBurger].label}
            </div>
            <div
              ref={refBlock}
              className="w-full overflow-auto"
              id="zones-scroll"
              style={{ height: 'calc(100% - 40px)' }}
            >
              {selectedItems[selectedBurger].element}
            </div>
            <div
              className={cn(
                {
                  'opacity-0 pointer-events-none': selectedBurger !== 'zones' || !showTopArrow,
                  'opacity-1': selectedBurger === 'zones' && showTopArrow,
                },
                'w-8 h-8 rounded-full cursor-pointer bg-blue-100 center absolute bottom-4',
                'right-4 transition-all',
              )}
              onClick={scrollTop}
            >
              <ArrowIcon className="stroke-path-white w-4 h-4" />
            </div>
          </>
        ) : (
          <>
            {isMap ? (
              <>
                <Link to={'/user'}>
                  <div className={'bg-blue-700  p-2 fill-white ml-auto flex justify-between'}>
                    <p className={'text-white '}>Профиль</p>
                    <UserIcon className={'w-4 fill-white'} />
                  </div>
                </Link>
                <Search
                  onChange={handleChangeSearch}
                  withList
                  placeholder="Поиск"
                  classNames="rounded-none"
                />
                <div
                  className={cn('bg-orange-100', mobileButtonClassNames)}
                  onClick={() => setSelectedBurger('zones')}
                >
                  Объекты
                  {mobileButtonCounter(
                    filteredPolygonsCount,
                    isLoadingReestrPolygons || isLoadingDrawPolygons,
                  )}
                </div>

                {isFavouritesActive && !user?.isUser ? (
                  <div
                    className={cn('bg-yellow-100', mobileButtonClassNames)}
                    onClick={() => setSelectedBurger('new-zones')}
                  >
                    Необработанные объекты
                    {mobileButtonCounter(favoritePolygons?.length, isLoadingFavoritePolygons)}
                  </div>
                ) : null}

                <div
                  className={cn('bg-blue-100', mobileButtonClassNames)}
                  onClick={() => setSelectedBurger('info')}
                >
                  Информация об объекте
                </div>
              </>
            ) : null}
            {isOnlyDashboard && (user?.isManager || user?.isLeader) ? (
              <>
                <ImportExcelButton variant="text" classNames="m-md:hidden" />
                <ExportExcelButton variant="text" classNames="m-md:hidden" />
              </>
            ) : null}

            <div
              className={cn('bg-blue-700 rounded-b-lg', mobileButtonClassNames)}
              onClick={handleLogout}
            >
              Выход
              <ExitIcon className="w-4 h-4 fill-white ml-auto" />
            </div>
          </>
        )}
      </div>
    </>
  );
};
