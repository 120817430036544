import { Dispatch, FC, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { declOfNum } from 'src/utils';
import { useClickOutside } from 'src/hooks/useClickOutside';
import { Button } from 'src/components/Button';
import { Select } from 'src/components/Select';
import { useForm } from 'react-hook-form';
import { Comment } from 'src/components/Comment';
import { getPolygonSelectLists, selectPolygonSelects } from 'src/utils/helpers';
import { useAppSelector } from 'src/hooks/useRedux';
import { initialPolygonSelects } from 'src/config/data';
import { PolygonSelectsType, PolygonType, SelectItemType } from 'src/config/types';
import { EditorState } from 'draft-js';
import PolygonsService from 'src/services/PolygonsService';

import { ReactComponent as CloseIcon } from 'src/assets/icons/kit/close.svg';

interface DashboardEditModal {
  onClose: () => void;
  setTrigger: Dispatch<SetStateAction<boolean>>;
}

export const DashboardEditModal: FC<DashboardEditModal> = ({ onClose, setTrigger }) => {
  const {
    resetField,
    register,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<PolygonType>();

  const categories = useAppSelector(state => state.categories);
  const { checkedPolygons } = useAppSelector(state => state.polygons);

  const isOne = checkedPolygons?.length === 1;

  useEffect(() => {
    if (isOne) {
      selectPolygonSelects(checkedPolygons[0], categories, setPolygonSelects, setValue, setComment);
    }
  }, [isOne, checkedPolygons]);

  const [polygonSelects, setPolygonSelects] = useState<PolygonSelectsType>(initialPolygonSelects);
  const [comment, setComment] = useState<EditorState>(EditorState.createEmpty());
  const [isLoading, setIsLoading] = useState(false);

  const polygonsSelectLists = useMemo(
    () => getPolygonSelectLists(categories, polygonSelects),
    [categories, polygonSelects],
  );

  const handleChangeStatus = (status: SelectItemType) => {
    setPolygonSelects(prev => ({ ...prev, status }));
    const selectedStatus = categories.statuses.find(el => (el?.id || 0) === +status.value);

    if (!selectedStatus?.id) return;

    if (!selectedStatus?.substatuses?.length) {
      resetField('substatus');
    }
  };

  const polygonsCount = checkedPolygons.length || 0;

  const ref = useRef<HTMLDivElement>(null);

  useClickOutside(ref, onClose);

  const nameLine = (str: string) => (
    <div className="px-3 mb-1 font-gothampro-400 text-grey-500 text-extra-xs whitespace-nowrap">
      {str}
    </div>
  );

  const selectData = {
    errors: errors,
    register: register,
    clearErrors: clearErrors,
    disabled: isLoading,
    setValue: setValue,
    isBig: true,
  };

  const onSubmit = async data => {
    if (isLoading) return;

    setIsLoading(true);

    const params: {
      owner?: number;
      square?: number;
      status?: number;
      substatus?: number;
      type_owner?: number;
      category?: number;
      polygons: number[];
    } = {
      ...(data.substatus && { substatus: +data.substatus }),
      ...(data.owner && { owner: +data.owner }),
      ...(data.type_owner && { type_owner: +data.type_owner }),
      ...(data.block_status && { block_status: +data.block_status }),
      ...(data.category && { category: +data.category }),
      status: +data.status,
      polygons: checkedPolygons.map(polygon => polygon.id || 0),
    };

    try {
      await PolygonsService.bulkUpdate(params);
      setTrigger(prev => !prev);

      onClose();
    } catch (e) {
      console.log(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed z-2500 inset-0 w-full h-full bg-black-rgba-300 center">
      <div
        className={
          'w-[58rem] bg-white dark:bg-blue-800 rounded shadow-blacks-1000 py-12 flex relative'
        }
        ref={ref}
      >
        <div
          className={
            'w-5 h-5 cursor-pointer absolute rounded-full border border-grey-500 center ' +
            'top-8 right-8'
          }
          onClick={onClose}
        >
          <CloseIcon className="w-2 h-2 fill-grey-500" />
        </div>

        <form className="mx-auto w-[22rem] flex flex-col gap-5" onSubmit={handleSubmit(onSubmit)}>
          <div className="font-gothampro-500 dark:text-white">{`Измените параметры ${polygonsCount} ${declOfNum(
            polygonsCount,
            ['объекта', 'объектов', 'объектов'],
          )}`}</div>

          <div>
            {nameLine('Направление')}
            <Select
              data={{ name: 'block_status', required: false, placeholder: 'Направление' }}
              list={polygonsSelectLists.blockStatuses}
              value={polygonSelects.blockStatus}
              onChange={blockStatus => setPolygonSelects(prev => ({ ...prev, blockStatus }))}
              {...selectData}
            />
          </div>

          <div>
            {nameLine('Группа объекта')}
            <Select
              data={{ name: 'category', required: false, placeholder: 'Группа объекта' }}
              list={polygonsSelectLists.categories}
              value={polygonSelects.category}
              onChange={category => setPolygonSelects(prev => ({ ...prev, category }))}
              {...selectData}
            />
          </div>

          <div>
            {nameLine('Правообладатель')}
            <Select
              data={{
                name: 'owner',
                required: false,
                placeholder: 'Введите имя правообладателя',
              }}
              withSearch
              list={polygonsSelectLists.owners}
              value={polygonSelects.owner}
              onChange={owner => setPolygonSelects(prev => ({ ...prev, owner }))}
              {...selectData}
            />
          </div>

          <div>
            {nameLine('Вид права')}
            <Select
              data={{ name: 'type_owner', required: false, placeholder: 'Введите вид права' }}
              list={polygonsSelectLists.typeOwners}
              value={polygonSelects.typeOwner}
              onChange={typeOwner => setPolygonSelects(prev => ({ ...prev, typeOwner }))}
              {...selectData}
            />
          </div>

          <div>
            {nameLine('Статус')}
            <Select
              data={{ name: 'status', required: true, placeholder: 'Статус' }}
              list={polygonsSelectLists.statuses}
              value={polygonSelects.status}
              onChange={handleChangeStatus}
              {...selectData}
            />
          </div>

          {polygonsSelectLists.subStatuses?.length ? (
            <div>
              {nameLine('Подстатус')}
              <Select
                data={{ name: 'substatus', required: true, placeholder: 'Подстатус' }}
                list={polygonsSelectLists.subStatuses}
                value={polygonSelects.subStatus}
                onChange={subStatus => setPolygonSelects(prev => ({ ...prev, subStatus }))}
                {...selectData}
              />
            </div>
          ) : null}

          {isOne ? (
            <div>
              {nameLine('Комментарий')}
              <Comment comment={comment} disabled={false} setComment={setComment} />
            </div>
          ) : null}

          <Button variant="blue" size="big" classNames="mx-auto">
            {`Применить${!isOne ? ' ко всем' : ''}`}
          </Button>
        </form>
      </div>
    </div>
  );
};
