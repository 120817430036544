import { FC, useEffect, useState } from 'react';
import { MapButtonsDesktop } from 'src/components/MapButtons/MapButtonsDesktop';
import { MapButtonsMobile } from 'src/components/MapButtons/MapButtonsMobile';
import { useWindowDimensions } from 'src/hooks/useWindowDimension';
import { setFlyTo } from 'src/store/map';
import { sleep } from 'src/utils';
import { useAppDispatch } from 'src/hooks/useRedux';
import { LatLngExpression } from 'leaflet';

export const MapButtons: FC = () => {
  const dispatch = useAppDispatch();

  const { width } = useWindowDimensions();

  const [currentPosition, setCurrentPosition] = useState<LatLngExpression>();

  const moveToMyLocation = async () => {
    if (!currentPosition) return;

    dispatch(setFlyTo(currentPosition));
    await sleep(100);
    dispatch(setFlyTo(currentPosition));
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(async ({ coords }) =>
      setCurrentPosition([coords.latitude, coords.longitude]),
    );
  }, []);

  return (
    <>
      {width >= 1024 ? <MapButtonsDesktop {...(currentPosition && { moveToMyLocation })} /> : null}
      {width < 1024 ? <MapButtonsMobile {...(currentPosition && { moveToMyLocation })} /> : null}
    </>
  );
};
