import { FC, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { getFilteredPolygons } from 'src/selectors/polygonSelectors';
import {
  createClusterCustomIcon,
  renderMarker,
} from 'src/components/Map/LeafletMapMarkerCluster/helpers';
import { useWindowDimensions } from 'src/hooks/useWindowDimension';

import MarkerClusterGroup from 'react-leaflet-cluster';

export const LeafletMapMarkerCluster: FC = () => {
  const { statuses } = useAppSelector(state => state.categories);
  const lassoFilter = useAppSelector(state => state.filters.lassoFilter);

  const { width } = useWindowDimensions();
  const dispatch = useAppDispatch();

  const filteredPolygons = useAppSelector(state => getFilteredPolygons(state));

  const markersList = useMemo(
    () => (
      <>
        {filteredPolygons?.map((polygon, i) =>
          renderMarker(polygon, width, i, dispatch, lassoFilter),
        )}
      </>
    ),
    [filteredPolygons, lassoFilter],
  );

  return (
    <>
      <MarkerClusterGroup
        iconCreateFunction={cluster => createClusterCustomIcon(cluster, statuses)}
        showCoverageOnHover={false}
        animate={false}
        spiderfyOnMaxZoom={false}
        disableClusteringAtZoom={16}
      >
        {markersList}
      </MarkerClusterGroup>
    </>
  );
};
