import { FC, useMemo, Fragment, useState } from 'react';
import { stringToCoords } from 'src/utils';
import { getFilteredPolygons } from 'src/selectors/polygonSelectors';
import { geoJSONToCoords, getPolygonCategories } from 'src/utils/helpers';
import { useAuth } from 'src/hooks/useAuth';
import { FeatureGroup, Polygon, useMapEvents } from 'react-leaflet';
import { useAppSelector } from 'src/hooks/useRedux';
import { PolygonType } from 'src/config/types';
import { LatLngExpression } from 'leaflet';

export const LeafletMapPolygons: FC = () => {
  const { selectedPolygon, reestrPolygons } = useAppSelector(state => state.polygons);
  const { isClustersActive } = useAppSelector(state => state.common);
  const categories = useAppSelector(state => state.categories);
  const lassoFilter = useAppSelector(state => state.filters.lassoFilter);

  const { user } = useAuth();

  const [bounds, setBounds] = useState<LatLngExpression>();
  const filteredPolygons = useAppSelector(state => getFilteredPolygons(state));

  useMapEvents({
    moveend: e => {
      setBounds(e.target.getBounds());
    },
  });

  const checkVisible = (polygon: PolygonType, isClustersActive: boolean, bounds) => {
    if (isClustersActive) {
      return document.querySelector(
        `.marker-${
          polygon?.properties?.cadaster_number || polygon?.cadaster_number ? 'reestr' : 'draw'
        }-${polygon.id}`,
      );
    }

    if (lassoFilter?.positions?.length) {
      return (lassoFilter?.polygonIds || []).includes(polygon?.id || 0);
    }

    const center = stringToCoords(polygon?.properties?.center || polygon?.center || '');
    return (
      center[0] <= bounds._northEast.lat &&
      center[1] <= bounds._northEast.lng &&
      center[0] >= bounds._southWest.lat &&
      center[1] >= bounds._southWest.lng
    );
  };

  const renderPolygon = (polygon: PolygonType, i: number) => {
    if (!bounds || !polygon?.geometry) {
      return <Fragment key={`polygon-with-copy-${i}`} />;
    }

    if (checkVisible(polygon, isClustersActive, bounds)) {
      const { category, status, blockStatus } = getPolygonCategories(polygon, categories);
      const positions = geoJSONToCoords(polygon.geometry);
      const hatching = status?.color || '';
      const color = polygon?.id ? blockStatus?.color || '#7AA0CE' : blockStatus?.color || '#7AA0CE';
      const stroke = user?.is_colorpicker
        ? category?.color || '#AAAAAA'
        : blockStatus?.color || '#47750B';
      const isSelected = polygon?.id === selectedPolygon?.id;

      return (
        <Fragment key={`polygon-with-copy-${i}`}>
          {positions.map((coords, i) => (
            <Fragment key={`selected-multi-${i}`}>
              <Polygon
                positions={coords}
                pathOptions={{
                  fillOpacity: 0.5,
                  fillColor: color,
                  stroke: false,
                }}
                className={`fill-${polygon.id}`}
              />
              <Polygon
                positions={coords}
                pathOptions={{
                  fillOpacity: 1,
                  weight: isSelected ? 6 : 3,
                  color: stroke,
                  fillColor: hatching
                    ? `url(#pattern-${hatching.replace('#', '')})`
                    : 'transparent',
                }}
                className={`stroke-${polygon.id}`}
              />
            </Fragment>
          ))}
        </Fragment>
      );
    } else {
      return <Fragment key={`polygon-with-copy-${i}`} />;
    }
  };

  const renderSelectedPolygon = useMemo(() => {
    if (!selectedPolygon) {
      return <></>;
    }
    return renderPolygon(selectedPolygon, reestrPolygons.length);
  }, [selectedPolygon, reestrPolygons, bounds]);

  const polygonsList = useMemo(
    () => <>{filteredPolygons.map((polygon, i) => renderPolygon(polygon, i))}</>,
    [filteredPolygons, isClustersActive, bounds, lassoFilter],
  );

  return (
    <FeatureGroup>
      {polygonsList}

      {(selectedPolygon && !selectedPolygon?.id) ||
      (selectedPolygon && !selectedPolygon?.properties?.cadaster_number)
        ? renderSelectedPolygon
        : null}
    </FeatureGroup>
  );
};
