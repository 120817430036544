import { FC, ReactNode, useMemo } from 'react';
import { useWindowDimensions } from 'src/hooks/useWindowDimension';
import { useAppSelector } from 'src/hooks/useRedux';

import cn from 'classnames';

interface DropBlockSwipeProps {
  onClick?: () => void;
  name: string;
  wrapperClassNames?: string;
  classNames?: string;
  children?: ReactNode;
}

export const DropBlockSwipe: FC<DropBlockSwipeProps> = ({
  onClick,
  name,
  wrapperClassNames,
  children,
}) => {
  const { openedDropBlocks } = useAppSelector(state => state.common);

  const { width } = useWindowDimensions();

  const isOpen = useMemo(() => Boolean(openedDropBlocks?.[name]), [openedDropBlocks, name]);

  return (
    <div
      className={cn(
        { '-right-[50vw]': !isOpen, 'right-8': isOpen },
        'transition-full absolute top-11 shadow-blacks-100 w-[21rem] d-xs:w-[30rem] d-lg:w-[36rem]',
        wrapperClassNames,
      )}
      onClick={onClick}
    >
      <div
        className={
          'transition-full overflow-y-auto overflow-x-hidden mr-10 w-full rounded-b-md bg-white ' +
          'dark:bg-blue-800'
        }
        style={{
          maxHeight: isOpen ? (width >= 1024 ? `calc(100vh - 220px)` : '60vh') : '0',
          opacity: isOpen ? 1 : 0,
        }}
        {...(name === 'zones' && { id: 'zones-scroll' })}
      >
        {children}
      </div>
    </div>
  );
};
