import { FC, useState } from 'react';
import { Input } from 'src/components/Input';
import { useForm } from 'react-hook-form';
import { Button } from 'src/components/Button';
import { useAuth } from 'src/hooks/useAuth';
import { LoginType } from 'src/config/types';

import { ReactComponent as EyeOpen } from 'src/assets/icons/kit/eye.svg';
import { ReactComponent as EyeClose } from 'src/assets/icons/kit/eye-close.svg';

export const LoginForm: FC = () => {
  const [isEmptyPassword, setIsEmptyPassword] = useState('');
  const [isEmptyUsername, setIsEmptyUsername] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginType>();
  const { login } = useAuth();

  const onSubmit = async data => {
    await login(data);
    !data.username ? setIsEmptyUsername('* Пожалуйста введите логин') : setIsEmptyUsername('');
    !data.password ? setIsEmptyPassword('* Пожалуйста введите пароль') : setIsEmptyPassword('');
    data.username && data.password
      ? setIsEmptyPassword(
          '* Введен неправильный логин или пароль. Пожалуйста проверьте введенные данные',
        )
      : '';
  };

  const nameLine = (str: string) => (
    <div className="mb-1 ml-4 font-gothampro-400 text-grey-500 text-extra-xs whitespace-nowrap">
      {str}
    </div>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="t-sm:max-w-[32rem] h-fit w-full">
      <div
        className={'w-full text-center text-2xl dark:text-white mb-8 t-lg:mb-24 font-gothampro-500'}
      >
        Вход
      </div>

      {nameLine('Логин')}
      <Input
        data={{ name: 'username', placeholder: 'Введите логин' }}
        errors={errors}
        register={register}
        size="big"
        classNames={`mb-5 h-11 ${isEmptyUsername ? ' drop-shadow-[0_0px_1.5px_red]' : ''}`}
      />
      <p className="text-red-600 relative -top-4">{isEmptyUsername}</p>
      <div className="relative">
        {nameLine('Пароль')}
        <Input
          data={{ name: 'password', placeholder: 'Введите пароль' }}
          errors={errors}
          register={register}
          size="big"
          autoComplete="on"
          classNames={`mb-8 h-11  ${isEmptyPassword ? ' drop-shadow-[0_0px_1px_red]' : ''}`}
          type={!isPasswordVisible ? 'password' : 'text'}
        />
        <div
          className="absolute top-5 right-3 p-2 cursor-pointer"
          onClick={() => {
            setIsPasswordVisible(prev => !prev);
          }}
        >
          {!isPasswordVisible ? <EyeClose className="w-5 h-5" /> : <EyeOpen className="w-5 h-5" />}
        </div>
      </div>
      <p className="text-red-600 relative -top-7">{isEmptyPassword}</p>
      <div
        className={
          'flex flex-col m-md:flex-row w-full justify-center gap-1 text-sm ' +
          'font-gothampro-400 mb-8 t-lg:mb-20 items-center'
        }
      >
        <div className="dark:text-white">Забыли пароль?</div>
        <div className="cursor-pointer text-blue-100 dark:text-blue-1100">
          Обратиться к администратору
        </div>
      </div>

      <div className="w-full flex justify-center">
        <Button variant="blue" size="big" classNames="w-full max-w-[22rem]">
          Войти
        </Button>
      </div>
    </form>
  );
};
