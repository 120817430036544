import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { ZoneTag } from 'src/components/Zones/ZoneTag';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { setFilteredPolygonsCount } from 'src/store/polygons';
import {
  getPolygonCategories,
  getPolygonName,
  getSubStatusIcon,
  handleSelectPolygon,
} from 'src/utils/helpers';
import { useWindowDimensions } from 'src/hooks/useWindowDimension';
import { Loader } from 'src/components/Loader';
import { AppToolTip } from 'src/components/AppToolTip';
import { getFilteredPolygons } from 'src/selectors/polygonSelectors';
import { useAuth } from 'src/hooks/useAuth';
import { PolygonType } from 'src/config/types';

import cn from 'classnames';

interface ZonePolygonsListProps {
  search: string;
}

export const ZonePolygonsList: FC<ZonePolygonsListProps> = ({ search }) => {
  const categories = useAppSelector(state => state.categories);
  const { polygonsFilter } = useAppSelector(state => state.filters);
  const { selectedPolygon, isLoadingDrawPolygons, isLoadingReestrPolygons } = useAppSelector(
    state => state.polygons,
  );

  const { user } = useAuth();

  const { width } = useWindowDimensions();
  const dispatch = useAppDispatch();

  const filteredPolygons = useAppSelector(state => getFilteredPolygons(state));

  const showInterval = 10;
  const initialElementsToShow = 20;

  const [elementsToShow, setElementsToShow] = useState(initialElementsToShow);

  useEffect(() => {
    setElementsToShow(initialElementsToShow);
  }, [filteredPolygons, polygonsFilter, search]);

  const checkScroll = e => {
    const el = e.target as HTMLDivElement;
    if (el.scrollHeight - (el.scrollTop + el.offsetHeight) < 10) {
      setElementsToShow(prev => prev + showInterval);
    }
  };

  useEffect(() => {
    const zonesContainer = document.querySelector('#zones-scroll') as HTMLDivElement;

    if (!zonesContainer) return;

    zonesContainer.addEventListener('scroll', checkScroll);
  }, []);

  useEffect(() => {
    dispatch(setFilteredPolygonsCount(filteredPolygons.length));
  }, [filteredPolygons]);

  const filteredPolygonsList = useMemo(() => {
    if (!search) {
      return filteredPolygons;
    }

    const res: PolygonType[] = [];

    filteredPolygons?.forEach(polygon => {
      if (
        `${polygon?.properties?.name || ''} ${polygon?.properties?.cadaster_number || ''}`
          ?.toLowerCase()
          ?.includes(search.toLowerCase())
      ) {
        res.push(polygon);
      }
    });

    return res;
  }, [filteredPolygons, search]);

  const getPolygonSquare = (square: number) => {
    const text = square > 1000 ? 'км' : 'м';
    const number = Math.floor(square > 1000 ? square / 1000 : square);
    return (
      <>
        {number} {text}
        <sup>2</sup>
      </>
    );
  };

  const getUniq = (polygon: PolygonType | null) =>
    `${polygon?.id || 0}-${polygon?.cadaster_number || 0}`;

  const getUniqList = (polygon: PolygonType | null) =>
    `${polygon?.id || 0}-${polygon?.properties?.cadaster_number || 0}`;

  return (
    <>
      {isLoadingReestrPolygons || isLoadingDrawPolygons ? (
        <div className="w-full h-full flex items-center justify-center py-10">
          <Loader classNames="w-20 h-20" />
        </div>
      ) : null}

      {!filteredPolygonsList.length && !(isLoadingReestrPolygons || isLoadingDrawPolygons) ? (
        <div
          className={
            'py-10 text-center w-full text-center text-sm t-lg:text-base ' +
            'font-gothampro-400 dark:text-white'
          }
        >
          Не найдено
        </div>
      ) : null}

      {filteredPolygonsList?.length && !isLoadingReestrPolygons
        ? filteredPolygonsList.slice(0, elementsToShow).map((polygon, i) => {
            const { category, status, blockStatus, subStatus } = getPolygonCategories(
              polygon,
              categories,
            );

            const isSelected = getUniqList(polygon) === getUniq(selectedPolygon);
            const isNextSelected =
              getUniqList(filteredPolygonsList[i + 1]) &&
              getUniqList(filteredPolygonsList[i + 1]) === getUniq(selectedPolygon);

            if (!status) {
              return <Fragment key={`zone-item-${i}`} />;
            }

            return (
              <div
                key={`zone-item-${i}`}
                onClick={async () => {
                  await handleSelectPolygon(polygon, width, dispatch);
                }}
                className={cn(
                  {
                    'border-b border-grey-400 dark:border-blue-700': !isSelected && !isNextSelected,
                    'border-y border-orange-100 dark:border-white bg-orange-rgba-100 dark:bg-blue-700':
                      isSelected,
                    'cursor-pointer': !isSelected,
                  },
                  'px-4 py-5 transition-full hover:bg-orange-rgba-100 dark:hover:bg-blue-700',
                )}
              >
                <div className="flex gap-2 mb-4">
                  <AppToolTip
                    checkWidth
                    width="80%"
                    classNames="text-black-100 font-gothampro-400 text-sm dark:text-white truncate"
                    text={getPolygonName(polygon)}
                  />

                  <div
                    className={
                      'ml-auto text-grey-500 text-xs text-end whitespace-nowrap ' +
                      'font-gothampro-600 dark:text-white'
                    }
                  >
                    {getPolygonSquare(polygon?.properties?.square || 0)}
                  </div>
                </div>

                <div className="w-full flex gap-2 flex-wrap items-center">
                  {category ? (
                    <ZoneTag
                      fullWidth
                      category={{
                        ...category,
                        color: user?.is_colorpicker ? category.color : '#FF5500',
                      }}
                    />
                  ) : null}

                  <ZoneTag fullWidth category={status} />

                  {subStatus ? (
                    <div
                      className={
                        'rounded text-extra-xs flex text-white border px-2 py-1.5 ' +
                        'font-gothampro-500 truncate'
                      }
                      style={{ borderColor: status.color, color: status.color }}
                    >
                      {subStatus.name}
                      {getSubStatusIcon(status.color || '#000', subStatus.icon || 'home')}
                    </div>
                  ) : null}

                  {blockStatus ? <ZoneTag fullWidth category={blockStatus} /> : null}
                </div>
              </div>
            );
          })
        : null}
    </>
  );
};
