import { FC } from 'react';
import { Button } from 'src/components/Button';
import { useNavigate } from 'react-router';
import { geoJSONToCoords, getPolygonCategories } from 'src/utils/helpers';
import { useAuth } from 'src/hooks/useAuth';
import { MapContainer, Marker, Polygon, TileLayer, WMSTileLayer } from 'react-leaflet';
import { mapData, schemaTileUrl } from 'src/config/data';
import { useAppSelector } from 'src/hooks/useRedux';
import { BASE_URL } from 'src/config';
import { PolygonType } from 'src/config/types';
import L from 'leaflet';

import MarkerIcon from 'src/assets/icons/marker.svg';
import cn from 'classnames';
import { stringToCoords } from 'src/utils';

interface DashBoardSingleMapProps {
  polygon: PolygonType;
  classNames?: string;
}

export const DashBoardSingleMap: FC<DashBoardSingleMapProps> = ({ polygon, classNames }) => {
  const categories = useAppSelector(state => state.categories);

  const { user } = useAuth();

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/map?cn=${polygon.cadaster_number}`);
  };
  const center = stringToCoords(polygon?.center || polygon?.properties?.center || '');

  const polygonMapData = {
    ...mapData,
    zoom: 16,
    center,
  };

  const myIcon = L.icon({
    iconUrl: MarkerIcon,
    popupAnchor: [0, -40],
    iconAnchor: [16, 40],
    iconSize: [32, 40],
  });

  const { category, status, blockStatus } = getPolygonCategories(polygon, categories);

  const positions = polygon.geometry ? geoJSONToCoords(polygon.geometry) : [];
  const hatching = status?.color || '';
  const color = polygon?.id ? blockStatus?.color || '#7AA0CE' : blockStatus?.color || '#7AA0CE';
  const stroke = user?.is_colorpicker ? category?.color || '#AAAAAA' : '#47750B';

  return (
    <div className={cn('w-full', classNames)}>
      <div className="w-full h-[20rem] rounded-lg overflow-hidden mb-5">
        <MapContainer {...polygonMapData} className="my-map">
          <TileLayer maxZoom={19} url={schemaTileUrl[0]} />

          <WMSTileLayer
            maxZoom={19}
            zIndex={2}
            tileSize={1024}
            url={`${BASE_URL}/api-layer/land_plots/`}
            format=""
          />

          <Marker icon={myIcon} position={center} />

          {positions?.length ? (
            <>
              <Polygon
                fillOpacity={0.5}
                positions={positions}
                pathOptions={{
                  fillColor: color,
                  stroke: false,
                }}
              />
              <Polygon
                fillOpacity={1}
                positions={positions}
                pathOptions={{
                  color: stroke,
                  fillColor: `url(#pattern-${hatching.replace('#', '')})`,
                }}
              />
            </>
          ) : null}
        </MapContainer>
      </div>

      <Button variant="blue" size="big" classNames="w-full text-center" onClick={handleClick}>
        Перейти к объекту на карте
      </Button>
    </div>
  );
};
