import { FC, Fragment } from 'react';
import { categoryFields, landPlotsFields } from 'src/config/data';
import { useAppSelector } from 'src/hooks/useRedux';
import { getLandPlotValue, getPolygonCategories } from 'src/utils/helpers';
import { PolygonType } from 'src/config/types';

interface DashboardSingleStatProps {
  polygon: PolygonType;
  classNames?: string;
}

export const DashboardSingleStat: FC<DashboardSingleStatProps> = ({ polygon, classNames }) => {
  const categories = useAppSelector(state => state.categories);

  const polygonCategory = getPolygonCategories(polygon, categories);

  const statLine = (label: string, value: string) =>
    value ? (
      <div
        key={`stat-line-${label}`}
        className={
          'text-xs t-lg:text-base flex flex-col m-md:flex-row m-md:items-center gap-1 m-md:gap-2 ' +
          'font-gothampro-400 mb-2.5'
        }
      >
        <div className="text-grey-500">{label}:</div>
        <div className="dark:text-white m-md:text-right m-md:ml-auto">{value}</div>
      </div>
    ) : (
      <Fragment key={`stat-line-${label}`} />
    );

  return (
    <div className={classNames}>
      {landPlotsFields.map((field, i) => {
        const value = getLandPlotValue(field, polygon.reestrInfo?.feature.attrs);

        if (!value.length || value === ' ' || value === '  ()') {
          return <Fragment key={`object-info-line-${i}`} />;
        }

        return statLine(field.name, value);
      })}

      {categoryFields.map((el, i) => {
        if (!polygonCategory?.[el.field]) {
          return <Fragment key={`object-info-category-${i}`} />;
        }

        return statLine(el.label, polygonCategory?.[el.field]?.name);
      })}
    </div>
  );
};
