import { calculateStatusesCount, handleSelectPolygon } from 'src/utils/helpers';
import { stringToCoords } from 'src/utils';
import { PolygonType } from 'src/config/types';
import { Marker } from 'react-leaflet';

import L from 'leaflet';
import MarkerIcon from 'src/assets/icons/marker.svg';
import { Fragment } from 'react';

export const createClusterCustomIcon = function (cluster, statuses) {
  const checkOnDom = document.querySelector(`#html-${cluster._leaflet_id}`);

  if (checkOnDom) {
    return L.divIcon({
      className: 'hover-z-index',
      html: checkOnDom as HTMLElement,
    });
  }

  const statusesCount: { [key: number]: number } = calculateStatusesCount(cluster);

  const statusesList: string[] = [...statuses].map(status => {
    if (!statusesCount?.[status.id]) {
      return '';
    }

    return `<div class='flex items-center gap-1'><div class='w-3 h-3' style='background-color: ${
      status.color
    }'></div><div class='font-gothampro-400 text-extra-xs whitespace-nowrap'>${status.name} - ${
      statusesCount[status.id]
    }</div></div>`;
  });

  const count: number[] = [];
  const bg: string[] = [];

  statuses.forEach(status => {
    if (!statusesCount?.[status.id]) return;
    count.push(statusesCount[status.id]);
    if (status?.color) {
      bg.push(status.color);
    }
  });

  const html = `<div id='html-${
    cluster._leaflet_id
  }' class='my-canvas group t-lg:w-16 t-lg:h-16 w-10 h-10 rounded-full bg-white center font-gothampro-500 text-extra-xs t-lg:text-xs relative'>
                      <canvas id='${cluster._leaflet_id}' data-count='${count.join(
    '-',
  )}' data-bg='${bg.join('-')}' class='absolute charts-canvas'></canvas>
                      <div class='z-110 relative w-4/6 h-4/6 center bg-white rounded-full'>${cluster.getChildCount()}</div>
                      <div class='z-500 absolute hidden group-hover:flex p-2.5 t-lg:left-16 left-10 top-0 bg-white rounded shadow-blacks-400 flex-col gap-1'>
                        <div class='absolute -left-0.5 w-2 h-2 bg-white rotate-45' style='top: calc(50% - 4px)'></div>
                        ${statusesList.join('')}
                      </div>
                    </div>
                   `;

  return L.divIcon({
    className: 'hover-z-index',
    html,
  });
};

const customIcon = (polygon: PolygonType) =>
  new L.Icon({
    iconUrl: MarkerIcon,
    iconRetinaUrl: MarkerIcon,
    popupAnchor: [0, -40],
    iconAnchor: [16, 40],
    iconSize: [32, 40],
    status: polygon?.properties?.status,
    className: `marker-${polygon?.properties?.cadaster_number ? 'reestr' : 'draw'}-${polygon.id}`,
  });

export const renderMarker = (
  polygon: PolygonType,
  width: number,
  i: number,
  dispatch,
  lassoFilter,
) => {
  if (
    lassoFilter?.positions?.length &&
    !(lassoFilter?.polygonIds || []).includes(polygon?.id || 0)
  ) {
    return <Fragment key={`marker-${i}`} />;
  }

  return (
    <Marker
      eventHandlers={{
        click: async e => {
          e.originalEvent.preventDefault();
          await handleSelectPolygon(polygon, width, dispatch);
        },
      }}
      key={`marker-${i}`}
      position={stringToCoords(polygon?.properties?.center || '')}
      icon={customIcon(polygon)}
    />
  );
};
