import { FC, useMemo, useRef, useState, CSSProperties } from 'react';
import { useClickOutside } from 'src/hooks/useClickOutside';
import { debounce } from 'src/utils';
import { Loader } from 'src/components/Loader';
import { resetSearchAddressLIst } from 'src/store/reestr';
import { addCnToUrl } from 'src/utils/helpers';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';

import { ReactComponent as SearchIcon } from 'src/assets/icons/kit/search.svg';
import { ReactComponent as CloseIcon } from 'src/assets/icons/kit/close.svg';
import cn from 'classnames';

interface SearchProps {
  onChange?: (str: string, noSearch?: boolean) => void;
  withList?: boolean;
  defaultValue?: string;
  placeholder?: string;
  classNames?: string;
  style?: CSSProperties;
  small?: boolean;
}

export const Search: FC<SearchProps> = ({
  classNames,
  small,
  style,
  onChange,
  withList,
  ...props
}) => {
  const { searchAddressList, isLoadingSearchAddressList } = useAppSelector(state => state.reestr);

  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const refInput = useRef<HTMLInputElement>(null);

  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [resetList, setResetList] = useState(false);

  useClickOutside(ref, () => setIsOpen(false));

  const handleChange = debounce(e => {
    if (searchAddressList?.length) {
      dispatch(resetSearchAddressLIst());
    }
    onChange && onChange(e?.target?.value);
    setSearch(e?.target?.value);
  }, 400);

  const handleResetSearch = () => {
    if (!refInput?.current) return;
    onChange && onChange('');
    refInput.current.value = '';
    setSearch('');
  };

  const handleRemoveFromLocalStorage = (str: string) => {
    const searchHistory = localStorage?.searchHistory ? JSON.parse(localStorage.searchHistory) : [];
    localStorage.searchHistory = JSON.stringify(searchHistory.filter(el => el !== str));
    setResetList(prev => !prev);
  };

  const handleSetSearchToLocaleStorage = (str: string) => {
    const searchHistory = localStorage?.searchHistory ? JSON.parse(localStorage.searchHistory) : [];
    searchHistory.push(str);
    localStorage.searchHistory = JSON.stringify([...new Set(searchHistory)]);
  };

  const handleSetSearchValue = (str: string) => {
    if (refInput?.current) {
      refInput.current.value = str;
    }

    setSearch(str);
    handleSetSearchToLocaleStorage(str);

    onChange && onChange(str, true);
    setIsOpen(false);
    addCnToUrl(str);
  };

  const list = useMemo(() => {
    const searchHistory = localStorage?.searchHistory
      ? JSON.parse(localStorage?.searchHistory)
      : [];

    if (!search && searchHistory?.length) {
      return searchHistory;
    }

    return searchAddressList;
  }, [search, searchAddressList, resetList]);

  useClickOutside(ref, () => setIsOpen(false));

  return (
    <div
      className={cn(
        {
          'rounded-t': isOpen && Boolean(withList),
          rounded: !isOpen || !Boolean(withList),
          'px-2.5 t-lg:px-5 py-3 h-10 t-lg:h-11': !Boolean(small),
          'px-3 py-2 h-8 text-xs': Boolean(small),
        },
        'bg-grey-rgba-100 shadow-greys-100 font-gothampro-400 text-sm t-lg:text-base',
        'flex items-center gap-2 relative dark:text-white dark:bg-grey-1000',
        classNames,
      )}
      style={style}
      ref={ref}
    >
      <input
        onClick={() => setIsOpen(true)}
        ref={refInput}
        onChange={handleChange}
        defaultValue={search}
        className="bg-transparent w-full"
        {...props}
      />

      <CloseIcon
        className={cn(
          { 'pointer-events-none opacity-0': !search, 'opacity-1 cursor-pointer': search },
          'w-3 h-3 fill-grey-200 dark:fill-white transition-full mr-1',
        )}
        onClick={handleResetSearch}
      />
      <SearchIcon className="w-4 h-4 fill-grey-200 dark:fill-white" />

      {isOpen && withList ? (
        <div
          className={cn(
            'absolute top-full left-0 max-h-[16rem] w-full bg-white rounded-b',
            'overflow-auto z-2000 dark:bg-grey-1000',
          )}
        >
          {isLoadingSearchAddressList ? (
            <div className="w-full center py-10">
              <Loader classNames="w-10 h-10" />
            </div>
          ) : null}

          {!isLoadingSearchAddressList && !list?.length ? (
            <div className="w-full center py-4">
              <div className="text-extra-xs t-lg:text-sm font-gothampro-400 dark:text-white">
                Ничего не найдено
              </div>
            </div>
          ) : null}

          {!isLoadingSearchAddressList && list?.length
            ? list?.map((el, i) => (
                <div
                  key={`search-item-${i}`}
                  className={
                    'px-5 py-2 font-gothampro-400 text-sm cursor-pointer dark:text-white ' +
                    'hover:bg-grey-900 dark:hover:bg-grey-200 flex justify-between gap-2'
                  }
                  onClick={() => handleSetSearchValue(el?.title || el?.value || el)}
                >
                  {el?.title || el}

                  {!searchAddressList?.length ? (
                    <CloseIcon
                      className={
                        'w-3 h-3 min-w-[0.75rem] fill-grey-200 dark:fill-white transition-full ' +
                        'cursor-pointer mt-1'
                      }
                      onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        handleRemoveFromLocalStorage(el?.title || el);
                      }}
                    />
                  ) : null}
                </div>
              ))
            : null}
        </div>
      ) : null}
    </div>
  );
};
