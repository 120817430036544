import { ChangeEvent, FC, useRef } from 'react';
import { InputType } from 'src/config/types';
import { FieldErrors, RegisterOptions } from 'react-hook-form';

import cn from 'classnames';

interface InputProps {
  data?: InputType;
  errors?: FieldErrors;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  register?: (name: string, RegisterOptions?) => { onChange; onBlur; name; ref };
  classNames?: string;
  registerOptions?: RegisterOptions;
  disabled?: boolean;
  defaultValue?: string;
  size?: 'big' | 'small' | 'extraSmall';
  type?: 'input' | 'password' | 'number' | 'text';
  autoComplete?: 'on' | 'off';
}

export const Input: FC<InputProps> = ({
  data,
  registerOptions,
  errors,
  register,
  onChange,
  classNames,
  disabled,
  defaultValue,
  size = 'small',
  type = 'input',
  autoComplete,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const isError = !!errors?.[data?.name || ''];
  const error = errors?.[data?.name || '']?.message || '';

  const inputClassName = cn(
    {
      'border-pink-200': isError,
      'px-3 h-7 text-xs': size === 'small',
      'px-2 h-5 text-ultra-xs': size === 'extraSmall',
      'px-4 h-11 text-xs': size === 'big',
    },
    'w-full font-gothampro-400 rounded border border-grey-1700 placeholder-grey-200',
    'dark:bg-blue-200 dark:border-blue-200 dark:text-white bg-grey-1600',
  );

  return (
    <div className={cn('flex flex-col gap-1 w-full relative', classNames)} ref={ref}>
      <input
        placeholder={data?.placeholder}
        defaultValue={defaultValue}
        type={type}
        {...(onChange && { onChange: onChange })}
        {...(register &&
          register(data?.name || '', {
            required: data?.required,
            ...registerOptions,
          }))}
        disabled={disabled}
        autoComplete={autoComplete}
        className={inputClassName}
      />
      {isError ? (
        <div className="font-gothampro-400 text-pink-200 absolute -bottom-3.5 text-extra-xs">
          {(error as string) || 'Поле не может быть пустым!'}
        </div>
      ) : null}
    </div>
  );
};
