import { FC } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { setDarkMode } from 'src/store/common';

import { ReactComponent as SunIcon } from 'src/assets/icons/kit/sun.svg';
import { ReactComponent as MoonIcon } from 'src/assets/icons/kit/moon.svg';
import cn from 'classnames';

interface DarkModeSwitchProps {
  classNames?: string;
}

export const DarkModeSwitch: FC<DarkModeSwitchProps> = ({ classNames }) => {
  const { darkMode } = useAppSelector(state => state.common);
  const dispatch = useAppDispatch();

  const styles = 'w-6 h-6 rounded-full center';

  return (
    <div
      className={cn(
        'rounded-full p-0.5 bg-grey-1100 dark:bg-blue-200 flex gap-1 cursor-pointer',
        'shadow-greys-200 dark:shadow-blues-200',
        classNames,
      )}
      onClick={() => {
        dispatch(setDarkMode(!darkMode));
      }}
    >
      <div className={cn({ 'bg-blue-100': !darkMode }, styles)}>
        <SunIcon
          className={cn({ 'fill-blue-300': darkMode, 'fill-white': !darkMode }, 'w-4 h-4')}
        />
      </div>
      <div className={cn({ 'bg-blue-100': darkMode }, styles)}>
        <MoonIcon
          className={cn({ 'fill-grey-200': !darkMode, 'fill-white': darkMode }, 'w-3.5 h-3.5')}
        />
      </div>
    </div>
  );
};
