import { FC } from 'react';
import { ZoneTag } from 'src/components/Zones/ZoneTag';
import { PolygonsFilterType } from 'src/config/types';
import { useAppSelector } from 'src/hooks/useRedux';

import { ReactComponent as FingerIcon } from 'src/assets/icons/kit/finger.svg';
import { ReactComponent as BoxIcon } from 'src/assets/icons/kit/box.svg';
import { ReactComponent as HomeIcon } from 'src/assets/icons/kit/home.svg';
import { ReactComponent as DecorationIcon } from 'src/assets/icons/kit/decoration.svg';
import { ReactComponent as DashIcon } from 'src/assets/icons/kit/dash.svg';
import cn from 'classnames';

interface StatusesFilterProps {
  handleSelect: (id: number, filterName: keyof PolygonsFilterType) => void;
  tempFilter: PolygonsFilterType;
}

export const StatusesFilter: FC<StatusesFilterProps> = ({ handleSelect, tempFilter }) => {
  const { statuses } = useAppSelector(state => state.categories);

  const getSubStatusIcon = (statusColor: string, subStatusIcon: string) => {
    const style = {
      fill: statusColor,
    };
    const className = 'w-3 h-3 min-w-[0.75rem]';

    const subStatusIcons = {
      finger: <FingerIcon className={className} style={style} />,
      box: <BoxIcon className={className} style={style} />,
      home: <HomeIcon className={className} style={style} />,
      dash: <DashIcon className="w-6 h-6 min-w-[1.25rem]" style={{ stroke: statusColor }} />,
      decoration: <DecorationIcon className={className} style={style} />,
    };

    return subStatusIcons[subStatusIcon];
  };

  return (
    <div className="flex flex-wrap gap-2">
      {statuses.map(status => (
        <div
          className={cn({
            'min-w-full w-full flex gap-2 flex-wrap t-lg:flex-nowrap': status.substatuses?.length,
          })}
          key={`status-filter-${status.id}`}
        >
          <ZoneTag
            fullWidth
            category={{
              ...status,
              icon: getSubStatusIcon(status?.color || '', 'dash'),
            }}
            isActive={!!tempFilter?.statuses?.includes(status.id)}
            onClick={() => handleSelect(status.id, 'statuses')}
          />
          {status?.substatuses?.length
            ? status.substatuses.map(subStatus => (
                <ZoneTag
                  fullWidth
                  key={`substatus-filter-${subStatus.id}`}
                  category={{
                    ...subStatus,
                    color: status.color,
                    icon: getSubStatusIcon(status?.color || '', subStatus?.icon || ''),
                  }}
                  isActive={!!tempFilter?.subStatuses?.includes(subStatus.id)}
                  onClick={() => handleSelect(subStatus.id, 'subStatuses')}
                />
              ))
            : null}
        </div>
      ))}
    </div>
  );
};
