import { ChangeEvent, FC, useRef, useState } from 'react';
import { useClickOutside } from 'src/hooks/useClickOutside';
import { Button } from 'src/components/Button';
import { Loader } from 'src/components/Loader';
import { checkUploading, formatBytes, notice } from 'src/utils';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { setIsBurgerOpen, setIsExcelUploadVisible } from 'src/store/common';
import ExcelService from 'src/services/ExcelService';

import { ReactComponent as ExcelImportIcon } from 'src/assets/icons/kit/excel-import.svg';
import { ReactComponent as ExcelFileIcon } from 'src/assets/icons/excel-file.svg';
import { ReactComponent as CloseIcon } from 'src/assets/icons/kit/close.svg';
import cn from 'classnames';
import { AppToolTip } from 'src/components/AppToolTip';

interface ImportExcelButtonProps {
  variant: 'icon' | 'text';
  classNames?: string;
}

export const ImportExcelButton: FC<ImportExcelButtonProps> = ({ variant, classNames }) => {
  const { isExcelUpload } = useAppSelector(state => state.common);

  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [excel, setExcel] = useState<File | null>(null);

  const handleOpen = () => {
    if (isExcelUpload) {
      dispatch(setIsExcelUploadVisible(true));
      return;
    }
    setIsOpen(true);
  };
  const handleClose = () => setIsOpen(false);

  useClickOutside(ref, handleClose);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.files?.length) {
      return;
    }
    saveFile(e.target.files[0]);
  };

  const handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      saveFile(e.dataTransfer.files[0]);
    }
  };

  const saveFile = file => {
    const maxSize = 25 * 1024 * 1024;
    if (file.size > maxSize) {
      notice({
        title: 'Ошибка!',
        message: 'максимальный размер - 25мб',
        type: 'danger',
      });
      return;
    }

    setExcel(file);
  };

  const handleSend = async () => {
    if (!excel) {
      notice({
        title: 'Ошибка!',
        message: 'Файл не выбран',
        type: 'danger',
      });
      return;
    }

    try {
      const formData = new FormData();
      formData.append('xlsx', excel, excel.name);

      const res = await ExcelService.importExcel(formData);

      if (res?.data?.error && res?.data?.success) {
        notice({
          title: 'Ошибка!',
          message: 'Импорт или экспорт уже выполняется ',
          type: 'danger',
        });
        return;
      }

      checkUploading(dispatch);
    } catch (e) {
      console.log(e.message);
    } finally {
      dispatch(setIsBurgerOpen(false));
      handleClose();
      setExcel(null);
      setIsLoading(false);
    }
  };

  return (
    <div className={cn('relative z-20', classNames)}>
      <div
        className={cn(
          {
            'shadow-greens-100 bg-green-200': isOpen,
            'bg-green-400': !isOpen,
            'w-8 h-8 t-lg:w-11 t-lg:h-11 center rounded': variant === 'icon',
            'w-full h-10 flex items-center px-2.5 text-sm py-3 border-b border-green-500':
              variant === 'text',
          },
          ' hover:bg-green-200 cursor-pointer transition-full hover:shadow-greens-100',
        )}
        onClick={handleOpen}
      >
        {variant === 'text' ? (
          <AppToolTip classNames="font-gothampro-500 text-white" text="Импорт" />
        ) : null}
        <ExcelImportIcon
          className={cn({ 'ml-auto': variant === 'text' }, 'fill-white w-4 h-4 t-lg:w-5 t-lg:h-5')}
        />
      </div>

      {isOpen ? (
        <div
          className={cn(
            {
              'top-9 t-lg:top-12': variant === 'icon',
              'top-10': variant === 'text',
            },
            'w-80 m-md:w-96 p-4 absolute right-0 rounded bg-white dark:bg-blue-800 flex flex-col',
          )}
          ref={ref}
        >
          {isLoading ? (
            <div className="w-full h-full flex items-center justify-center py-10">
              <Loader classNames="w-20 h-20" />
            </div>
          ) : (
            <>
              <div
                className="cursor-pointer w-5 h-5 rounded-full border border-grey-500 center ml-auto"
                onClick={handleClose}
              >
                <CloseIcon className="w-1.5 h-1.5 fill-grey-500" />
              </div>

              <div className="font-gothampro-500 text-black dark:text-white text-sm mb-5">
                Загрузите данные
              </div>

              {excel ? (
                <div className="flex gap-2 mb-5">
                  <ExcelFileIcon className="w-12 h-12" />
                  <div>
                    <div className="text-lg dark:text-white font-gothampro-400">{excel.name}</div>
                    <div className="text-grey-200 font-gothampro-400 text-xs">
                      {formatBytes(excel.size)}
                    </div>
                  </div>
                </div>
              ) : (
                <label
                  htmlFor="excel-import"
                  onDrop={handleDrop}
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  className={
                    'w-full mb-5 rounded border border-dashed border-grey-2100 p-3 bg-blue-1200 ' +
                    'dark:bg-blue-300 flex flex-col dark:border-blue-200'
                  }
                >
                  <div className="text-grey-500 text-xs font-gothampro-400 mx-auto mb-1 text-center">
                    Перетяните Excel файл в эту область
                  </div>
                  <div className="text-grey-500 text-extra-xs font-gothampro-400 mx-auto mb-5">
                    или нажмите на кнопку
                  </div>
                  <Button as="div" variant="grey" classNames="w-fit mx-auto">
                    Загрузить файл с компьютера
                  </Button>

                  <input
                    id="excel-import"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    type="file"
                    className="hidden"
                    onChange={handleChange}
                    multiple={false}
                  />
                </label>
              )}

              <Button variant="blue" size="big" onClick={handleSend}>
                Загрузить
              </Button>
            </>
          )}
        </div>
      ) : null}
    </div>
  );
};
