import { FC, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { getFilteredPolygons } from 'src/selectors/polygonSelectors';
import { renderMarker } from 'src/components/Map/LeafletMapMarkerCluster/helpers';
import { useWindowDimensions } from 'src/hooks/useWindowDimension';

import MarkerClusterGroup from 'react-leaflet-cluster';

export const LeafletMapMarkers: FC = () => {
  const dispatch = useAppDispatch();
  const { width } = useWindowDimensions();

  const lassoFilter = useAppSelector(state => state.filters.lassoFilter);
  const filteredPolygons = useAppSelector(state => getFilteredPolygons(state));

  const markersList = useMemo(
    () => (
      <>
        {filteredPolygons?.map((polygon, i) =>
          renderMarker(polygon, width, i, dispatch, lassoFilter),
        )}
      </>
    ),
    [filteredPolygons, lassoFilter],
  );

  return (
    <>
      <MarkerClusterGroup disableClusteringAtZoom={1}>{markersList}</MarkerClusterGroup>
    </>
  );
};
