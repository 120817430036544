import { FC, useEffect, useMemo, useState } from 'react';
import { Input } from 'src/components/Input';
import { useForm } from 'react-hook-form';
import { Select } from 'src/components/Select';
import { Comment } from 'src/components/Comment';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import {
  setIsNewPolygonFormOpen,
  setOpenedDropBlock,
  setPolygonEdit,
  setResetForm,
} from 'src/store/common';
import { stateToHTML } from 'draft-js-export-html';
import { FormButtons } from 'src/components/Forms/NewPolygonForm/FormButtons';
import { useWindowDimensions } from 'src/hooks/useWindowDimension';
import {
  setDrawPolygons,
  setNotSavedDrawFavoritePolygonId,
  setReestrPolygons,
  setResetPolygon,
  setSelectedPolygon,
} from 'src/store/polygons';
import {
  geoJSONToCoords,
  getPolygonName,
  getPolygonSelectLists,
  removeQueryParams,
  selectPolygonSelects,
} from 'src/utils/helpers';
import { AppToolTip } from 'src/components/AppToolTip';
import { useAuth } from 'src/hooks/useAuth';
import { Loader } from 'src/components/Loader';
import { PolygonType, PolygonSelectsType, SelectItemType } from 'src/config/types';
import { EditorState } from 'draft-js';
import { initialPolygonSelects } from 'src/config/data';
import PolygonsService from 'src/services/PolygonsService';

import { ReactComponent as ArrowIcon } from 'src/assets/icons/kit/arrow.svg';
import cn from 'classnames';
import { fetchFavoritePolygons } from 'src/store/polygons/actions';

interface NewPolygonFormProps {
  onClick: () => void;
  classNames?: string;
}

export const NewPolygonForm: FC<NewPolygonFormProps> = ({ onClick, classNames }) => {
  const { user } = useAuth();
  const {
    reset,
    resetField,
    register,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<PolygonType>();

  const { width } = useWindowDimensions();
  const dispatch = useAppDispatch();
  const categories = useAppSelector(state => state.categories);
  const { resetFormTrigger, polygonEdit, openedDropBlocks, isNewPolygonFormOpen } = useAppSelector(
    state => state.common,
  );
  const {
    selectedPolygon,
    reestrPolygons,
    drawPolygons,
    isLoadingSelectedPolygon,
    notSavedDrawFavoritePolygonId,
    resetPolygon,
  } = useAppSelector(state => state.polygons);

  const [isLoading, setIsLoading] = useState(false);
  const [polygonSelects, setPolygonSelects] = useState<PolygonSelectsType>(initialPolygonSelects);
  const [comment, setComment] = useState<EditorState>(EditorState.createEmpty());

  const isActive = !!selectedPolygon || isLoadingSelectedPolygon;
  const isInfoOpen = !!openedDropBlocks?.['info'];
  const notNew = selectedPolygon && !selectedPolygon?.id;
  const disabled =
    (selectedPolygon?.id && !polygonEdit && !notSavedDrawFavoritePolygonId) || isLoading;
  const selectData = {
    errors: errors,
    disabled: disabled,
    register: register,
    clearErrors: clearErrors,
    setValue: setValue,
    variant: 'top' as 'top' | 'bottom' | undefined,
  };

  const handleSetComment = (comment: EditorState) => {
    setValue('comments', stateToHTML(comment.getCurrentContent()));
    setComment(comment);
  };

  const handleOpen = () => {
    dispatch(setIsNewPolygonFormOpen(!isNewPolygonFormOpen));
  };

  const handleChangeStatus = (arg: SelectItemType) => {
    if (disabled || !selectedPolygon) return;

    setPolygonSelects(prev => ({ ...prev, status: arg }));
    dispatch(setSelectedPolygon({ polygon: { ...selectedPolygon, status: +arg.value } }));

    const selectedStatus = categories.statuses.find(el => (el?.id || 0) === +arg.value);

    if (!selectedStatus?.substatuses?.length) {
      resetField('substatus');
    }
  };

  const handleChangeBlockStatus = (arg: SelectItemType) => {
    if (disabled || !selectedPolygon) return;

    setPolygonSelects(prev => ({ ...prev, blockStatus: arg }));
    dispatch(setSelectedPolygon({ polygon: { ...selectedPolygon, block_status: +arg.value } }));
  };

  const handleChangeCategory = (arg: SelectItemType) => {
    if (disabled || !selectedPolygon) return;

    setPolygonSelects(prev => ({ ...prev, category: arg }));
    dispatch(setSelectedPolygon({ polygon: { ...selectedPolygon, category: +arg.value } }));
  };

  useEffect(() => {
    if (!resetFormTrigger) return;
    reset();
    setComment(EditorState.createEmpty());
    setPolygonSelects(initialPolygonSelects);
    dispatch(setResetForm(false));
  }, [resetFormTrigger]);

  useEffect(() => {
    if (!selectedPolygon || !resetPolygon) return;

    handleSelect(selectedPolygon);
    dispatch(setResetPolygon(false));
  }, [selectedPolygon, resetPolygon]);

  const polygonsSelectLists = useMemo(
    () => getPolygonSelectLists(categories, polygonSelects),
    [categories, polygonSelects],
  );

  const [newPolygonsSelectLists, setNewPolygonsSelectLists] = useState<{
    owners: SelectItemType[];
  } | null>();

  const handleSelect = (polygon: PolygonType) => {
    selectPolygonSelects(polygon, categories, setPolygonSelects, setValue, setComment);
    setValue('name', polygon?.name || polygon?.properties?.name || '');
    setValue('status', polygon?.status || polygon?.properties?.status || undefined);
    setValue('comments', polygon?.comments || polygon?.properties?.comments || undefined);
    polygon?.substatus ||
      (polygon?.properties?.substatus &&
        setValue('substatus', polygon?.substatus || polygon?.properties?.substatus));
  };

  const handleSavePolygon = async params => {
    if (!selectedPolygon) return;

    if (selectedPolygon?.cadaster_number) {
      const newPolygon = await PolygonsService.insertReestrPolygon(params);
      dispatch(setReestrPolygons([...reestrPolygons, newPolygon]));
    }

    if (!selectedPolygon?.cadaster_number) {
      const newPolygon = await PolygonsService.insertDrawPolygon(params);
      dispatch(setDrawPolygons([...drawPolygons, newPolygon]));

      if (notSavedDrawFavoritePolygonId) {
        const newFavorite: PolygonType = {
          name: params.name || params?.properties?.name,
          comments: selectedPolygon.comments || selectedPolygon?.properties?.comments,
          geometry: params.geometry,
          square: params.square || params?.properties?.square,
          status: params.status || params?.properties?.status,
          drawPolygonId: newPolygon.id,
        };

        await PolygonsService.updateFavorite(newFavorite, notSavedDrawFavoritePolygonId);
        dispatch(fetchFavoritePolygons());
      }
    }
  };

  const handleUpdatePolygon = async params => {
    if (!selectedPolygon?.id) return;

    if (selectedPolygon?.cadaster_number) {
      const newPolygon = await PolygonsService.updateReestrPolygon(params, selectedPolygon.id);
      dispatch(
        setReestrPolygons(
          reestrPolygons.map(polygon => {
            if (polygon.id === selectedPolygon.id) {
              return newPolygon;
            }
            return polygon;
          }),
        ),
      );
    }

    if (!selectedPolygon?.cadaster_number) {
      const newPolygon = await PolygonsService.updateDrawPolygon(params, selectedPolygon.id);
      dispatch(
        setDrawPolygons(
          drawPolygons.map(polygon => {
            if (polygon.id === selectedPolygon.id) {
              return newPolygon;
            }
            return polygon;
          }),
        ),
      );
    }

    dispatch(setPolygonEdit(false));
  };

  const onSubmit = async data => {
    if (isLoading) return;

    setIsLoading(true);
    const isMulti = selectedPolygon?.geometry?.includes('MULTIPOLYGON');
    const coordinates = geoJSONToCoords(selectedPolygon?.geometry || '');

    const newData = {
      ...selectedPolygon,
      center: selectedPolygon?.center,
      comments: data?.comments || '',
      name: data.name || selectedPolygon?.name || selectedPolygon?.properties?.name || '',
      status: +data.status,
      ...(data.substatus && { substatus: +data.substatus }),
      ...(data.owner && {
        owner: +data.owner,
      }),
      ...(data.type_owner && { type_owner: +data.type_owner }),
      ...(data.block_status && { block_status: +data.block_status }),
      ...(data.category && { category: +data.category }),
      ...(notSavedDrawFavoritePolygonId && {
        favoritePolygonId: notSavedDrawFavoritePolygonId,
      }),
    };

    const params = {
      ...selectedPolygon,
      ...newData,
      ...(selectedPolygon?.geometry && {
        geometry: {
          type: isMulti ? 'MultiPolygon' : 'Polygon',
          coordinates: isMulti ? [coordinates] : coordinates,
        },
      }),
      properties: {
        ...selectedPolygon?.properties,
        ...newData,
      },
    };

    try {
      if (notNew) {
        await handleSavePolygon(params);
      }

      if (!notNew) {
        await handleUpdatePolygon(params);
      }

      removeQueryParams();
      dispatch(setIsNewPolygonFormOpen(false));
      dispatch(setSelectedPolygon({ polygon: null }));
      dispatch(setResetForm(true));
      dispatch(setOpenedDropBlock({}));
    } catch (e) {
      console.log(e.message);
    } finally {
      setIsLoading(false);
      dispatch(setNotSavedDrawFavoritePolygonId(null));
    }
  };

  const nameLine = (str: string) => (
    <div className="mb-1 font-gothampro-400 text-grey-500 text-extra-xs whitespace-nowrap">
      {str}
    </div>
  );

  useEffect(() => {
    setNewPolygonsSelectLists(polygonsSelectLists);
  }, [polygonsSelectLists]);

  return (user?.isLeader && polygonSelects.status) || user?.isManager ? (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={cn(
        {
          '-bottom-full': !isActive,
          'bottom-8': !isNewPolygonFormOpen && isActive,
          'bottom-[30rem] m-md:bottom-[28rem] t-sm:bottom-[18.5rem]':
            isNewPolygonFormOpen && isActive,
          't-lg:pr-0 d-xl:pr-60': isInfoOpen,
        },
        'absolute h-0 transition-full flex justify-center',
        'w-full px-4',
        classNames,
      )}
      onClick={onClick}
    >
      <div
        className={cn(
          { 't-lg:mr-[20rem] d-xs:mr-[30rem] d-lg:mr-[39rem] d-xl:mr-0': isInfoOpen },
          'w-full px-3 t-lg:px-5 t-sm:py-12 py-12 h-[30rem] m-md:h-[28rem] t-sm:h-[18.5rem]',
          'shadow-blacks-200 bg-white relative dark:bg-blue-300 transition-full',
          't-lg:w-[36rem] d-xs:w-[38rem] d-lg:w-[44rem] rounded-t-xl',
        )}
      >
        <div
          className={
            'w-14 h-14 rounded-full cursor-pointer flex items-center justify-center ' +
            'cursor-pointer bg-blue-100 absolute -top-7 hover:shadow-blues-100 transition-full'
          }
          style={{ left: 'calc(50% - 28px)' }}
          onClick={() => {
            handleOpen();
            if (!selectedPolygon) {
              return;
            }
            width < 1024 && dispatch(setOpenedDropBlock({}));
          }}
        >
          <ArrowIcon
            className={cn(
              { 'rotate-180 mt-1': isNewPolygonFormOpen },
              'w-6 h-6 stroke-path-white transition-full',
            )}
          />
        </div>
        {isLoadingSelectedPolygon || isLoading ? (
          <div className="w-full h-full flex items-center justify-center">
            <Loader classNames="w-20 h-20" />
          </div>
        ) : null}

        {!(isLoadingSelectedPolygon || isLoading) ? (
          <>
            <div className="-mt-4 mb-2">
              {selectedPolygon?.cadaster_number ? (
                <AppToolTip
                  checkWidth
                  text={getPolygonName(selectedPolygon)}
                  classNames="font-gothampro-500 text-sm dark:text-white truncate h-7"
                />
              ) : (
                <Input
                  data={{
                    name: 'name',
                    placeholder: 'Название участка',
                    required: false,
                  }}
                  errors={errors}
                  register={register}
                  disabled={disabled}
                  classNames="t-sm:w-64"
                />
              )}
            </div>

            <div className="w-full flex t-sm:gap-6 gap-1 flex-col t-sm:flex-row mb-2 t-lg:mb-5">
              <div className="w-full t-sm:w-64 min-w-[10rem]">
                <>
                  {nameLine('Группа объекта')}
                  <Select
                    data={{ name: 'category', required: false, placeholder: 'Группа объекта' }}
                    list={polygonsSelectLists.categories}
                    value={polygonSelects.category}
                    onChange={handleChangeCategory}
                    classNames="mb-1 t-sm:mb-3"
                    withSearch
                    noIcon={!user?.is_colorpicker}
                    {...selectData}
                  />

                  {nameLine('Направление')}
                  <Select
                    data={{ name: 'block_status', required: false, placeholder: 'Направление' }}
                    list={polygonsSelectLists.blockStatuses}
                    value={polygonSelects.blockStatus}
                    onChange={handleChangeBlockStatus}
                    classNames="mb-1 t-sm:mb-3"
                    {...selectData}
                  />

                  {nameLine('Вид права')}
                  <Select
                    data={{ name: 'type_owner', required: false, placeholder: 'Введите вид права' }}
                    list={polygonsSelectLists.typeOwners}
                    value={polygonSelects.typeOwner}
                    onChange={typeOwner => setPolygonSelects(prev => ({ ...prev, typeOwner }))}
                    classNames="mb-1 t-sm:mb-3"
                    {...selectData}
                  />

                  {nameLine('Правообладатель')}

                  <Select
                    data={{
                      name: 'owner',
                      required: false,
                      placeholder: 'Введите имя правообладателя',
                    }}
                    withSearch
                    list={newPolygonsSelectLists ? newPolygonsSelectLists.owners : []}
                    value={polygonSelects.owner}
                    onChange={owner => setPolygonSelects(prev => ({ ...prev, owner }))}
                    {...selectData}
                  />
                </>
              </div>
              <div className="w-full flex flex-col">
                <div className="flex w-full gap-2 t-sm:gap-8">
                  <div className="w-1/2">
                    {nameLine('Статус')}
                    <Select
                      data={{ name: 'status', required: true, placeholder: 'Статус' }}
                      list={polygonsSelectLists.statuses}
                      value={polygonSelects.status}
                      onChange={handleChangeStatus}
                      classNames="mb-1 t-sm:mb-3"
                      {...selectData}
                    />
                  </div>

                  {polygonsSelectLists.subStatuses?.length ? (
                    <div className="w-1/2">
                      {nameLine('Подстатус')}
                      <Select
                        data={{ name: 'substatus', required: true, placeholder: 'Подстатус' }}
                        list={polygonsSelectLists.subStatuses}
                        value={polygonSelects.subStatus}
                        onChange={subStatus => setPolygonSelects(prev => ({ ...prev, subStatus }))}
                        classNames="mb-1 t-sm:mb-3"
                        {...selectData}
                      />
                    </div>
                  ) : null}
                </div>

                {nameLine('Комментарий')}
                <Comment comment={comment} disabled={disabled} setComment={handleSetComment} />

                <FormButtons
                  handleSelect={handleSelect}
                  handleOpen={handleOpen}
                  notNew={notNew}
                  isOpen={isNewPolygonFormOpen}
                />
              </div>
            </div>
          </>
        ) : null}
      </div>
    </form>
  ) : null;
};
