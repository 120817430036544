import { FC, useEffect, useMemo, useState } from 'react';
import { LeafletMapPolygons } from 'src/components/Map/LeafletMapPolygons';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { LeafletRuler } from 'src/components/Map/LeafletRuler';
import { LeafletMapLayers } from 'src/components/Map/LeafletMapLayers';
import { setFlyTo } from 'src/store/map';
import { LeafletMapMyPosition } from 'src/components/Map/LeafletMapMyPosition';
import { LeafletMapSelectedPolygonTooltip } from 'src/components/Map/LeafletMapSelectedPolygonTooltip';
import { LeafletMapMarkerCluster } from 'src/components/Map/LeafletMapMarkerCluster';
import { LeafletMapFavoritePolygonPopUp } from 'src/components/Map/LeafletMapFavoritePolygonPopUp';
import { LeafletMapMarkers } from 'src/components/Map/LeafletMapMarkers';
import { LeafletMapEditControl } from 'src/components/Map/LeafletMapEditControl';
import { fetchSelectedPolygon } from 'src/store/polygons/actions';
import { useAuth } from 'src/hooks/useAuth';
import { isDrawing } from 'src/utils/helpers';
import { LeafletMapLasso } from 'src/components/Map/LeafletMapLasso';
import { sleep } from 'src/utils';
import { ruDrawOptions, ruEditOptions } from 'src/config/data';
import { TileLayer, useMap, useMapEvents } from 'react-leaflet';
import L from 'leaflet';

import './styles.css';

export const LeafletMap: FC = () => {
  const { selectedLayers, flyTo, mainTileUrl } = useAppSelector(state => state.map);
  const { isClustersActive } = useAppSelector(state => state.common);
  const { selectedPolygon, favoritePolygons, reestrPolygons, isLoadingReestrPolygons } =
    useAppSelector(state => state.polygons);

  const { user } = useAuth();

  const dispatch = useAppDispatch();
  const mapHook = useMap();

  const [zoom, setZoom] = useState(15);

  useEffect(() => {
    if (L?.['drawLocal']?.draw && L?.['drawLocal']?.edit) {
      L['drawLocal'].draw = ruDrawOptions;
      L['drawLocal'].edit = ruEditOptions;
    }
  }, []);

  useEffect(() => {
    if (!flyTo || !mapHook) return;

    mapHook.setView(flyTo, 16);
    setZoom(16);
    dispatch(setFlyTo(null));
  }, [flyTo]);

  useMapEvents({
    click: async e => {
      await sleep(100);
      const favoriteForm = document.querySelector('#favorite-form');
      const lassoActive = document.querySelector('.leaflet-lasso-close');

      if ((favoriteForm && favoriteForm.contains(e?.originalEvent?.target as Node)) || lassoActive)
        return;

      if (isDrawing() || !selectedLayers?.length) return;

      await dispatch(
        fetchSelectedPolygon({ coords: [e.latlng.lat, e.latlng.lng], selectedLayers }),
      );
    },
    zoomend: e => {
      setZoom(e.target.getZoom());
    },
  });

  const checkNewForm = useMemo(
    () =>
      isLoadingReestrPolygons ||
      !!favoritePolygons.find(
        polygon => polygon?.properties?.cadaster_number === selectedPolygon?.cadaster_number,
      ) ||
      !!favoritePolygons.find(polygon => polygon?.id === selectedPolygon?.id) ||
      !!reestrPolygons.find(
        polygon => polygon?.properties?.cadaster_number === selectedPolygon?.cadaster_number,
      ),
    [selectedPolygon, favoritePolygons, reestrPolygons],
  );

  return (
    <>
      <LeafletMapLayers />
      <LeafletMapPolygons />
      <LeafletMapEditControl />
      <LeafletRuler />
      <LeafletMapMyPosition />
      <LeafletMapLasso />

      {mainTileUrl.map((tileUrl, i) => {
        return <TileLayer key={`tile-url-${i}`} maxZoom={19} url={tileUrl} />;
      })}

      {!checkNewForm && !selectedPolygon?.id && user?.isLeader ? (
        <LeafletMapFavoritePolygonPopUp />
      ) : null}
      {zoom >= 16 ? <LeafletMapSelectedPolygonTooltip /> : null}
      {isClustersActive ? <LeafletMapMarkerCluster /> : null}
      {!isClustersActive && zoom >= 16 ? <LeafletMapMarkers /> : null}
    </>
  );
};
