import { FC, ReactNode, useEffect, useState } from 'react';
import { Header } from 'src/components/Header';
import { useWindowDimensions } from 'src/hooks/useWindowDimension';
import { useLocation } from 'react-router';
import { LoaderFull } from 'src/components/LoaderFull';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { ReactNotifications } from 'react-notifications-component';
import { setTooltip } from 'src/store/common';

import 'react-notifications-component/dist/theme.css';
import cn from 'classnames';

interface MainContainerProps {
  children?: ReactNode;
}

export const MainContainer: FC<MainContainerProps> = ({ children }) => {
  const { selectedLayers } = useAppSelector(state => state.map);
  const { darkMode, isLoaderFullActive, isBurgerOpen, toolTip } = useAppSelector(
    state => state.common,
  );

  const { width } = useWindowDimensions();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const getInitialHeadHeight = () =>
    width >= 1024 ? (selectedLayers?.length ? 114 : 76) : selectedLayers?.length ? 142 : 104;

  const [headHeight, setHeadHeight] = useState<number>(getInitialHeadHeight());

  useEffect(() => {
    const head = document.querySelector('#head') as HTMLDivElement;

    if (!head) return;

    setHeadHeight(head.offsetHeight);
  }, [selectedLayers, width, location, isBurgerOpen]);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  const onMouseEnterHandler = () => {
    const toolTip = document.querySelector('#tooltip');

    if (!toolTip) return;

    toolTip.setAttribute('data-active', 'true');
  };

  const onMouseLeave = () => {
    const toolTip = document.querySelector('#tooltip');

    if (!toolTip) return;

    toolTip.removeAttribute('data-active');

    dispatch(setTooltip(null));
  };

  return (
    <>
      <Header />
      <ReactNotifications />
      <div
        className={cn('w-full')}
        style={{ height: `calc(100dvh - ${Math.ceil(headHeight) + 1}px)` }}
      >
        {children}
      </div>

      {isLoaderFullActive ? <LoaderFull /> : null}

      {toolTip ? (
        <div
          className={
            'fixed max-w-[18rem] text-extra-xs py-2 px-3 font-gothampro-400 rounded-lg max-h-[6rem] ' +
            '-translate-x-2/4 bg-white-100 dark:bg-blue-400 dark:text-white z-10000 overflow-auto'
          }
          onMouseEnter={onMouseEnterHandler}
          onMouseLeave={onMouseLeave}
          id="tooltip"
          style={{
            top: `${toolTip.top}px`,
            left: `${toolTip.left}px`,
          }}
        >
          {toolTip.text}
        </div>
      ) : null}
    </>
  );
};
