import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useWindowDimensions } from 'src/hooks/useWindowDimension';
import { useAppDispatch } from 'src/hooks/useRedux';
import { setTooltip } from 'src/store/common';
import { sleep } from 'src/utils';

import cn from 'classnames';

interface AppToolTip {
  text?: string;
  onClick?: () => void;
  checkWidth?: boolean;
  noTruncate?: boolean;
  width?: string;
  classNames?: string;
  children?: ReactNode;
}

export const AppToolTip: FC<AppToolTip> = ({
  text,
  onClick,
  width,
  checkWidth,
  noTruncate,
  classNames,
  children,
}) => {
  const { width: winWidth } = useWindowDimensions();
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const refText = useRef<HTMLDivElement>(null);

  const [active, setActive] = useState<boolean>(false);

  useEffect(() => {
    if (!checkWidth) {
      setActive(true);
      return;
    }

    if (refText?.current) {
      setActive(
        refText.current.offsetWidth < refText.current.scrollWidth ||
          refText.current.offsetHeight < refText.current.scrollHeight,
      );
    }
  }, [text, refText, checkWidth]);

  const removeTooltip = () => dispatch(setTooltip(null));

  useEffect(() => {
    removeTooltip();
  }, [winWidth]);

  useEffect(() => {
    window.addEventListener('orientationchange', removeTooltip);
  }, []);

  const onMouseEnterHandler = () => {
    if (!text || !ref?.current || !active) return;
    const boundingClient = ref.current.getBoundingClientRect();

    const left = boundingClient.left + ref.current.offsetWidth / 2;
    const top = boundingClient.top + ref.current.offsetHeight + 6;

    dispatch(setTooltip({ text, top, left }));
  };

  const onMouseLeaveHandler = async () => {
    await sleep(500);

    const toolTip = document.querySelector('#tooltip');

    if (!toolTip) return;

    const isActive = toolTip.getAttribute('data-active');

    if (!isActive) {
      removeTooltip();
    }
  };

  return (
    <>
      <div
        onClick={onClick}
        className={cn('relative w-full', children ? classNames : '')}
        ref={ref}
        style={{ width }}
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
      >
        {children || (
          <div ref={refText} className={cn({ truncate: !Boolean(noTruncate) }, classNames)}>
            {text}
          </div>
        )}
      </div>
    </>
  );
};
