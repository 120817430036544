import { FC, ReactNode } from 'react';
import { Input } from 'src/components/Input';
import { useAuth } from 'src/hooks/useAuth';
import { Loader } from 'src/components/Loader';
import { Button } from 'src/components/Button';
import { useNavigate } from 'react-router';
import AuthService from 'src/services/AuthService';
import { notice } from 'src/utils';

export const UserPage: FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleBack = () => navigate(-1);

  const getTitle = (text: string): ReactNode => (
    <div className="ml-3 mb-0.5 text-xs font-gothampro-400 text-grey-3000">{text}</div>
  );

  const handleResetPassword = async () => {
    try {
      const res = await AuthService.resetPassword();

      if (res?.message) {
        notice({
          message: res.message,
          type: 'success',
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="px-4 max-w-[32rem] w-full h-full flex flex-col gap-8">
      <div className="text-2xl font-gothampro-500 dark:text-white">Ваш профиль</div>

      {!user ? (
        <div className="w-full square center">
          <Loader classNames="w-40 h-40" />
        </div>
      ) : null}

      {user ? (
        <>
          <div className="flex flex-col gap-3 w-full">
            <div className="w-full">
              {getTitle('Ваш логин')}
              <Input defaultValue={user?.username} disabled />
            </div>

            {user?.first_name ? (
              <div className="w-full">
                {getTitle('Ваше имя')}
                <Input defaultValue={user?.first_name} disabled />
              </div>
            ) : null}

            {user?.last_name ? (
              <div className="w-full">
                {getTitle('Ваша фамилия')}
                <Input defaultValue={user?.last_name} disabled />
              </div>
            ) : null}

            {user?.email ? (
              <div className="w-full">
                {getTitle('Ваш e-mail')}
                <Input defaultValue={user?.email} disabled />
              </div>
            ) : null}
          </div>

          <div className="flex flex-col m-md:flex-row m-md:items-center m-md:gap-2 gap-1">
            <div className="text-sm font-gothampro-400 dark:text-white">Хотите сменить пароль?</div>
            <div
              className="text-sm font-gothampro-400 text-blue-100 cursor-pointer underline"
              onClick={handleResetPassword}
            >
              Обратиться к администратору
            </div>
          </div>

          <Button variant="blue" classNames="w-80" size="big" onClick={handleBack}>
            Назад
          </Button>
        </>
      ) : null}
    </div>
  );
};
