import { FC, ReactNode, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { GroupsFilter } from 'src/components/FIlterPolygons/GroupsFilter';
import { Button } from 'src/components/Button';
import { setPolygonsFilter } from 'src/store/filters';
import { StatusesFilter } from 'src/components/FIlterPolygons/StatusesFilter';
import { TypeOwnersFilter } from 'src/components/FIlterPolygons/TypeOwnersFilter';
import { BlockStatusesFilter } from 'src/components/FIlterPolygons/BlockStatusesFilter';
import { OwnersFilter } from 'src/components/FIlterPolygons/OwnersFilter';
import { Loader } from 'src/components/Loader';
import { fetchInfoData } from 'src/store/polygons/actions';
import { PolygonsFilterType } from 'src/config/types';

import cn from 'classnames';

interface FilterPolygonsProps {
  handleClose: () => void;
  classNames?: string;
}

export const FilterPolygons: FC<FilterPolygonsProps> = ({ handleClose, classNames }) => {
  const { polygonsFilter } = useAppSelector(state => state.filters);
  const { isLoadingAllInfo } = useAppSelector(state => state.categories);

  const [tempFilter, setTempFilter] = useState<PolygonsFilterType>(polygonsFilter);

  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);

  const isFilterActive = useMemo(() => !!Object.keys(polygonsFilter)?.length, [polygonsFilter]);

  const handleSelect = (id: number, filterName: keyof PolygonsFilterType) => {
    const newTempFilterByName = tempFilter?.[filterName]?.includes(id)
      ? tempFilter?.[filterName]?.filter(el => el !== id)
      : [...(tempFilter?.[filterName] || []), id];

    setTempFilter(prev => ({ ...prev, [filterName]: newTempFilterByName }));
  };

  const handleSaveFilters = () => {
    if (JSON.stringify(tempFilter.categories) !== JSON.stringify(polygonsFilter.categories)) {
      dispatch(fetchInfoData(tempFilter?.categories || []));
    }

    dispatch(setPolygonsFilter(tempFilter));
    handleClose();
  };

  const handleResetFilters = () => {
    dispatch(fetchInfoData([]));
    setTempFilter({});
    dispatch(setPolygonsFilter({}));
    handleClose();
  };

  const getTitle = (title: string) => (
    <div className="text-extra-xs dark:text-white font-gothampro-400 opacity-50 mb-2">{title}</div>
  );

  const smoothScrollTo = (id: string) => {
    if (!ref?.current) return;

    const block = document.querySelector(`#${id}`) as HTMLDivElement;

    if (!block) return;

    ref.current.scrollTo({
      top: block.offsetHeight + block.offsetTop - ref.current.clientHeight,
      left: 0,
      behavior: 'smooth',
    });
  };

  const props = { handleSelect, tempFilter, smoothScrollTo };

  const filters: { label: string; component: ReactNode; id?: string }[] = [
    {
      label: 'Направления',
      component: <BlockStatusesFilter {...props} />,
    },
    {
      label: 'Группы объектов',
      component: <GroupsFilter {...props} />,
      id: 'group',
    },
    {
      label: 'Правообладатели',
      component: <OwnersFilter {...props} />,
      id: 'owner',
    },
    {
      label: 'Статус',
      component: <StatusesFilter {...props} />,
    },
    {
      label: 'Вид права',
      component: <TypeOwnersFilter {...props} />,
    },
  ];

  return (
    <div className={cn('relative', classNames)}>
      {isLoadingAllInfo ? (
        <div className="t-lg:w-[34rem] d-xs:w-[40rem] h-[50vh] flex items-center justify-center py-10">
          <Loader classNames="w-20 h-20" />
        </div>
      ) : null}

      {!isLoadingAllInfo ? (
        <>
          <div className="max-h-[50vh] overflow-auto flex flex-col gap-7" ref={ref}>
            {filters.map(filter => (
              <div
                key={`filter-list-${filter.label}`}
                id={`filter-${filter.id}`}
                className="w-full"
              >
                {getTitle(filter.label)}
                {filter.component}
              </div>
            ))}
          </div>

          <div className="flex flex-col t-sm:flex-row gap-2 t-sm:gap-5 justify-center pt-3 t-lg:pt-5">
            {isFilterActive ? (
              <Button
                variant="grey"
                size="big"
                classNames="w-full t-sm:w-60"
                onClick={handleResetFilters}
              >
                Сбросить все фильтры
              </Button>
            ) : null}
            <Button
              variant="blue"
              size="big"
              classNames="w-full t-sm:w-60"
              onClick={handleSaveFilters}
            >
              Применить
            </Button>
          </div>
        </>
      ) : null}
    </div>
  );
};
