import { Dispatch, FC, SetStateAction, useRef, useState } from 'react';
import { CheckBox } from 'src/components/CheckBox';
import { useClickOutside } from 'src/hooks/useClickOutside';
import { DashboardTableHeadFilter } from 'src/components/Dashboard/DashboardTableHeadFilter';
import { useAuth } from 'src/hooks/useAuth';
import { setCheckedPolygons } from 'src/store/polygons';
import { dashboardTableItems } from 'src/config/data';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import {
  DashboardPolygonsFilterType,
  DashboardTableSort,
  PolygonsFilterType,
  PolygonType,
} from 'src/config/types';

import { ReactComponent as SortIcon } from 'src/assets/icons/kit/sort.svg';
import { ReactComponent as FilterIcon } from 'src/assets/icons/kit/filter.svg';
import cn from 'classnames';

interface DashboardTableHeadProps {
  setIsAllChecked: Dispatch<SetStateAction<boolean>>;
  isAllChecked: boolean;
  polygons: PolygonType[];
  sort: DashboardTableSort | undefined;
  setSort: (arg: DashboardTableSort | undefined) => void;
}

export const DashboardTableHead: FC<DashboardTableHeadProps> = ({
  polygons,
  isAllChecked,
  setIsAllChecked,
  sort,
  setSort,
}) => {
  const dispatch = useAppDispatch();
  const { polygonsFilter, dashboardPolygonsFilter } = useAppSelector(state => state.filters);

  const ref = useRef<HTMLDivElement>(null);
  const { user } = useAuth();

  const [openFilter, setOpenFilter] = useState<
    keyof PolygonsFilterType | keyof DashboardPolygonsFilterType
  >();

  useClickOutside(ref, () => setOpenFilter(undefined));

  const handleSetOpenFilter = (
    filter?: keyof PolygonsFilterType | keyof DashboardPolygonsFilterType,
  ) => {
    if (filter === openFilter) {
      setOpenFilter(undefined);
      return;
    }

    setOpenFilter(filter);
  };

  const handleClickAllChecked = () => {
    if (!(user?.isLeader || user?.isManager)) return;

    if (isAllChecked) {
      setIsAllChecked(false);
      dispatch(setCheckedPolygons([]));
    } else {
      setIsAllChecked(true);
      dispatch(setCheckedPolygons(polygons));
    }
  };

  const handleSort = (field: keyof PolygonType) => {
    setSort({
      type: field === sort?.field ? (sort?.type === 'ASC' ? 'DESC' : 'ASC') : 'ASC',
      field,
    });
  };

  const isActiveFilter = name =>
    (name && polygonsFilter?.[name]?.length) || (name && dashboardPolygonsFilter?.[name]?.length);

  return (
    <div
      className={cn(
        {
          'bg-grey-2200 dark:bg-blue-700': Boolean(openFilter),
          'bg-white dark:bg-blue-200': !Boolean(openFilter),
        },
        'rounded-t w-fit t-lg:w-auto h-8 t-lg:h-14 min-h-[2rem] t-lg:min-h-[3.5rem] flex ' +
          'border-b border-grey-1100 dark:border-white items-center',
      )}
    >
      {dashboardTableItems.map((item, i) => (
        <div
          key={`dashboard-table-head-${i}`}
          className={cn(
            {
              'cursor-pointer': Boolean(item?.filter) || Boolean(item?.dashboardFilter),
              'bg-white dark:bg-blue-800 shadow-blacks-800': Boolean(
                (item?.filter && item?.filter === openFilter) ||
                  (item?.dashboardFilter && item?.dashboardFilter === openFilter),
              ),
            },
            'h-8 t-lg:h-14 flex group items-center border-b border-grey-1100 dark:border-white ' +
              'relative',
            item.className,
          )}
        >
          {item.label === 'checkBox' ? (
            <CheckBox isActive={isAllChecked} onClick={handleClickAllChecked} />
          ) : item.label === 'arrow' ? (
            <div className={item.className} />
          ) : (
            <div
              className={
                'dark:text-white text-ultra-xs t-lg:text-xs whitespace-nowrap font-gothampro-400 ' +
                'flex items-center gap-1'
              }
              {...(item.filter && {
                onClick: () => handleSetOpenFilter(item.filter),
              })}
              {...(item.dashboardFilter && {
                onClick: () => handleSetOpenFilter(item.dashboardFilter),
              })}
            >
              {item?.sort ? (
                <SortIcon
                  className={cn(
                    {
                      'rotate-180': sort?.field === item.sort && sort.type === 'ASC',
                      'rotate-0': sort?.field === item.sort && sort.type === 'DESC',
                      'fill-orange-100': sort?.field === item.sort,
                      'hover:fill-orange-100 fill-grey-2600': sort?.field !== item.sort,
                    },
                    'w-3.5 h-3.5 cursor-pointer transition-full',
                  )}
                  onClick={e => {
                    e?.stopPropagation();
                    item.sort && handleSort(item.sort);
                  }}
                />
              ) : null}
              {item.label}
              <FilterIcon
                className={cn(
                  {
                    'fill-blue-100': isActiveFilter(item.filter || item.dashboardFilter),
                    'fill-grey-2600': !isActiveFilter(item.filter || item.dashboardFilter),
                  },
                  'w-3 h-3 t-lg:w-4 t-lg:h-4 group-hover:fill-blue-100 transition-full',
                )}
              />
            </div>
          )}

          {openFilter === item?.filter && item.filter ? (
            <div ref={ref}>
              <DashboardTableHeadFilter filter={item.filter} />
            </div>
          ) : null}

          {openFilter === item?.dashboardFilter && item.dashboardFilter ? (
            <div ref={ref}>
              <DashboardTableHeadFilter filter={item.dashboardFilter} isDashboard />
            </div>
          ) : null}
        </div>
      ))}
    </div>
  );
};
