import { FC, useRef, useState } from 'react';
import { useClickOutside } from 'src/hooks/useClickOutside';
import { FilterPolygons } from 'src/components/FIlterPolygons';
import { calculateFiltersCount } from 'src/utils/helpers';
import { MapIcon } from 'src/components/MapIcon';
import { InfoData } from 'src/components/InfoData';
import { AppToolTip } from 'src/components/AppToolTip';
import { useAppSelector } from 'src/hooks/useRedux';
import { MapButtonType } from 'src/config/types';

import { ReactComponent as FilterIcon } from 'src/assets/icons/kit/filter.svg';
import { ReactComponent as CloseIcon } from 'src/assets/icons/kit/close.svg';
import { ReactComponent as MapPinIcon } from 'src/assets/icons/kit/map-pin.svg';
import { ReactComponent as InfoIcon } from 'src/assets/icons/kit/info.svg';

interface MapButtonsDesktopProps {
  moveToMyLocation?: () => void;
}

export const MapButtonsDesktop: FC<MapButtonsDesktopProps> = ({ moveToMyLocation }) => {
  const { polygonsFilter } = useAppSelector(state => state.filters);

  const ref = useRef<HTMLDivElement>(null);
  const [activeButton, setActiveButton] = useState<MapButtonType>();

  useClickOutside(ref, () => setActiveButton(undefined));

  const iconClassName = 'w-5 h-5 fill-white';

  const buttons: MapButtonType[] = [
    {
      id: 1,
      icon: <FilterIcon className={iconClassName} />,
      color: '#FF5500',
      label: 'Фильтры',
      count: calculateFiltersCount(polygonsFilter),
      content: (
        <FilterPolygons classNames="w-full" handleClose={() => setActiveButton(undefined)} />
      ),
    },
    {
      id: 2,
      icon: <InfoIcon className={iconClassName} />,
      color: '#0009FF',
      label: 'Информация',
      content: <InfoData />,
    },
  ];

  const handleSetActiveButton = (button: MapButtonType) => {
    if (activeButton?.id === button.id) {
      setActiveButton(undefined);
    } else {
      setActiveButton(button);
    }
  };

  return (
    <>
      {moveToMyLocation && (
        <div className="absolute right-4 t-lg:right-0 top-3 t-lg:top-14 z-2000">
          <div
            className={
              'w-[30px] h-[30px] bg-blue-100 cursor-pointer center hover:shadow-violets-100 rounded'
            }
            onClick={moveToMyLocation}
          >
            <MapPinIcon className={iconClassName} />
          </div>
        </div>
      )}

      <div className="flex flex-col absolute top-0 left-80 z-2300 ml-1 gap-1" ref={ref}>
        <div className="flex gap-1">
          {buttons.map(button => (
            <AppToolTip key={`desktop-map-button-${button.id}`} text={button?.label}>
              <MapIcon
                onClick={() => {
                  handleSetActiveButton(button);
                }}
                isActive={button?.id === activeButton?.id}
                isBlur={activeButton && button?.id !== activeButton?.id}
                {...button}
              />
            </AppToolTip>
          ))}
        </div>

        {activeButton ? (
          <div className="bg-white dark:bg-blue-800 rounded-md max-w-[34rem] d-xs:max-w-[40rem] p-5">
            <div className="flex justify-between pb-5">
              <div className="text-sm text-white font-gothampro-500">{activeButton.label}</div>
              <div
                className="w-5 h-5 border border-black dark:border-white cursor-pointer rounded-full center"
                onClick={() => setActiveButton(undefined)}
              >
                <CloseIcon className="w-2 h-2 dark:fill-white" />
              </div>
            </div>
            {activeButton.content}
          </div>
        ) : null}
      </div>
    </>
  );
};
