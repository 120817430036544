import { FC, useRef, useState } from 'react';
import { useClickOutside } from 'src/hooks/useClickOutside';
import { Button } from 'src/components/Button';
import { download, notice } from 'src/utils';
import { setIsExcelUploadVisible } from 'src/store/common';
import { Loader } from 'src/components/Loader';
import { BASE_EXCEL_URL } from 'src/config';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { ExcelExportColumnsType, ExcelExportType } from 'src/config/types';
import ExcelService from 'src/services/ExcelService';

import { ReactComponent as ExcelExportIcon } from 'src/assets/icons/kit/excel-export.svg';
import { ReactComponent as CloseIcon } from 'src/assets/icons/kit/close.svg';
import cn from 'classnames';
import { AppToolTip } from 'src/components/AppToolTip';

interface ExportExcelButtonProps {
  onClick?: () => void;
  variant: 'icon' | 'text';
  classNames?: string;
}

export const ExportExcelButton: FC<ExportExcelButtonProps> = ({ onClick, variant, classNames }) => {
  const { isExcelUpload } = useAppSelector(state => state.common);
  const { polygonsFilter, dashboardPolygonsFilter } = useAppSelector(state => state.filters);
  const { checkedPolygons } = useAppSelector(state => state.polygons);

  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [columns, setColumns] = useState<ExcelExportColumnsType>({
    block: true,
    category: true,
    cadaster_number: true,
    address: true,
    square: true,
    type_owner: true,
    owner: true,
    status: true,
    substatus: true,
    cost: true,
  });

  const handleOpen = () => {
    onClick && onClick();
    if (isExcelUpload) {
      dispatch(setIsExcelUploadVisible(true));
      return;
    }
    setIsOpen(true);
  };

  const handleClose = () => {
    onClick && onClick();

    setIsOpen(false);
  };

  const handleSelectField = (field: string) =>
    setColumns(prev => ({ ...prev, [field]: !prev[field] }));

  useClickOutside(ref, handleClose);

  const columnNames = {
    block: 'Направление',
    category: 'Группа',
    cadaster_number: 'Кадастровый номер',
    address: 'Адрес',
    square: 'Площадь',
    type_owner: 'Вид права',
    owner: 'Правообладатель',
    status: 'Статус',
    substatus: 'Подстатус',
    cost: 'Стоимость',
  };

  const getArrInString = arr => arr?.map(el => el?.toString()) || [];

  const handleGetExcel = async () => {
    setIsLoading(true);
    const params: ExcelExportType = {
      columns,
      filter: {
        block_status: getArrInString(polygonsFilter?.blockStatuses),
        category: getArrInString(polygonsFilter?.categories),
        type_owner: getArrInString(polygonsFilter?.typeOwners),
        owner: getArrInString(polygonsFilter?.owners),
        status: getArrInString(polygonsFilter?.statuses),
        substatus: getArrInString(polygonsFilter?.subStatuses),
        cadaster_number: dashboardPolygonsFilter?.cadaster_number || '',
        ...(checkedPolygons && {
          id: checkedPolygons.map(polygon => polygon?.id || 0) || [],
        }),
        ...(dashboardPolygonsFilter?.price?.from && {
          price_from: dashboardPolygonsFilter?.price?.from,
        }),
        ...(dashboardPolygonsFilter?.price?.to && {
          price_to: dashboardPolygonsFilter?.price?.to,
        }),
        ...(dashboardPolygonsFilter?.square?.from && {
          square_from: dashboardPolygonsFilter?.square?.from,
        }),
        ...(dashboardPolygonsFilter?.square?.from && {
          square_to: dashboardPolygonsFilter?.square?.to,
        }),
        address: dashboardPolygonsFilter?.address || '',
      },
    };

    try {
      const res = await ExcelService.exportExcel(params);

      if (res?.data?.error && res?.data?.success) {
        notice({
          title: 'Ошибка!',
          message: 'Импорт или экспорт уже выполняется ',
          type: 'danger',
        });
      }

      if (!res?.data?.file) return;

      download(`${BASE_EXCEL_URL}/${res.data.file}`, 'excel.xlsx');
    } catch (e) {
      console.log(e.message);
    } finally {
      handleClose();
      setIsLoading(false);
    }
  };

  return (
    <div className={cn('relative z-10', classNames)}>
      <div
        className={cn(
          {
            'shadow-greens-100 bg-green-200': isOpen,
            'bg-green-400': !isOpen,
            'w-8 h-8 t-lg:w-11 t-lg:h-11 center rounded': variant === 'icon',
            'w-full h-10 flex items-center px-2.5 text-sm py-3 border-b border-green-500':
              variant === 'text',
          },
          'hover:bg-green-200 cursor-pointer transition-full hover:shadow-greens-100',
        )}
        onClick={handleOpen}
      >
        {variant === 'text' ? (
          <AppToolTip classNames="font-gothampro-500 text-white" text="Экспорт">
            Экспорт
          </AppToolTip>
        ) : null}
        <ExcelExportIcon
          className={cn({ 'ml-auto': variant === 'text' }, 'fill-white w-4 h-4 t-lg:w-5 t-lg:h-5')}
        />
      </div>

      {isOpen ? (
        <div
          className={cn(
            {
              ' top-9 t-lg:top-12': variant === 'icon',
              'top-10': variant === 'text',
            },
            'w-56 absolute right-0 rounded bg-white dark:bg-blue-800 flex flex-col',
          )}
          ref={ref}
        >
          {isLoading ? (
            <div className="w-full h-full flex items-center justify-center py-10">
              <Loader classNames="w-20 h-20" />
            </div>
          ) : (
            <>
              <div className="p-4 border-b border-grey-1100 flex items-center justify-between">
                <div className="font-gothampro-500 dark:text-white text-xs">
                  Выберите поля для вывода
                </div>
                <CloseIcon onClick={handleClose} className="w-3 h-3 fill-grey-500 cursor-pointer" />
              </div>

              {Object.keys(columns).map(key => {
                const isSelected = columns[key];

                return (
                  <div
                    key={`excel-export-field-${key}`}
                    className={cn(
                      {
                        'hover:bg-grey-1300 dark:hover:bg-blue-700': !isSelected,
                      },
                      'flex py-2.5 px-4 w-full border-b border-grey-1100 cursor-pointer justify-between',
                    )}
                    onClick={() => handleSelectField(key)}
                  >
                    <div className="dark:text-white text-xs font-gothampro-400">
                      {columnNames[key]}
                    </div>
                    <div className="w-8 h-4 rounded-full relative bg-grey-1100">
                      <div
                        className={cn(
                          {
                            'left-4 bg-orange-100': isSelected,
                            'left-0 bg-grey-1500': !isSelected,
                          },
                          'absolute top-0 w-4 h-4 rounded-full transition-full transition-full',
                        )}
                      />
                    </div>
                  </div>
                );
              })}

              <div className="py-2.5 px-4 w-full">
                <Button variant="blue" size="small" classNames="w-full" onClick={handleGetExcel}>
                  Выгрузить
                </Button>
              </div>
            </>
          )}
        </div>
      ) : null}
    </div>
  );
};
