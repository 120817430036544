import { ChangeEvent, FC, useMemo, useState } from 'react';
import { CheckBox } from 'src/components/CheckBox';
import { setDashboardPolygonsFilter, setPolygonsFilter } from 'src/store/filters';
import { Input } from 'src/components/Input';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { useWindowDimensions } from 'src/hooks/useWindowDimension';
import { debounce } from 'src/utils';
import { DashboardPolygonsFilterType, PolygonsFilterType } from 'src/config/types';

import cn from 'classnames';

interface DashboardTableHeadFilterProps {
  filter: keyof PolygonsFilterType | keyof DashboardPolygonsFilterType;
  isDashboard?: boolean;
}

export const DashboardTableHeadFilter: FC<DashboardTableHeadFilterProps> = ({
  filter,
  isDashboard,
}) => {
  const { polygonsFilter, dashboardPolygonsFilter } = useAppSelector(state => state.filters);
  const categories = useAppSelector(state => state.categories);

  const { width } = useWindowDimensions();
  const dispatch = useAppDispatch();

  const [search, setSearch] = useState<string>('');

  const handleSelectFilter = (
    id: number,
    filterName: keyof PolygonsFilterType | keyof DashboardPolygonsFilterType,
  ) => {
    const newTempFilterByName = polygonsFilter?.[filterName]?.includes(id)
      ? polygonsFilter?.[filterName]?.filter(el => el !== id)
      : [...(polygonsFilter?.[filterName] || []), id];

    dispatch(setPolygonsFilter({ ...polygonsFilter, [filterName]: newTempFilterByName }));
  };

  const handleSearch = debounce((e: ChangeEvent<HTMLInputElement>) => {
    const str = e.target.value;

    if (!isDashboard) {
      setSearch(str);
    }

    if (isDashboard) {
      const newTempFilterByName = { ...dashboardPolygonsFilter, [filter]: str };

      dispatch(setDashboardPolygonsFilter(newTempFilterByName));
    }
  }, 300);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    key: 'price' | 'square',
    type: 'from' | 'to',
  ) => {
    const newTempFilterByName = {
      ...dashboardPolygonsFilter,
      [key]: { ...dashboardPolygonsFilter[key], [type]: +e.target.value },
    };

    dispatch(setDashboardPolygonsFilter(newTempFilterByName));
  };

  const filteredList = useMemo(() => {
    if (!search) {
      return categories?.[filter] || [];
    }

    return (categories?.[filter] || []).filter(item =>
      item.name.toLowerCase().includes(search.toLowerCase()),
    );
  }, [search, categories]);

  const data = { from: 'от', to: 'до' };

  return (
    <div
      className={cn(
        { '-right-0': filter === 'price', 'left-0': filter !== 'price' },
        'absolute top-full max-h-[14rem] t-lg:max-h-[20rem] min-w-[12rem] t-lg:min-w-[16rem] w-full overflow-auto ' +
          'bg-white dark:bg-blue-800 shadow-blacks-900 z-2000',
      )}
    >
      <div className="w-full px-2 py-1.5 t-lg:px-4 t-lg:py-2.5">
        {filter !== 'price' && filter !== 'square' ? (
          <Input
            size={width >= 1024 ? 'small' : 'extraSmall'}
            onChange={handleSearch}
            defaultValue={dashboardPolygonsFilter?.[filter] || ''}
          />
        ) : null}

        {filter === 'price' || filter === 'square' ? (
          <>
            {(['from', 'to'] as ('from' | 'to')[]).map(type => (
              <div
                key={`filter-${filter}-${type}`}
                className="flex justify-between items-center gap-2 mb-1"
              >
                <div className="text-ultra-xs t-lg:text-xs font-gothampro-400 dark:text-white">
                  {data[type]}
                </div>
                <Input
                  type="number"
                  classNames="max-w-[12rem]"
                  onChange={debounce(e => handleChange(e, filter, type))}
                  defaultValue={dashboardPolygonsFilter?.[filter]?.[type]?.toString() || ''}
                />
              </div>
            ))}
          </>
        ) : null}
      </div>

      {filteredList?.map(item => {
        const isActive = !!polygonsFilter?.[filter]?.includes(item.id);

        return (
          <div
            key={`dashboard-table-filter-${filter}-${item.id}`}
            className={cn(
              {
                'bg-white-200 dark:bg-blue-200': isActive,
              },
              'w-full px-2 py-1.5 t-lg:px-4 t-lg:py-2.5 hover:bg-white-200 flex items-center ' +
                'justify-between transition-full gap-2 hover:dark:bg-blue-200 ',
            )}
            onClick={() => handleSelectFilter(item.id, filter)}
          >
            <div className="text-ultra-xs t-lg:text-xs font-gothampro-400 dark:text-white">
              {item.name}
            </div>
            <CheckBox isActive={isActive} />
          </div>
        );
      })}
    </div>
  );
};
