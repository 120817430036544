import { request } from 'src/services/Api/Fetch';

const getAllFiles = () => {
  return request(`/api/upload_file/`);
};

const saveFile = (params: FormData) => {
  return request(`/api/upload_file/`, params, 'POST');

  // return Api.post(`/api/upload_file/`, params, {
  //   headers: { 'Content-Type': 'multipart/form-data' },
  // });
};

const deleteFileById = (params: { id: number }) => {
  return request(`/api/upload_file/${params.id}`, {}, 'DELETE');
};

const FilesService = {
  getAllFiles,
  saveFile,
  deleteFileById,
};

export default FilesService;
