import { FC } from 'react';
import { Loader } from 'src/components/Loader';
import { ObjectInfoItemList } from 'src/components/ObjectInfo/ObjectInfoItemList';
import { useAppSelector } from 'src/hooks/useRedux';

export const ObjectInfo: FC = () => {
  const { selectedPolygon, isLoadingSelectedPolygon } = useAppSelector(state => state.polygons);
  const { reestrList } = useAppSelector(state => state.reestr);

  const searchedInfo = selectedPolygon?.reestrInfo;

  return (
    <div className="w-full relative">
      {isLoadingSelectedPolygon ? (
        <div className="w-full h-full flex items-center justify-center py-10">
          <Loader classNames="w-20 h-20" />
        </div>
      ) : null}

      {!isLoadingSelectedPolygon && !searchedInfo && !reestrList.length ? (
        <div className="p-10 w-full text-center font-gothampro-400 dark:text-white">Не найдено</div>
      ) : null}

      {!isLoadingSelectedPolygon && searchedInfo?.feature?.attrs ? (
        <>
          <ObjectInfoItemList attrs={searchedInfo?.feature?.attrs} />
        </>
      ) : null}
    </div>
  );
};
