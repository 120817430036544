import { FC, useState } from 'react';
import { Search } from 'src/components/Search';
import { ZonePolygonsList } from 'src/components/Zones/ZonePolygonsList';

export const Zones: FC = () => {
  const [search, setSearch] = useState('');

  const handleChangeSearch = search => {
    setSearch(search);
  };

  return (
    <div className="w-full dark:bg-blue-500 min-h-full">
      <div className="px-4 py-2.5 border-b border-grey-1100">
        <Search
          small
          onChange={handleChangeSearch}
          placeholder="Найти объекты..."
          classNames="w-full"
        />
      </div>

      <ZonePolygonsList search={search} />
    </div>
  );
};
