import React, { FC, useEffect, useState } from 'react';
import { LeafletMap } from 'src/components/Map/LeafletMap';
import { NewPolygonForm } from 'src/components/Forms/NewPolygonForm';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import {
  fetchDrawPolygons,
  fetchFavoritePolygons,
  fetchInfoData,
  fetchReestrPolygons,
  fetchSelectedPolygon,
} from 'src/store/polygons/actions';
import { mapDefsObserver, pieChartObserver } from 'src/utils/observers';
import { MapButtons } from 'src/components/MapButtons';
import { MapDropBlocks } from 'src/components/MapDropBlocks';
import { Screen } from 'src/components/Screen/Index';
import { useAuth } from 'src/hooks/useAuth';
import { isEmpty } from 'src/utils';
import { mapData, polygonFieldsForMap } from 'src/config/data';
import { MapContainer } from 'react-leaflet';
import { LatLngExpression } from 'leaflet';

import { ReactComponent as ScreenIcon } from 'src/assets/icons/screen.svg';
import cn from 'classnames';

export const MapPage: FC = () => {
  const { isMobileButtonsOpened } = useAppSelector(state => state.map);
  const { statuses, isLoadingAllInfo } = useAppSelector(state => state.categories);
  const { selectedLayers } = useAppSelector(state => state.map);
  const { isClustersActive } = useAppSelector(state => state.common);

  const {
    reestrPolygons,
    isLoadingReestrPolygons,
    drawPolygons,
    isLoadingDrawPolygons,
    infoData,
    isInfoDataLoading,
    favoritePolygons,
    isLoadingFavoritePolygons,
  } = useAppSelector(state => state.polygons);

  const dispatch = useAppDispatch();
  const { user } = useAuth();

  const [lastClicked, setLastClicked] = useState<number>(3);
  const [scrIcon, setScrIcon] = useState<boolean>(true);
  const [screenPage, setScreenPage] = useState<boolean>(false);

  useEffect(() => {
    if (!user) return;

    initPolygons();

    if (!drawPolygons.length && !isLoadingDrawPolygons) {
      dispatch(fetchDrawPolygons());
    }

    if (isEmpty(infoData) && !isInfoDataLoading) {
      dispatch(fetchInfoData([]));
    }

    if (
      !favoritePolygons?.length &&
      !isLoadingFavoritePolygons &&
      (user?.isLeader || user?.isManager)
    ) {
      dispatch(fetchFavoritePolygons());
    }
  }, [user]);

  const getInitPolygons = async coords => {
    const posToGet = {
      xmin: coords[1] - 0.02,
      ymin: coords[0] - 0.04,
      xmax: coords[1] + 0.02,
      ymax: coords[0] + 0.04,
    };

    if (!reestrPolygons.length && !isLoadingReestrPolygons) {
      await dispatch(fetchReestrPolygons({ fields: polygonFieldsForMap, coords: posToGet }));
      await dispatch(fetchReestrPolygons({}));
    }
  };

  const initPolygons = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const cn = urlSearchParams.get('cn');

    if (cn) {
      await dispatch(fetchReestrPolygons({ fields: polygonFieldsForMap }));
      return;
    }
    navigator.geolocation.getCurrentPosition(
      async position => {
        const coords: LatLngExpression = [position.coords.latitude, position.coords.longitude];

        await getInitPolygons(coords);
      },
      async () => {
        const coords = mapData.center || [];

        await getInitPolygons(coords);
      },
    );
  };

  useEffect(() => {
    if (isLoadingAllInfo || !user) return;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const cn = urlSearchParams.get('cn');

    if (!cn) return;

    dispatch(fetchSelectedPolygon({ cn, selectedLayers }));
  }, [isLoadingAllInfo, user]);

  useEffect(() => {
    mapDefsObserver(statuses);
  }, [statuses, reestrPolygons]);

  useEffect(() => {
    pieChartObserver();
  }, [isClustersActive]);

  return (
    <div className="w-full h-full t-lg:pb-4">
      <div className="w-full h-full relative t-sm:rounded-t-md overflow-hidden">
        <div className="w-full h-full center" id="map-svg">
          <div id={'screen'} style={{ width: '100%', height: '100%' }}>
            {screenPage ? <Screen setScrIcon={setScrIcon} /> : null}

            <MapContainer className="my-map my-edit-control" {...mapData}>
              <LeafletMap />
            </MapContainer>
          </div>

          {(window.innerWidth < 1024 && isMobileButtonsOpened) ||
          (window.innerWidth > 1024 && scrIcon) ? (
            <ScreenIcon
              className={'pointer'}
              id={'screenIcon'}
              onClick={() => setScreenPage(!screenPage)}
            />
          ) : (
            <></>
          )}
        </div>

        <MapButtons />

        <MapDropBlocks lastClicked={lastClicked} setLastClicked={setLastClicked} />

        <NewPolygonForm
          onClick={() => setLastClicked(3)}
          classNames={cn({ 'z-2100': lastClicked !== 3, 'z-2200': lastClicked === 3 })}
        />
      </div>
    </div>
  );
};
