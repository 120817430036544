import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import { PolygonType, SelectItemType, SelectType } from 'src/config/types';
import { FieldErrors, UseFormClearErrors, UseFormSetValue } from 'react-hook-form';
import { useClickOutside } from 'src/hooks/useClickOutside';
import { AppToolTip } from 'src/components/AppToolTip';

import { ReactComponent as ArrowIcon } from 'src/assets/icons/kit/arrow.svg';
import cn from 'classnames';

interface SelectProps {
  list: SelectItemType[];
  value: SelectItemType | null;
  onChange?: (arg: SelectItemType) => void;
  classNames?: string;
  data: SelectType;
  errors?: FieldErrors;
  setValue?: UseFormSetValue<PolygonType>;
  register?: (name: string, RegisterOptions?) => { onChange; onBlur; name; ref };
  clearErrors?: UseFormClearErrors<PolygonType>;
  disabled?: boolean;
  variant?: 'top' | 'bottom';
  noDark?: boolean;
  small?: boolean;
  isBig?: boolean;
  withSearch?: boolean;
  noIcon?: boolean;
}

export const Select: FC<SelectProps> = ({
  value,
  list,
  noDark,
  classNames,
  setValue,
  data,
  errors,
  register,
  clearErrors,
  disabled,
  onChange,
  small,
  variant = 'bottom',
  isBig,
  withSearch,
  noIcon,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.value = value?.label || '';
    }
  }, [value]);

  const isSmall = Boolean(small);
  const isTop = variant === 'top';
  const isError = !!errors?.[data.name ?? ''];
  const error = errors?.[data.name ?? '']?.message || '';

  useClickOutside(ref, () => {
    setSearch('');
    setIsOpen(false);
  });

  const handleOpen = () => {
    setSearch('');

    if (disabled) return;

    if (data?.name && clearErrors) clearErrors(data.name as keyof PolygonType);

    setIsOpen(prev => !prev);
  };

  const handleSelect = (item: SelectItemType) => {
    onChange && onChange(item);
    setValue && data.name && setValue(data.name as keyof PolygonType, item.value);
    if (inputRef?.current) {
      inputRef.current.value = item.label;
    }
  };

  const filteredList = useMemo(() => {
    if (!search) return list;

    return list.filter(el => el?.label?.toLowerCase().includes(search?.toLowerCase()));
  }, [search, list]);

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e?.target?.value);
  };

  const renderList = (isTop: boolean) => (
    <div
      className={cn(
        {
          'border-b rounded-b top-full': !isTop,
          'border-t rounded-t': isTop,
          'border-x border-grey-700 dark:border-grey-1000': !isSmall,
        },
        'absolute z-100 max-h-[14rem] left-0 w-full shadow-blacks-600 dark:shadow-blacks-500 ',
      )}
      style={{ bottom: `${ref?.current?.offsetHeight}px` }}
    >
      <div
        className={
          'w-full max-h-[14rem] overflow-auto scrollbar-hide flex flex-col ' +
          'bg-white dark:bg-grey-1000'
        }
      >
        {filteredList.map((el, i) => (
          <div
            className={cn(
              {
                'bg-grey-900 dark:bg-grey-200': value?.value === el.value,
                'px-3 py-2 text-xs': !isSmall,
                'px-2 py-1.5 text-extra-xs': isSmall,
              },
              'font-gothampro-400 cursor-pointer flex items-center dark:text-white',
              'hover:bg-grey-900 dark:hover:bg-grey-200',
              el.classNames,
            )}
            key={`select-item-${data.placeholder}-${i}`}
            onClick={() => handleSelect(el)}
            style={{ ...el.styles }}
          >
            {el.icon && !noIcon ? el.icon : null}
            <div style={{ width: el?.icon ? `calc(100% - 24px)` : '100%' }}>
              <AppToolTip checkWidth text={el.label} classNames="truncate w-full" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const width = disabled ? 20 : 48;

  return (
    <div
      className={cn(classNames, 'w-full cursor-pointer relative')}
      ref={ref}
      onClick={handleOpen}
    >
      {isOpen && isTop ? renderList(true) : null}

      <div
        className={cn(
          {
            rounded: !isOpen,
            'rounded-t': isOpen && !isTop,
            'rounded-b': isOpen && isTop,
            'border border-grey-700 dark:border-grey-1000': !isSmall && !isError,
            'h-7 px-3 text-xs': !isSmall && !isBig,
            'px-2 py-2 text-extra-xs': isSmall,
            'h-11 px-3 text-xs': isBig,
            'border border-pink-200': isError,
          },
          'flex items-center bg-white dark:bg-grey-1000 dark:text-white font-gothampro-400 w-full',
          value?.classNames,
        )}
        style={{ ...value?.styles }}
      >
        {value?.icon && !noIcon ? value.icon : null}

        {withSearch && !disabled ? (
          <input
            onClick={() => {
              if (!inputRef.current) return;
              inputRef.current.select();
            }}
            ref={inputRef}
            defaultValue={value?.label}
            onChange={handleChangeSearch}
            placeholder={data.placeholder}
            disabled={disabled}
            className="placeholder:text-white bg-transparent w-full whitespace-nowrap truncate"
          />
        ) : (
          <AppToolTip
            checkWidth
            width={!value?.icon ? '100%' : `calc(100% - ${width}px)`}
            text={value?.label || data?.placeholder || ''}
            classNames="w-full whitespace-nowrap truncate"
          />
        )}

        {!disabled ? (
          <ArrowIcon
            className={cn(
              { 'rotate-180': isOpen, 'dark:stroke-white': !Boolean(noDark) },
              'w-4 h-4 min-w-[1rem] transition-full ml-auto',
            )}
          />
        ) : null}

        <input
          {...(register && {
            ...register(data?.name || '', {
              required: data.required,
            }),
          })}
          disabled={disabled}
          className="hidden"
        />
      </div>
      {isError ? (
        <div className="font-gothampro-400 absolute -bottom-3.5 text-pink-200 text-extra-xs">
          {(error as string) || 'Поле не может быть пустым!'}
        </div>
      ) : null}

      {isOpen && !isTop ? renderList(false) : null}
    </div>
  );
};
