import { ChangeEvent, Dispatch, FC, Fragment, SetStateAction } from 'react';
import { PolygonImageType } from 'src/config/types';
import { getSrc, notice } from 'src/utils';

import { ReactComponent as PhotoIcon } from 'src/assets/icons/kit/photo.svg';
import { ReactComponent as TrashIcon } from 'src/assets/icons/kit/trash.svg';

interface DashboardSingleAddPhotoProps {
  polygonId: number;
  tempImages: PolygonImageType[];
  setTempImages: Dispatch<SetStateAction<PolygonImageType[]>>;
}

export const DashboardSingleAddPhoto: FC<DashboardSingleAddPhotoProps> = ({
  polygonId,
  tempImages,
  setTempImages,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.files?.length) {
      return;
    }
    saveFile(e.target.files[0]);
  };

  const saveFile = (image: File) => {
    const maxSize = 25 * 1024 * 1024;
    if (image.size > maxSize) {
      notice({
        title: 'Ошибка!',
        message: 'максимальный размер - 25мб',
        type: 'danger',
      });
      return;
    }

    const newImage: PolygonImageType = {
      polygon: polygonId,
      flag: 'add',
      image,
    };

    setTempImages(prev => [...prev, newImage]);
  };

  const handleRemove = (i: number) => {
    if (!tempImages?.[i]?.id) {
      setTempImages(prev => prev.filter((_, index) => index !== i));
      return;
    }

    setTempImages(prev => {
      const temp = [...prev];

      return temp.map((image, index) => {
        if (index === i) {
          return { ...image, flag: 'delete' };
        }
        return image;
      });
    });
  };

  return (
    <div className="w-full grid grid-cols-2 gap-5">
      {tempImages?.map((el, i) =>
        el.flag === 'delete' ? (
          <Fragment key={`polygon-image-${i}`} />
        ) : (
          <div
            key={`polygon-image-${i}`}
            className="w-full h-32 m-md:h-52 rounded-lg overflow-hidden relative"
          >
            <div
              className={
                'absolute top-3 right-3 w-8 h-8 bg-white rounded-full cursor-pointer center group'
              }
              onClick={() => handleRemove(i)}
            >
              <TrashIcon className="w-4 h-4 fill-grey-2800 group-hover:fill-orange-100" />
            </div>
            <img src={getSrc(el.image)} alt="" className="w-full h-full object-cover" />
          </div>
        ),
      )}

      <label
        className={
          'w-full h-32 m-md:h-52 bg-white hover:bg-white-200 border border-grey-500 ' +
          'hover:border-blue-900 hover:shadow-greys-400 center rounded-lg transition-full ' +
          'overflow-hidden cursor-pointer dark:bg-blue-1200 dark:border-none dark:shadow'
        }
      >
        <PhotoIcon className="fill-white-300 dark:fill-blue-900 w-14 h-12 m-md:w-24 m-md:h-20" />
        <input
          type="file"
          accept="image/*"
          className="hidden"
          onChange={handleChange}
          multiple={false}
        />
      </label>
    </div>
  );
};
