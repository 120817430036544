import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchReestrPolygonById,
  fetchInfoData,
  fetchFavoritePolygons,
  fetchReestrPolygons,
  fetchDrawPolygons,
  fetchSelectedPolygon,
  fetchDrawPolygonById,
} from 'src/store/polygons/actions';
import { InfoDataType, PolygonType } from 'src/config/types';
import { PolygonsState } from 'src/store/polygons/types';

const initialState: PolygonsState = {
  reestrPolygons: [],
  isLoadingReestrPolygons: false,
  filteredPolygonsCount: 0,

  drawPolygons: [],
  isLoadingDrawPolygons: false,

  favoritePolygons: [],
  isLoadingFavoritePolygons: false,

  selectedPolygon: null,
  isLoadingSelectedPolygon: false,

  resetPolygon: false,

  infoData: {} as InfoDataType,
  isInfoDataLoading: false,

  checkedPolygons: [],

  notSavedDrawFavoritePolygonId: null,

  error: '',
};

const polygonsSlice = createSlice({
  name: 'polygons',
  initialState,
  reducers: {
    setReestrPolygons: (state, action: PayloadAction<PolygonType[]>) => {
      state.reestrPolygons = action.payload;
    },
    setCheckedPolygons: (state, action: PayloadAction<PolygonType[]>) => {
      state.checkedPolygons = action.payload;
    },
    setDrawPolygons: (state, action: PayloadAction<PolygonType[]>) => {
      state.drawPolygons = action.payload;
    },
    setNotSavedDrawFavoritePolygonId: (state, action: PayloadAction<number | null>) => {
      state.notSavedDrawFavoritePolygonId = action.payload;
    },
    setFavoritePolygons: (state, action: PayloadAction<PolygonType[]>) => {
      state.favoritePolygons = action.payload;
    },
    setSelectedPolygon: (
      state,
      action: PayloadAction<{ polygon: PolygonType | null; reset?: boolean }>,
    ) => {
      const { polygon, reset } = action.payload;
      state.selectedPolygon = polygon;
      if (!polygon || polygon?.cadaster_number || polygon?.favoritePolygonId) {
        state.notSavedDrawFavoritePolygonId = null;
      }

      if (reset) {
        state.resetPolygon = reset;
      }
    },
    setFilteredPolygonsCount: (state, action: PayloadAction<number>) => {
      state.filteredPolygonsCount = action.payload;
    },
    setResetPolygon: (state, action: PayloadAction<boolean>) => {
      state.resetPolygon = action.payload;
    },
    setSelectedPolygonGeometry: (
      state,
      action: PayloadAction<{
        geometry: string;
        square: number;
      }>,
    ) => {
      const { geometry, square } = action.payload;
      if (state.selectedPolygon) {
        state.selectedPolygon = { ...state.selectedPolygon, geometry, square };
      }
    },
    resetPolygons: state => {
      state.reestrPolygons = [];
      state.isLoadingReestrPolygons = false;
      state.filteredPolygonsCount = 0;

      state.drawPolygons = [];
      state.isLoadingDrawPolygons = false;

      state.favoritePolygons = [];
      state.isLoadingFavoritePolygons = false;
    },
  },
  extraReducers: {
    [fetchReestrPolygons.pending.type]: state => {
      state.isLoadingReestrPolygons = true;
      state.reestrPolygons;
      state.filteredPolygonsCount;
    },
    [fetchReestrPolygons.fulfilled.type]: (state, action: PayloadAction<PolygonType[]>) => {
      state.isLoadingReestrPolygons = false;
      state.reestrPolygons = action.payload;
      state.filteredPolygonsCount = action?.payload?.length || 0;
      state.error = '';
    },
    [fetchReestrPolygons.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoadingReestrPolygons = false;
      state.filteredPolygonsCount = 0;
      state.error = action.payload;
    },

    [fetchDrawPolygons.pending.type]: state => {
      state.isLoadingDrawPolygons = true;
      state.drawPolygons = [];
    },
    [fetchDrawPolygons.fulfilled.type]: (state, action: PayloadAction<PolygonType[]>) => {
      state.isLoadingDrawPolygons = false;
      state.drawPolygons = action.payload;
      state.error = '';
    },
    [fetchDrawPolygons.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoadingDrawPolygons = false;
      state.error = action.payload;
    },

    [fetchFavoritePolygons.pending.type]: state => {
      state.isLoadingFavoritePolygons = true;
      state.favoritePolygons = [];
    },
    [fetchFavoritePolygons.fulfilled.type]: (state, action: PayloadAction<PolygonType[]>) => {
      state.isLoadingFavoritePolygons = false;
      state.error = '';
      state.favoritePolygons = action.payload;
    },
    [fetchFavoritePolygons.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoadingFavoritePolygons = false;
      state.error = action.payload;
    },

    [fetchReestrPolygonById.pending.type]: state => {
      state.isLoadingSelectedPolygon = true;
      state.selectedPolygon = null;
      state.resetPolygon = true;
    },
    [fetchReestrPolygonById.fulfilled.type]: (state, action: PayloadAction<PolygonType>) => {
      state.isLoadingSelectedPolygon = false;
      state.error = '';
      state.selectedPolygon = {
        geometry: action.payload.geometry,
        id: action.payload.id,
        ...action.payload.properties,
      };
    },
    [fetchReestrPolygonById.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoadingSelectedPolygon = false;
      state.error = action.payload;
    },

    [fetchDrawPolygonById.pending.type]: state => {
      state.isLoadingSelectedPolygon = true;
      state.selectedPolygon = null;
      state.resetPolygon = true;
    },
    [fetchDrawPolygonById.fulfilled.type]: (state, action: PayloadAction<PolygonType>) => {
      state.isLoadingSelectedPolygon = false;
      state.error = '';
      state.selectedPolygon = action.payload;
    },
    [fetchDrawPolygonById.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoadingSelectedPolygon = false;
      state.error = action.payload;
    },

    [fetchSelectedPolygon.pending.type]: state => {
      state.isLoadingSelectedPolygon = true;
      state.selectedPolygon = null;
    },
    [fetchSelectedPolygon.fulfilled.type]: (state, action: PayloadAction<PolygonType>) => {
      state.isLoadingSelectedPolygon = false;
      state.selectedPolygon = action.payload;
      state.error = '';
    },
    [fetchSelectedPolygon.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoadingSelectedPolygon = false;
      state.error = action.payload;
    },

    [fetchInfoData.pending.type]: state => {
      state.isInfoDataLoading = true;
      state.infoData = {} as InfoDataType;
    },
    [fetchInfoData.fulfilled.type]: (state, action: PayloadAction<InfoDataType>) => {
      state.isInfoDataLoading = false;
      state.error = '';
      state.infoData = action.payload;
    },
    [fetchInfoData.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isInfoDataLoading = false;
      state.error = action.payload;
      state.infoData = {} as InfoDataType;
    },
  },
});

export const {
  setCheckedPolygons,
  setReestrPolygons,
  setNotSavedDrawFavoritePolygonId,
  setDrawPolygons,
  setFavoritePolygons,
  setSelectedPolygon,
  setSelectedPolygonGeometry,
  setFilteredPolygonsCount,
  setResetPolygon,
  resetPolygons,
} = polygonsSlice.actions;

const reducer = polygonsSlice.reducer;
export default reducer;
