import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Button } from 'src/components/Button';
import { DashboardSingleCarousel } from 'src/components/DashboardSIngle/DashboardSingleCarousel';
import { DashboardSingleBanner } from 'src/components/DashboardSIngle/DashboardSingleBanner';
import { DashboardSingleStat } from 'src/components/DashboardSIngle/DashboardSingleStat';
import { DashboardSingleAddPhoto } from 'src/components/DashboardSIngle/DashboardSingleAddPhoto';
import { DashboardStatEdit } from 'src/components/DashboardSIngle/DashboardStatEdit';
import { useForm } from 'react-hook-form';
import { Comment } from 'src/components/Comment';
import { stateToHTML } from 'draft-js-export-html';
import { DashboardSingleFiles } from 'src/components/DashboardSIngle/DashboardSingleFiles';
import { DashBoardSingleMap } from 'src/components/DashboardSIngle/DashBoardSingleMap';
import { setLoaderFull } from 'src/store/common';
import { addDefs, geoJSONToCoords, selectPolygonSelects } from 'src/utils/helpers';
import { useAuth } from 'src/hooks/useAuth';
import { fetchReestrPolygonById } from 'src/store/polygons/actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { initialPolygonSelects } from 'src/config/data';
import { EditorState } from 'draft-js';
import {
  PolygonFileType,
  PolygonImageType,
  PolygonSelectsType,
  PolygonType,
} from 'src/config/types';
import PolygonsService from 'src/services/PolygonsService';
import ImagesService from 'src/services/ImagesService';
import FilesService from 'src/services/FilesService';
import parse from 'html-react-parser';

import { ReactComponent as ArrowIcon } from 'src/assets/icons/kit/arrow.svg';
import cn from 'classnames';

export const DashboardPolygonPage: FC = () => {
  const categories = useAppSelector(state => state.categories);
  const { selectedPolygon, isLoadingSelectedPolygon } = useAppSelector(state => state.polygons);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    resetField,
    register,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<PolygonType>();

  const selectData = {
    errors: errors,
    register: register,
    clearErrors: clearErrors,
    setValue: setValue,
  };

  useEffect(() => {
    addDefs(categories.statuses);

    const map = document.querySelector('.my-map');

    if (!map) return;

    const observer = new MutationObserver(() => {
      addDefs(categories.statuses);
    });

    observer.observe(map, {
      subtree: true,
      childList: true,
    });
  }, [categories.statuses, selectedPolygon]);

  const { polygonId } = useParams();
  const { user } = useAuth();

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [polygonSelects, setPolygonSelects] = useState<PolygonSelectsType>(initialPolygonSelects);
  const [comment, setComment] = useState<EditorState>(EditorState.createEmpty());
  const [tempImages, setTempImages] = useState<PolygonImageType[]>([]);
  const [tempFiles, setTempFiles] = useState<PolygonFileType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allFiles, setAllFiles] = useState<{
    images: PolygonImageType[];
    files: PolygonFileType[];
  }>({ images: [], files: [] });

  const startEdit = () => setIsEdit(true);
  const endEdit = () => setIsEdit(false);

  useEffect(() => {
    if (selectedPolygon) {
      selectPolygonSelects(selectedPolygon, categories, setPolygonSelects, setValue);
      setValue('exposition_cost', selectedPolygon.exposition_cost);
      setValue('predict_cost', selectedPolygon.predict_cost);
    }
  }, [selectedPolygon]);

  const init = async (polygonId: string) => {
    await dispatch(fetchReestrPolygonById(polygonId));
    try {
      const allFiles = await PolygonsService.getReestrPolygonAllFilesById({ id: +polygonId });

      if (!allFiles?.data) return;

      const data = allFiles.data;

      setAllFiles(data);

      if (data?.images?.length) {
        setTempImages(allFiles.data.images);
      }

      if (data?.files?.length) {
        setTempFiles(allFiles.data.files);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    (async () => {
      if (polygonId) {
        await init(polygonId);
      }
    })();
  }, []);

  useEffect(() => {
    dispatch(setLoaderFull(isLoadingSelectedPolygon || isLoading));
  }, [isLoadingSelectedPolygon, isLoading]);

  if (!selectedPolygon) {
    return (
      <div className="w-full h-full flex flex-col items-center justify-center">
        <div className="dark:text-white text-2xl t-lg:text-4xl mb-5">Полигон не найден</div>
        <Button variant="blue" size="big" classNames="w-40" onClick={() => navigate('/dashboard')}>
          К списку
        </Button>
      </div>
    );
  }

  const isCommentEmpty = selectedPolygon?.comments?.replaceAll('<br>', '') === '<p></p>';

  const blockTitle = (label: string) => (
    <div className="dark:text-white pl-5 mb-2.5 font-gothampro-500">{label}</div>
  );

  const blockClassName = 'rounded-lg w-full dark:bg-blue-800 bg-white p-2.5 t-lg:p-5';

  const handleCancelEdit = async e => {
    e?.preventDefault();
    endEdit();
    selectPolygonSelects(selectedPolygon, categories, setPolygonSelects, setValue);
    setValue('exposition_cost', selectedPolygon.exposition_cost);
    setValue('predict_cost', selectedPolygon.predict_cost);
    setTempImages(allFiles.images);
    setTempFiles(allFiles.files);
  };

  const onSubmit = async data => {
    if (isLoading) return;

    setIsLoading(true);

    const isMulti = selectedPolygon?.geometry.includes('MULTIPOLYGON');
    const coordinates = geoJSONToCoords(selectedPolygon?.geometry || '');

    const params: PolygonType = {
      ...selectedPolygon,
      ...(data.substatus && { substatus: +data.substatus }),
      ...(data.owner && { owner: +data.owner }),
      ...(data.type_owner && { type_owner: +data.type_owner }),
      ...(data.block_status && { block_status: +data.block_status }),
      ...(data.category && { category: +data.category }),
      status: +data.status,
      comments: stateToHTML(comment.getCurrentContent()),
      ...(data.exposition_cost && { exposition_cost: +data.exposition_cost }),
      ...(data.predict_cost && { predict_cost: +data.predict_cost }),
      geometry: {
        type: isMulti ? 'MultiPolygon' : 'Polygon',
        coordinates: isMulti ? [coordinates] : coordinates,
      },
    };

    try {
      if (!polygonId) return;

      if (tempImages.length || tempFiles.length) {
        const requests: any[] = [];

        for (const image of tempImages) {
          if (image.flag === 'add') {
            const formData = new FormData();
            formData.append('image', image.image, image?.image?.['name']);
            formData.append('polygon', image.polygon.toString());
            requests.push(ImagesService.saveImage(formData));
          }
          if (image.flag === 'delete' && image.id) {
            requests.push(ImagesService.deleteImageById({ id: image.id }));
          }
        }

        for (const file of tempFiles) {
          if (file.flag === 'add') {
            const formData = new FormData();
            formData.append('file', file.file, file?.file?.['name']);
            formData.append('polygon', file.polygon.toString());
            formData.append('name', file.name);
            requests.push(FilesService.saveFile(formData));
          }
          if (file.flag === 'delete' && file.id) {
            requests.push(FilesService.deleteFileById({ id: file.id }));
          }
        }

        await Promise.all(requests);
        setTempImages([]);
        setTempFiles([]);
      }

      await PolygonsService.updateReestrPolygon(params, +polygonId);
      await init(polygonId);
    } catch (e) {
      console.log(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    navigate('/dashboard');
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={
        'w-full flex flex-col d-lg:flex-row justify-between gap-5 pb-5 t-sm:pb-10 d-lg:px-0 ' +
        'px-3 t-sm:px-5 d-lg:pr-6'
      }
    >
      <div className="d-lg:w-[37rem] d-lg:min-w-[37rem] w-full">
        <div
          className="w-full flex items-center cursor-pointer mb-5 d-lg:mb-10"
          onClick={handleBack}
        >
          <ArrowIcon className="w-5 h-5 dark:stroke-path-white -rotate-90" />
          <div className="dark:text-white font-gothampro-500 ml-2">Назад</div>
        </div>
        {tempImages?.length || isEdit ? (
          <div className="w-full mb-5 d-lg:mb-10">
            {blockTitle('Фотографии участка')}
            {isEdit && selectedPolygon?.id ? (
              <DashboardSingleAddPhoto
                polygonId={selectedPolygon.id}
                tempImages={tempImages}
                setTempImages={setTempImages}
              />
            ) : null}

            {!isEdit && tempImages.length ? (
              <DashboardSingleCarousel
                images={tempImages}
                classNames={'h-[20rem] t-sm:h-[28rem] t-lg:h-[30rem] d-lg:h-[24rem]'}
              />
            ) : null}
          </div>
        ) : null}

        <DashboardSingleBanner
          isEdit={isEdit}
          register={register}
          polygon={selectedPolygon}
          classNames="mb-5 d-lg:mb-16"
        />

        <DashBoardSingleMap polygon={selectedPolygon} />
      </div>

      <div className="d-lg:max-w-[48rem] w-full max-w-auto">
        <div className="w-full mb-5 d-lg:mb-10">
          {blockTitle('Характеристики')}
          {isEdit ? (
            <DashboardStatEdit
              polygonSelects={polygonSelects}
              setPolygonSelects={setPolygonSelects}
              resetField={resetField}
              selectData={selectData}
            />
          ) : (
            <DashboardSingleStat polygon={selectedPolygon} classNames={blockClassName} />
          )}
        </div>

        {(!selectedPolygon?.comments || isCommentEmpty) && !isEdit ? null : (
          <div className="w-full mb-5 d-lg:mb-10">
            {blockTitle('Описание')}
            {isEdit ? (
              <div className={blockClassName}>
                <Comment comment={comment} disabled={false} setComment={setComment} />
              </div>
            ) : (
              <div
                className={cn(
                  'font-gothampro-400 dark:text-white whitespace-pre-wrap',
                  blockClassName,
                )}
              >
                {parse(selectedPolygon?.comments || '')}
              </div>
            )}
          </div>
        )}

        {(tempFiles?.length && selectedPolygon?.id) || (isEdit && selectedPolygon?.id) ? (
          <div className="w-full mb-5 d-lg:mb-10">
            {blockTitle('Документация')}
            <DashboardSingleFiles
              polygonId={selectedPolygon.id}
              isEdit={isEdit}
              tempFiles={tempFiles}
              setTempFiles={setTempFiles}
              classNames={blockClassName}
            />
          </div>
        ) : null}

        {user?.isLeader || user?.isManager ? (
          <div className="w-full flex gap-2 justify-end">
            {isEdit ? (
              <Button variant="grey" size="big" onClick={handleCancelEdit}>
                Отменить
              </Button>
            ) : null}
            {!user.isLeader ? (
              <Button
                variant="blue"
                size="big"
                onClick={e => {
                  !isEdit && e?.preventDefault();
                  isEdit ? endEdit() : startEdit();
                }}
              >
                {isEdit ? 'Сохранить' : 'Редактировать'}
              </Button>
            ) : null}
          </div>
        ) : null}
      </div>
    </form>
  );
};
