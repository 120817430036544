import { Dispatch, FC, Fragment, SetStateAction, useMemo } from 'react';
import { Select } from 'src/components/Select';
import { Input } from 'src/components/Input';
import { getLandPlotValue, getPolygonSelectLists } from 'src/utils/helpers';
import { useAuth } from 'src/hooks/useAuth';
import { PolygonSelectsType, SelectItemType } from 'src/config/types';
import { useAppSelector } from 'src/hooks/useRedux';
import { landPlotsFields } from 'src/config/data';

interface DashboardStatEditProps {
  polygonSelects: PolygonSelectsType;
  setPolygonSelects: Dispatch<SetStateAction<PolygonSelectsType>>;
  resetField: (arg: string) => void;
  selectData;
}

export const DashboardStatEdit: FC<DashboardStatEditProps> = ({
  polygonSelects,
  setPolygonSelects,
  resetField,
  selectData,
}) => {
  const { selectedPolygon } = useAppSelector(state => state.polygons);
  const categories = useAppSelector(state => state.categories);

  const { user } = useAuth();

  const polygonsSelectLists = useMemo(
    () => getPolygonSelectLists(categories, polygonSelects),
    [categories, polygonSelects],
  );

  const handleChangeStatus = (status: SelectItemType) => {
    setPolygonSelects(prev => ({ ...prev, status }));
    const selectedStatus = categories.statuses.find(el => (el?.id || 0) === +status.value);

    if (!selectedStatus?.id) return;

    if (!selectedStatus?.substatuses?.length) {
      resetField('substatus');
    }
  };

  const textClassName =
    'text-grey-500 dark:text-grey-700 font-gothampro-400 text-xs t-ls:text-sm w-full t-sm:min-w-[10rem]';
  const lineClassName = 'w-full flex t-sm:flex-row flex-col items-center gap-1 t-sm:gap-2';

  return (
    <div className="flex flex-col gap-2 t-lg:gap-3.5">
      {landPlotsFields.map((field, i) => {
        const value = getLandPlotValue(field, selectedPolygon?.reestrInfo?.feature.attrs);

        if (!value.length || value === ' ' || value === '  ()') {
          return <Fragment key={`object-info-line-edit-${i}`} />;
        }

        return (
          <div className={lineClassName} key={`object-info-line-edit-${i}`}>
            <div className={textClassName}>{field.name}</div>
            <Input defaultValue={value} disabled />
          </div>
        );
      })}

      <div className={lineClassName}>
        <div className={textClassName}>Направление:</div>
        <Select
          data={{ name: 'block_status', required: false, placeholder: 'Направление' }}
          list={polygonsSelectLists.blockStatuses}
          value={polygonSelects.blockStatus}
          onChange={blockStatus => setPolygonSelects(prev => ({ ...prev, blockStatus }))}
          {...selectData}
        />
      </div>

      <div className={lineClassName}>
        <div className={textClassName}>Группа объекта:</div>
        <Select
          data={{ name: 'category', required: false, placeholder: 'Группа объекта' }}
          list={polygonsSelectLists.categories}
          noIcon={!user?.is_colorpicker}
          value={polygonSelects.category}
          onChange={category => setPolygonSelects(prev => ({ ...prev, category }))}
          {...selectData}
        />
      </div>

      <div className={lineClassName}>
        <div className={textClassName}>Правообладатель:</div>
        <Select
          data={{
            name: 'owner',
            required: false,
            placeholder: 'Введите имя правообладателя',
          }}
          withSearch
          list={polygonsSelectLists.owners}
          value={polygonSelects.owner}
          onChange={owner => setPolygonSelects(prev => ({ ...prev, owner }))}
          {...selectData}
        />
      </div>

      <div className={lineClassName}>
        <div className={textClassName}>Вид права:</div>
        <Select
          data={{ name: 'type_owner', required: false, placeholder: 'Введите вид права' }}
          list={polygonsSelectLists.typeOwners}
          value={polygonSelects.typeOwner}
          onChange={typeOwner => setPolygonSelects(prev => ({ ...prev, typeOwner }))}
          {...selectData}
        />
      </div>

      <div className={lineClassName}>
        <div className={textClassName}>Статус:</div>
        <Select
          data={{ name: 'status', required: true, placeholder: 'Статус' }}
          list={polygonsSelectLists.statuses}
          value={polygonSelects.status}
          onChange={handleChangeStatus}
          {...selectData}
        />
      </div>

      {polygonsSelectLists.subStatuses?.length ? (
        <div className={lineClassName}>
          <div className={textClassName}>Подстатус:</div>
          <Select
            data={{ name: 'substatus', required: true, placeholder: 'Подстатус' }}
            list={polygonsSelectLists.subStatuses}
            value={polygonSelects.subStatus}
            onChange={subStatus => setPolygonSelects(prev => ({ ...prev, subStatus }))}
            {...selectData}
          />
        </div>
      ) : null}
    </div>
  );
};
