import { createSelector } from 'reselect';
import { PolygonType } from 'src/config/types';

const reestrPolygons = state => state.polygons.reestrPolygons;
const drawPolygons = state => state.polygons.drawPolygons;
const filters = state => state.filters.polygonsFilter;
const lassoFilter = state => state.filters.lassoFilter;

export const getFilteredPolygons = createSelector(
  [reestrPolygons, drawPolygons, filters, lassoFilter],
  (reestrPolygons, drawPolygons, filters, lassoFilter) => {
    const polygons: PolygonType[] = [
      ...(lassoFilter?.polygonIds?.length ? [] : drawPolygons),
      ...reestrPolygons,
    ];

    if (
      !filters?.categories?.length &&
      !filters?.blockStatuses?.length &&
      !filters?.statuses?.length &&
      !filters?.subStatuses?.length &&
      !filters?.typeOwners?.length &&
      !filters?.owners?.length &&
      !lassoFilter?.polygonIds?.length
    ) {
      return polygons;
    }
    const res: PolygonType[] = [];

    polygons?.forEach(polygon => {
      if (
        (filters?.categories?.length
          ? filters?.categories.includes(polygon.properties?.category || 0)
          : true) &&
        (filters?.blockStatuses?.length
          ? filters?.blockStatuses.includes(polygon.properties?.block_status || 0)
          : true) &&
        (filters?.statuses?.length
          ? filters?.statuses?.includes(polygon?.properties?.status)
          : true) &&
        (filters?.subStatuses?.length
          ? filters?.subStatuses.includes(polygon.properties?.substatus || 0)
          : true) &&
        (filters?.typeOwners?.length
          ? filters?.typeOwners.includes(polygon.properties?.type_owner || 0)
          : true) &&
        (filters?.owners?.length
          ? filters?.owners.includes(polygon.properties?.owner || 0)
          : true) &&
        (lassoFilter?.polygonIds?.length ? lassoFilter?.polygonIds.includes(polygon.id) : true)
      ) {
        res.push(polygon);
      }
    });

    return res;
  },
);
