import { FC, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import { useAppSelector } from 'src/hooks/useRedux';

import boldIcon from 'src/assets/icons/kit/bold.svg';
import italicIcon from 'src/assets/icons/kit/italic.svg';
import underlineIcon from 'src/assets/icons/kit/underline.svg';
import emojiIcon from 'src/assets/icons/emoji.svg';
import imageIcon from 'src/assets/icons/kit/image.svg';
import linkIcon from 'src/assets/icons/kit/link.svg';

import './styles.css';
import cn from 'classnames';
import { useAuth } from 'src/hooks/useAuth';

interface CommentProps {
  comment: EditorState;
  disabled: boolean;
  setComment: (arg: EditorState) => void;
}

const toolbar = {
  options: ['inline', 'emoji', 'link'],
  inline: {
    options: ['bold', 'italic', 'underline'],
    bold: { icon: boldIcon, className: 'editor-icon opacity-30' },
    italic: { icon: italicIcon, className: 'editor-icon opacity-30' },
    underline: {
      icon: underlineIcon,
      className: 'editor-icon opacity-30',
    },
  },
  emoji: {
    icon: emojiIcon,
    className: 'editor-icon',
  },
  link: {
    options: ['link'],
    link: { icon: linkIcon, className: 'editor-icon' },
  },
  image: {
    icon: imageIcon,
    className: 'editor-icon',
  },
};
export const Comment: FC<CommentProps> = ({ comment, disabled, setComment }) => {
  const { darkMode } = useAppSelector(state => state.common);
  const { user } = useAuth();

  const handleChange = e => {
    if (disabled) return;

    setComment(e);
  };

  useEffect(() => {
    const editorContainer = document.querySelector('.public-DraftEditor-content');

    if (!editorContainer) return;

    editorContainer.setAttribute('contenteditable', (!disabled).toString());
  }, [disabled]);

  const styleMap = {
    BOLD: {
      'font-family': `'GothamPro Bold', sans-serif`,
    },
  };

  return (
    <Editor
      customStyleMap={styleMap}
      wrapperClassName={cn({ 'dark-mode': darkMode }, 'editor-wrapper')}
      editorClassName={cn(
        { 'dark-mode': darkMode, 'editor-main-disabled': disabled },
        'editor-main',
      )}
      toolbarClassName={user?.isManager ? 'editor-toolbar' : 'hidden'}
      editorState={comment}
      onEditorStateChange={handleChange}
      toolbar={user?.isManager ? toolbar : {}}
      placeholder="Комментарий"
    />
  );
};
