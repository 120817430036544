import {
  CategoryType,
  ClickFetchType,
  ObjectInfoAttrsType,
  ObjectInfoType,
  PolygonSelectsType,
  PolygonsFilterType,
  PolygonType,
  SelectItemType,
  StatusType,
} from 'src/config/types';
import { coordsToGPS, numberWithSpaces, stringToCoords } from 'src/utils/index';
import { stateFromHTML } from 'draft-js-import-html';
import { setIsBurgerOpen, setIsNewPolygonFormOpen, setOpenedDropBlock } from 'src/store/common';
import { fetchDrawPolygonById, fetchReestrPolygonById } from 'src/store/polygons/actions';
import { setFlyTo } from 'src/store/map';
import { setNotSavedDrawFavoritePolygonId } from 'src/store/polygons';
import { CategoriesState } from 'src/store/categories/types';
import { Dispatch, SetStateAction } from 'react';
import { EditorState } from 'draft-js';
import { LatLng, LatLngExpression } from 'leaflet';
import {
  filterKeys,
  initialPolygon,
  layersList,
  polygonSelectFields,
  reestrDict,
} from 'src/config/data';

import ReestrService from 'src/services/ReestrService';

import { ReactComponent as FingerIcon } from 'src/assets/icons/kit/finger.svg';
import { ReactComponent as BoxIcon } from 'src/assets/icons/kit/box.svg';
import { ReactComponent as HomeIcon } from 'src/assets/icons/kit/home.svg';
import { ReactComponent as DecorationIcon } from 'src/assets/icons/kit/decoration.svg';

export const getPolygonName = (polygon: PolygonType): string =>
  polygon?.name ||
  polygon?.properties?.name ||
  (polygon?.cadaster_number || polygon?.properties?.cadaster_number
    ? `${polygon?.cadaster_number || polygon?.properties?.cadaster_number}${
        polygon?.address || polygon?.properties?.address
          ? ` - ${polygon?.address || polygon?.properties?.address}`
          : ''
      }`
    : 'Без названия');

export const calculateStatusesCount = cluster => {
  const statusesCount = {};

  if (cluster._markers.length) {
    cluster._markers.forEach(marker => {
      const status = marker?.options?.icon?.options?.status;

      if (statusesCount?.[status]) {
        statusesCount[status] += 1;
      } else {
        statusesCount[status] = 1;
      }
    });
  }

  if (cluster._childClusters.length) {
    cluster._childClusters.forEach(childCluster => {
      const temp = calculateStatusesCount(childCluster);
      Object.keys(temp).forEach(key => {
        if (statusesCount?.[key]) {
          statusesCount[key] += temp[key];
        } else {
          statusesCount[key] = temp[key];
        }
      });
    });
  }

  return statusesCount;
};

export const isDrawing = () =>
  !!document.querySelector('.leaflet-draw-toolbar-button-enabled') ||
  !!document.querySelector('.leaflet-ruler-clicked');

export const geoJSONToCoords = (str: string): LatLngExpression[][] => {
  if (str.includes('MULTIPOLYGON')) {
    const newStr = str.replace('SRID=4326;MULTIPOLYGON (((', '').replace(')))', '');
    const split = newStr.split('), (');

    return split
      .map(el => el.split(', '))
      .map(el =>
        el.map(el => {
          const temp = el.split(' ');
          return [+temp[0], +temp[1]];
        }),
      );
  }
  const split = str.split(', ');
  split[0] = split[0].replace('SRID=4326;POLYGON ((', '');
  split[split.length - 1] = split[split.length - 1].replace('))', '');

  return [
    split.map(el => {
      const temp = el.split(' ');
      return [+temp[0], +temp[1]];
    }),
  ];
};

export const coordsToGeoJSON = (coords?: number[][][], isMulti?: boolean) => {
  if (!coords) return '';

  if (isMulti) {
    const temp = coords.map(el => el.map(el => el.join(' ')).join(', ')).join('), (');

    return `SRID=4326;MULTIPOLYGON (((${temp})))`;
  }

  const temp = coords[0].map(el => el.join(' '));
  return `SRID=4326;POLYGON ((${temp.join(', ')}))`;
};

export const handleSelectPolygon = async (polygon: PolygonType, width: number, dispatch) => {
  if (!polygon?.id) return;

  addCnToUrl(polygon?.properties?.cadaster_number);

  if (polygon?.properties?.cadaster_number) {
    await dispatch(fetchReestrPolygonById(polygon.id.toString()));
  } else {
    await dispatch(fetchDrawPolygonById(polygon.id.toString()));
  }

  if (width >= 1024) {
    dispatch(setIsNewPolygonFormOpen(true));
    dispatch(setOpenedDropBlock({ info: true }));
  } else {
    dispatch(setOpenedDropBlock({}));
    dispatch(setIsBurgerOpen(false));
  }

  dispatch(setNotSavedDrawFavoritePolygonId(null));
  dispatch(setFlyTo(stringToCoords(polygon?.properties?.center || '')));
};

export const getPolygonCenterPosition = (polygon: PolygonType): LatLngExpression => {
  let position;
  const reestrCenter = polygon?.reestrInfo?.feature?.center;
  if (reestrCenter) {
    const centerReversed = coordsToGPS([reestrCenter.x, reestrCenter.y]);
    position = [centerReversed?.[1], centerReversed?.[0]];
  } else {
    position = polygon?.center ? stringToCoords(polygon?.center) : [];
  }

  return position;
};

export const calcLassoButtonPosition = (coords: LatLng[]): LatLngExpression => {
  let maxX = 0,
    maxY = 0;

  coords.forEach(coord => {
    if (maxX < +coord.lat) {
      maxX = coord.lat;
    }

    if (maxY < +coord.lng) {
      maxY = coord.lng;
    }
  });

  return [maxX, maxY];
};

export const createReestrImageUrl = (data: ObjectInfoType, selectedLayers: number[]) => {
  let layer = layersList[1];

  if (selectedLayers.includes(3)) {
    layer = layersList[2];
  }

  if (selectedLayers.includes(7)) {
    layer = layersList[6];
  }

  if (selectedLayers.includes(8)) {
    layer = layersList[7];
  }

  if (selectedLayers.includes(1)) {
    layer = layersList[0];
  }

  const extent = data?.feature?.extent_parent || data?.feature?.extent;

  const width = Math.trunc((extent?.xmax || 0) - (extent?.xmin || 0)) * 2;
  const height = Math.trunc((extent?.ymax || 0) - (extent?.ymin || 0)) * 2;

  const coords = `${extent?.xmin},${extent?.ymin},${extent?.xmax},${extent?.ymax}`;

  const cn = data.feature.attrs.id?.includes('/')
    ? data.feature.attrs.id?.split('/')[0]
    : data.feature.attrs.id;

  const contourNum = data.feature.attrs.id?.includes('/')
    ? data.feature.attrs.id?.split('/')[1]
    : '';

  const queryId = `{${layer.imageShow
    .map(num => `"${num}":"id = '${cn}'${contourNum ? ` and contour_num = ${contourNum}` : ''}"`)
    .join(',')}}`;

  const reestrUrl = `https://pkk.rosreestr.ru/arcgis/rest/services/PKK6/${layer.imageLink}/MapServer/export`;

  const reestrUrlParams = `bbox=${coords}&size=${width}%2C${height}&dpi=96&format=png32&transparent=true&bboxSR=102100&imageSR=102100&layers=show%3A${layer.imageShow.join(
    '%2C',
  )}&layerDefs=${encodeURIComponent(queryId)}&f=image`;

  const link = `${reestrUrl}?${reestrUrlParams}`;

  const bbox = `${extent?.xmin},${extent?.ymax},${extent?.xmax},${extent?.ymin}`;

  return {
    link,
    bbox,
  };
};

export const getFetchReestrImageType = (selectedLayers: number[]): ClickFetchType => {
  let fetchType: ClickFetchType = 'getLand';

  if (selectedLayers.includes(3)) {
    fetchType = 'getBuildings';
  }

  if (selectedLayers.includes(7)) {
    fetchType = 'getBorders';
  }

  if (selectedLayers.includes(8)) {
    fetchType = 'getTerritoryZone';
  }

  if (selectedLayers.includes(1)) {
    fetchType = 'getSpecialZone';
  }

  return fetchType;
};

export const addDefs = statuses => {
  if (!statuses.length) return;

  const mapSvg = document.querySelectorAll('svg.leaflet-zoom-animated');

  if (!mapSvg.length) return;

  mapSvg.forEach(svg => {
    if (!!svg.querySelector('#color-patterns')?.childNodes.length) return;

    const defs = generateDefs(statuses);
    svg?.['appendChild'](defs);
  });
};

export const generateDefs = (blockStatuses: StatusType[]) => {
  const xmlns = 'http://www.w3.org/2000/svg';
  const defs = document.createElementNS(xmlns, 'defs');

  defs.setAttributeNS(null, 'id', 'color-patterns');

  blockStatuses.forEach(status => {
    if (!status.color) return;

    const pattern = document.createElementNS(xmlns, 'pattern');
    const line = document.createElementNS(xmlns, 'line');
    const color = status.color.replace('#', '');

    pattern.setAttributeNS(null, 'id', `pattern-${color}`);
    pattern.setAttributeNS(null, 'height', '16');
    pattern.setAttributeNS(null, 'width', '16');
    pattern.setAttributeNS(null, 'patternUnits', 'userSpaceOnUse');
    pattern.setAttributeNS(null, 'patternTransform', 'rotate(-30)');

    line.setAttributeNS(null, 'x1', '0');
    line.setAttributeNS(null, 'x2', '9');
    line.setAttributeNS(null, 'y1', '8');
    line.setAttributeNS(null, 'y2', '8');
    line.setAttributeNS(null, 'stroke-width', '4');
    line.setAttributeNS(null, 'stroke', status.color);

    pattern.appendChild(line);
    defs.appendChild(pattern);
  });

  return defs;
};

export const calculateFiltersCount = (filter: PolygonsFilterType): number => {
  let count = 0;

  Object.keys(filter).forEach(key => {
    count += filter[key]?.length;
  });

  return count;
};

export const getSubStatusIcon = (statusColor: string, subStatusIcon: string) => {
  const style = {
    fill: statusColor,
  };
  const className = 'w-3 h-3 min-w-[0.75rem] ml-1 mr-1';

  const subStatusIcons = {
    finger: <FingerIcon className={className} style={style} />,
    box: <BoxIcon className={className} style={style} />,
    home: <HomeIcon className={className} style={style} />,
    decoration: <DecorationIcon className={className} style={style} />,
  };

  return subStatusIcons[subStatusIcon];
};

export const getPolygonFormSelectItem = (status: StatusType | CategoryType): SelectItemType => ({
  value: (status?.id || 0).toString(),
  label: status.name,
  ...(status?.['color'] && {
    icon: (
      <div
        className="w-4 h-4 min-w-[1rem] mr-2 rounded-sm"
        style={{ backgroundColor: status?.['color'] }}
      />
    ),
  }),
});

export const getPolygonSelectLists = (
  category: CategoriesState,
  polygonSelects: PolygonSelectsType,
): {
  categories: SelectItemType[];
  statuses: SelectItemType[];
  subStatuses: SelectItemType[];
  blockStatuses: SelectItemType[];
  owners: SelectItemType[];
  typeOwners: SelectItemType[];
} => {
  const categories = category.categories.map(el => getPolygonFormSelectItem(el));
  const statuses = category.statuses.map(status => getPolygonFormSelectItem(status));
  const blockStatuses = category.blockStatuses.map(status => getPolygonFormSelectItem(status));
  const owners = category.owners.map(status => getPolygonFormSelectItem(status));
  const typeOwners = category.typeOwners.map(status => getPolygonFormSelectItem(status));

  const currentStatus = category.statuses.find(
    el => el.id.toString() === polygonSelects?.status?.value?.toString(),
  );
  const subStatuses = currentStatus?.substatuses?.length
    ? currentStatus?.substatuses.map(status => getPolygonFormSelectItem(status))
    : [];

  return { categories, statuses, subStatuses, blockStatuses, owners, typeOwners };
};

export const getPolygonCategories = (
  polygon: PolygonType,
  categories: CategoriesState,
): {
  category?: CategoryType;
  status?: StatusType;
  subStatus?: StatusType;
  blockStatus?: StatusType;
  owner?: StatusType;
  typeOwner?: CategoryType;
} => {
  const category = categories.categories.find(
    el => el.id === polygon?.properties?.category || el.id === polygon?.category,
  );
  const status = categories.statuses.find(
    el => el.id === (polygon?.status || polygon?.properties?.status),
  );
  const subStatus = status?.substatuses?.find(
    el => el?.id === polygon?.properties?.substatus || el?.id === polygon?.substatus,
  );
  const blockStatus = categories.blockStatuses.find(
    el => el?.id === polygon?.properties?.block_status || el?.id === polygon?.block_status,
  );
  const owner = categories.owners.find(
    el => el?.id === polygon?.properties?.owner || el?.id === polygon?.owner,
  );
  const typeOwner = categories.typeOwners.find(
    el => el?.id === polygon?.properties?.type_owner || el?.id === polygon?.type_owner,
  );

  return { category, status, subStatus, blockStatus, owner, typeOwner };
};

export const selectPolygonSelects = (
  polygon: PolygonType | null,
  categories: CategoriesState,
  setPolygonSelects: Dispatch<SetStateAction<PolygonSelectsType>>,
  setValue: (name: string, value: string) => void,
  setComment?: Dispatch<SetStateAction<EditorState>>,
) => {
  if (!polygon) return;

  const { category, status, subStatus, blockStatus, owner, typeOwner } = getPolygonCategories(
    polygon,
    categories,
  );

  if (!status) return;

  setPolygonSelects({
    status: getPolygonFormSelectItem(status),
    subStatus: subStatus ? getPolygonFormSelectItem(subStatus) : null,
    category: category ? getPolygonFormSelectItem(category) : null,
    blockStatus: blockStatus ? getPolygonFormSelectItem(blockStatus) : null,
    owner: owner ? getPolygonFormSelectItem(owner) : null,
    typeOwner: typeOwner ? getPolygonFormSelectItem(typeOwner) : null,
  });
  setComment &&
    setComment(
      EditorState.createWithContent(
        stateFromHTML(polygon?.comments || polygon?.properties?.comments || ''),
      ),
    );

  // @ts-ignore
  polygonSelectFields.forEach(field => setValue(field, polygon?.[field]));
};

export const getLandPlotValue = (
  field: { name: string; arr: string[] },
  attrs?: ObjectInfoAttrsType,
): string => {
  if (!attrs) {
    return '';
  }

  return field.arr
    .map(el => {
      if ('^()'.indexOf(el) !== -1) {
        return el;
      }

      if (!attrs?.[el]) {
        return '';
      }

      if (el === 'cad_cost' || el === 'area_value') {
        return numberWithSpaces(+(attrs?.[el] || 0));
      }

      return reestrDict?.[el]?.[attrs?.[el]] || attrs?.[el] || el;
    })
    .join('')
    .replaceAll('^', ' ');
};

export const getReestrPolygon = async (
  reestrInfo: ObjectInfoType,
  selectedLayers: number[],
  additional_numbers?: number,
): Promise<PolygonType | null> => {
  const attrs = reestrInfo?.feature?.attrs;
  const reestrCenter = reestrInfo?.feature?.center;

  const params = {
    ...initialPolygon,
    square: attrs?.area_value || 0,
    cadaster_number: attrs?.cn || '',
    address: attrs?.address || '',
    price: attrs?.cad_cost || 0,
    reestrInfo,
  };

  if (reestrInfo?.info) {
    const parent = reestrInfo?.feature?.extent_parent || reestrInfo?.feature?.extent;

    if (!parent) return null;

    const a = coordsToGPS([parent?.xmax, parent.ymax]);
    const b = coordsToGPS([parent?.xmin, parent.ymin]);

    return {
      ...params,
      center: `${(a?.[1] + b?.[1]) / 2},${(a?.[0] + b?.[0]) / 2}`,
      geometry: '',
    };
  }
  const reestrImageUrl = createReestrImageUrl(reestrInfo, selectedLayers);

  const geoJSONCoords = await ReestrService.getGeoJsonPolygon(reestrImageUrl);
  let geometry;

  if (additional_numbers && additional_numbers > 1) {
    const coordinates: number[][][] = [];
    geoJSONCoords.features.forEach(el => {
      const reversedCoords = el.geometry.coordinates[0];
      coordinates.push(reversedCoords.map(coord => coordsToGPS(coord)?.['reverse']()));
    });

    geometry = coordsToGeoJSON(coordinates, true);
  } else {
    const reversedCoords = geoJSONCoords.features[0].geometry.coordinates[0];
    const coordinates = reversedCoords.map(coord => coordsToGPS(coord)?.['reverse']());
    geometry = coordsToGeoJSON([coordinates]);
  }

  const centerReversed = coordsToGPS([reestrCenter.x, reestrCenter.y]);
  const center = [centerReversed?.[1], centerReversed?.[0]];

  return {
    ...params,
    center: `${center[0]},${center[1]}`,
    geometry,
  };
};

export const addCnToUrl = (cn?: string) => {
  if (!cn) return;

  window.history.pushState('', '', `?cn=${cn}`);
};

export const removeQueryParams = () => window.history.pushState('', '', window.location.pathname);

export const getFilters = (polygonsFilter, dashboardPolygonsFilter) => {
  const filters = Object.keys(polygonsFilter).map(key =>
    polygonsFilter[key].length ? `&${filterKeys[key]}=${polygonsFilter[key].join(',')}` : '',
  );
  const dashboardFilters = Object.keys(dashboardPolygonsFilter).map(key => {
    if (dashboardPolygonsFilter?.[key] && typeof dashboardPolygonsFilter[key] === 'string') {
      return `&${key}=${dashboardPolygonsFilter[key]}`;
    } else {
      const arr = Object.keys(dashboardPolygonsFilter[key]).map(
        newKey => `&${key}_${newKey}=${dashboardPolygonsFilter[key][newKey]}`,
      );

      return arr.join('');
    }
  });

  return `${filters.join('')}${dashboardFilters.join('')}`;
};
