import { FC } from 'react';
import { Loader } from 'src/components/Loader';
import { numberWithSpaces } from 'src/utils';
import { useAppSelector } from 'src/hooks/useRedux';

import cn from 'classnames';

export const InfoData: FC = () => {
  const { infoData, isInfoDataLoading } = useAppSelector(state => state.polygons);

  const getNum = (num: number, divideBy: number) => numberWithSpaces((num / divideBy).toFixed(1));

  const getSize = (size: number): string => {
    if (Math.trunc(size).toString().length > 6) {
      return `${getNum(size, 1000 * 1000)} км`;
    }

    return `${getNum(size, 1)} м`;
  };

  const getPrice = (price: number): string => {
    const length = Math.trunc(price).toString().length;

    if (length > 9) {
      return `${getNum(price, 1000 * 1000 * 1000)} млрд р`;
    }

    if (length > 6) {
      return `${getNum(price, 1000 * 1000)} млн р`;
    }

    return `${getNum(price, 1)} р`;
  };

  const data = [
    { label: 'Количество:', value: infoData.count },
    { label: 'Общая площадь', value: getSize(infoData.total_square) },
    { label: 'Стоимость', value: getPrice(infoData.total_cad_cost) },
  ];

  return (
    <div className="flex flex-col gap-2 t-lg:min-w-[12rem]">
      {isInfoDataLoading ? (
        <div className="w-full center">
          <Loader classNames="w-20 h-20" />
        </div>
      ) : null}

      {!isInfoDataLoading ? (
        <>
          {data.map((el, i) => (
            <div key={`info-polygon-${i}`} className="flex items-center gap-2">
              <div className="w-32 t-lg:w-auto font-gothampro-400 text-extra-xs dark:text-white opacity-50">
                {el.label}
              </div>
              <div
                className={cn(
                  {
                    'text-pink-200': el.label === 'Стоимость',
                    'dark:text-white ': el.label !== 'Стоимость',
                  },

                  'bg-grey-rgba-400 dark:bg-transparent px-2 py-1 rounded border ml-auto',
                  'font-gothampro-500 text-xs whitespace-nowrap border-white dark:border-blue-200',
                )}
              >
                {el.value}
                {el.label === 'Общая площадь' ? <sup>2</sup> : null}
              </div>
            </div>
          ))}
        </>
      ) : null}
    </div>
  );
};
