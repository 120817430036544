import { FC } from 'react';
import { ZoneTag } from 'src/components/Zones/ZoneTag';
import { PolygonsFilterType } from 'src/config/types';
import { useAppSelector } from 'src/hooks/useRedux';

interface StatusesFilterProps {
  handleSelect: (id: number, filterName: keyof PolygonsFilterType) => void;
  tempFilter: PolygonsFilterType;
}

export const BlockStatusesFilter: FC<StatusesFilterProps> = ({ handleSelect, tempFilter }) => {
  const { blockStatuses } = useAppSelector(state => state.categories);

  return (
    <div className="flex flex-wrap gap-2">
      {blockStatuses.map(blockStatus => (
        <ZoneTag
          key={`block-status-filter-${blockStatus.id}`}
          isActive={tempFilter?.blockStatuses?.includes(blockStatus.id)}
          fullWidth
          onClick={() => handleSelect(blockStatus.id, 'blockStatuses')}
          category={blockStatus}
        />
      ))}
    </div>
  );
};
