import { ChangeEvent, Dispatch, FC, Fragment, SetStateAction } from 'react';
import { PolygonFileType } from 'src/config/types';
import { Button } from 'src/components/Button';
import { notice } from 'src/utils';
import { BASE_URL } from 'src/config';

import { ReactComponent as PDFIcon } from 'src/assets/icons/kit/pdf.svg';
import { ReactComponent as DocxIcon } from 'src/assets/icons/kit/docx.svg';
import { ReactComponent as XlsIcon } from 'src/assets/icons/kit/xls.svg';
import { ReactComponent as OtherFileIcon } from 'src/assets/icons/kit/other-file.svg';
import { ReactComponent as TrashIcon } from 'src/assets/icons/kit/trash.svg';
import cn from 'classnames';

interface DashboardSingleFilesProps {
  polygonId: number;
  tempFiles: PolygonFileType[];
  setTempFiles: Dispatch<SetStateAction<PolygonFileType[]>>;
  isEdit: boolean;
  classNames?: string;
}

export const DashboardSingleFiles: FC<DashboardSingleFilesProps> = ({
  polygonId,
  isEdit,
  tempFiles,
  setTempFiles,
  classNames,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.files?.length) {
      return;
    }
    saveFile(e.target.files[0]);
  };

  const handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      saveFile(e.dataTransfer.files[0]);
    }
  };

  const iconClassName = 'w-10 h-10 min-w-[2.5rem]';

  const getImage = (name: string) => {
    const splitName = name.split('.');
    const format = splitName?.[splitName.length - 1] || '';

    if (['doc', 'docx'].includes(format)) {
      return <DocxIcon className={cn('fill-blue-1300', iconClassName)} />;
    }

    if (['pdf'].includes(format)) {
      return <PDFIcon className={cn('fill-red-100', iconClassName)} />;
    }

    if (['xls', 'xlsx'].includes(format)) {
      return <XlsIcon className={cn('fill-green-200', iconClassName)} />;
    }

    return <OtherFileIcon className={cn('fill-grey-200', iconClassName)} />;
  };

  const saveFile = (file: File) => {
    const maxSize = 25 * 1024 * 1024;
    if (file.size > maxSize) {
      notice({
        title: 'Ошибка!',
        message: 'максимальный размер - 25мб',
        type: 'danger',
      });
      return;
    }

    const newFile: PolygonFileType = {
      polygon: polygonId,
      flag: 'add',
      name: file.name,
      file,
    };

    setTempFiles(prev => [...prev, newFile]);
  };

  const handleRemove = (i: number) => {
    if (!tempFiles?.[i]?.id) {
      setTempFiles(prev => prev.filter((_, index) => index !== i));
      return;
    }

    setTempFiles(prev => {
      const temp = [...prev];

      return temp.map((file, index) => {
        if (index === i) {
          return { ...file, flag: 'delete' };
        }
        return file;
      });
    });
  };

  return (
    <div className={cn(classNames)}>
      <div className="w-full grid t-sm:grid-cols-2 gap-5">
        {tempFiles.map((file, i) =>
          file.flag === 'delete' ? (
            <Fragment key={`polygon-file-${i}`} />
          ) : (
            <a
              key={`polygon-file-${i}`}
              className={cn(
                { 'cursor-default': typeof file.file !== 'string' },
                'w-full flex items-center gap-2 group',
              )}
              href={typeof file.file === 'string' ? `${BASE_URL}${file.file}` : undefined}
              target="_blank"
              download
            >
              {getImage(file.name)}
              <div
                className={
                  'truncate text-blue-100 dark:text-white text-xs t-lg:text-sm transition-full ' +
                  'font-gothampro-400 group-hover:text-orange-100 dark:group-hover:text-blue-100'
                }
              >
                {file.name}
              </div>
              {isEdit ? (
                <TrashIcon
                  onClick={e => {
                    e.preventDefault();
                    handleRemove(i);
                  }}
                  className={
                    'cursor-pointer w-4 h-4 fill-grey-2800 hover:fill-blue-100 ' +
                    'dark:hover:fill-orange-100 ml-auto'
                  }
                />
              ) : null}
            </a>
          ),
        )}
      </div>

      {isEdit ? (
        <div className={cn({ 'mt-5': tempFiles?.length }, 'w-full flex justify-center')}>
          <label
            onDrop={handleDrop}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            className={
              'w-full t-sm:w-1/2 rounded border border-dashed p-3 dark:bg-blue-1200 ' +
              'dark:bg-blue-300 flex flex-col dark:border-blue-200 bg-white-200 ' +
              'border-grey-2900'
            }
          >
            <div className="text-grey-500 text-xs font-gothampro-400 mx-auto mb-1">
              Перетяните файл в эту область
            </div>
            <div className="text-grey-500 text-extra-xs font-gothampro-400 mx-auto mb-5">
              или нажмите на кнопку
            </div>
            <Button as="div" variant="grey" classNames="w-fit mx-auto">
              Загрузить файл с компьютера
            </Button>

            <input type="file" className="hidden" onChange={handleChange} multiple={false} />
          </label>
        </div>
      ) : null}
    </div>
  );
};
