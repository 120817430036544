import { FC, useEffect, useRef } from 'react';
import { Search } from 'src/components/Search';
import { LayersSelect } from 'src/components/LayersSelect';
import { fetchSearchAddressList } from 'src/store/reestr/actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { ActiveLayers } from 'src/components/ActiveLayers';
import { checkIsCn, isCustomFilterEmpty, isFiltersEmpty } from 'src/utils';
import { HeadLoader } from 'src/components/HeadLoader';
import { useLocation } from 'react-router';
import { DarkModeSwitch } from 'src/components/DarkModeSwitch';
import { useAuth } from 'src/hooks/useAuth';
import { setSelectedPolygon } from 'src/store/polygons';
import { setIsBurgerOpen } from 'src/store/common';
import { Navigation } from 'src/components/Navigation';
import { setDashboardPolygonsFilter, setPolygonsFilter } from 'src/store/filters';
import { Button } from 'src/components/Button';
import { useWindowDimensions } from 'src/hooks/useWindowDimension';
import { ImportExcelButton } from 'src/components/Excel/ImportExcelButton';
import { ExportExcelButton } from 'src/components/Excel/ExportExcelButton';
import { fetchSelectedPolygon } from 'src/store/polygons/actions';
import { removeQueryParams } from 'src/utils/helpers';
import { useClickOutside } from 'src/hooks/useClickOutside';
import { HeaderBurger } from 'src/components/Header/HeaderBurger';
import { AppToolTip } from 'src/components/AppToolTip';
import { Link } from 'react-router-dom';

import { ReactComponent as BurgerIcon } from 'src/assets/icons/kit/burger.svg';
import { ReactComponent as ExitIcon } from 'src/assets/icons/kit/exit.svg';
import { ReactComponent as CloseIcon } from 'src/assets/icons/kit/close.svg';
import { ReactComponent as FilterCloseIcon } from 'src/assets/icons/kit/filter-close.svg';
import { ReactComponent as UserIcon } from 'src/assets/icons/kit/user.svg';
import cn from 'classnames';

export const Header: FC = () => {
  const { polygonsFilter, dashboardPolygonsFilter } = useAppSelector(state => state.filters);
  const { selectedLayers } = useAppSelector(state => state.map);
  const { isBurgerOpen } = useAppSelector(state => state.common);

  const { width } = useWindowDimensions();
  const { pathname } = useLocation();
  const { logout, user } = useAuth();
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);

  const handleCloseBurger = () => dispatch(setIsBurgerOpen(false));
  const handleOpenBurger = () => dispatch(setIsBurgerOpen(true));

  useClickOutside(ref, handleCloseBurger);

  const isMap = pathname === '/map';
  const isLogin = pathname === '/';
  const isDashboard = pathname.includes('dashboard');
  const isUser = pathname.includes('user');
  const isOnlyDashboard = pathname === '/dashboard';

  const handleChangeSearch = async (searchString: string, noSearch?: boolean) => {
    if (!searchString) return;

    if (!noSearch) {
      await dispatch(fetchSearchAddressList(searchString));
      return;
    }

    if (checkIsCn(searchString)) {
      await dispatch(fetchSelectedPolygon({ cn: searchString, selectedLayers }));
    } else {
      await dispatch(fetchSelectedPolygon({ text: searchString, selectedLayers }));
    }
  };

  const handleLogout = async () => {
    isBurgerOpen && handleCloseBurger();
    await logout();
    dispatch(setSelectedPolygon({ polygon: null }));
    removeQueryParams();
  };

  const handleResetFilters = () => {
    dispatch(setPolygonsFilter({}));
    dispatch(setDashboardPolygonsFilter({}));
  };

  const moveMapButtons = () => {
    if (width >= 1024) return;

    const layersContainer = document.querySelector<HTMLDivElement>('#layers-container');
    const items = document.querySelectorAll<HTMLDivElement>('.leaflet-top');

    const height = (layersContainer?.offsetHeight || 0) - 38;

    items.forEach(item => {
      item.style.transform = `translateY(${height}px)`;
    });
  };

  useEffect(() => {
    moveMapButtons();
  }, [selectedLayers, width]);

  useEffect(() => {
    window.addEventListener('orientationchange', moveMapButtons);
  }, []);

  return (
    <div className={cn({ 'bg-white dark:bg-blue-800': isBurgerOpen }, 'w-full z-2400 relative')}>
      <div className="w-full relative" id="head">
        {isLogin ? (
          <div className="px-4 t-lg:px-8 py-4 gap-3 t-lg:gap-4 flex">
            <DarkModeSwitch />
          </div>
        ) : null}

        {!isLogin ? (
          <>
            <div
              className={
                'w-full relative t-lg:justify-center flex-wrap flex items-center ' +
                'px-4 t-lg:px-8 py-4 h-16 t-lg:h-20'
              }
            >
              <HeadLoader isMap={isMap} />

              {!isDashboard && !isUser ? (
                <Search
                  onChange={handleChangeSearch}
                  withList
                  placeholder="Поиск"
                  classNames="w-72 d-xs:w-[28rem] d-lg:w-[38rem] hidden t-lg:flex absolute top-0"
                  style={{ left: `calc(50% - ${width >= 1440 ? 20 : width >= 1200 ? 16 : 10}rem)` }}
                />
              ) : null}

              <div className="ml-auto flex items-center">
                {isOnlyDashboard && !isBurgerOpen ? (
                  <>
                    {!isFiltersEmpty(polygonsFilter) ||
                    !isCustomFilterEmpty(dashboardPolygonsFilter) ? (
                      <Button
                        variant="orange"
                        size={width >= 1024 ? 'big' : 'base'}
                        onClick={handleResetFilters}
                        classNames="mr-3"
                      >
                        {width >= 1024 ? (
                          'Сбросить фильтры'
                        ) : (
                          <FilterCloseIcon className="w-4 h-4 fill-white" />
                        )}
                      </Button>
                    ) : null}
                    {user?.isManager || user?.isLeader ? (
                      <>
                        <ImportExcelButton variant="icon" classNames="hidden m-md:block mr-3" />
                        <ExportExcelButton variant="icon" classNames="hidden m-md:block" />
                      </>
                    ) : null}
                  </>
                ) : null}

                {isBurgerOpen ? (
                  <CloseIcon
                    className="h-7 w-7 fill-blue-100 cursor-pointer t-lg:hidden"
                    onClick={handleCloseBurger}
                  />
                ) : null}

                {!isBurgerOpen ? (
                  <>
                    <div className="flex t-lg:hidden">
                      <Navigation />
                    </div>
                    <BurgerIcon
                      className="h-7 w-7 fill-blue-100 cursor-pointer t-lg:hidden"
                      onClick={handleOpenBurger}
                    />
                  </>
                ) : null}
              </div>

              {isMap ? <LayersSelect classNames="hidden t-lg:block" /> : null}

              <div className="hidden t-lg:flex t-lg:ml-4">
                <Link to="/user">
                  <AppToolTip text="Пользователь">
                    <div
                      className={
                        'w-11 h-11 min-w-[2.75rem] center bg-orange-300 hover:shadow-oranges-100 ' +
                        'cursor-pointer rounded transition-full'
                      }
                    >
                      <UserIcon className="w-4 h-4 fill-white" />
                    </div>
                  </AppToolTip>
                </Link>
                <div onClick={handleLogout} className="flex cursor-pointer gap-2 items-center ml-2">
                  <div className="font-gothampro-500 text-grey-1800">Выход</div>
                  <ExitIcon className="w-4 h-4 fill-grey-1800" />
                </div>
              </div>
            </div>

            {isMap && !isBurgerOpen ? <ActiveLayers /> : null}
          </>
        ) : null}
      </div>

      {isBurgerOpen ? (
        <HeaderBurger handleChangeSearch={handleChangeSearch} handleLogout={handleLogout} />
      ) : null}
    </div>
  );
};
