import { BASE_URL } from 'src/config';
import TokenService from 'src/services/TokenService';
import AuthService from 'src/services/AuthService';

export const request = async (url, params = {}, method = 'GET', headers = {}) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const token = TokenService.getLocalAccessToken();

      const options = {
        method,
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          ...headers,
        },
        ...(method !== 'GET' && { body: JSON.stringify(params) }),
      };

      const response = await fetch(`${BASE_URL}${url}`, options);

      if (method === 'DELETE') {
        resolve({});
      }

      const result = await response.json();

      if (!response.ok && url.includes('token/refresh')) {
        TokenService.removeUser();

        if (window.location.pathname !== '/') {
          window.location.href = '/';
        }
        reject({ message: 'refresh_error' });
      }

      if (result.code === 'token_not_valid') {
        const res = await AuthService.refresh();
        const refresh = TokenService.getLocalRefreshToken();
        // @ts-ignore
        TokenService.setTokens({ access: res.access, refresh });
        reject({ message: 'token_not_valid', retry: true });
      }

      resolve(result);
    } catch (e) {
      reject({ message: `req error - ${e?.message || ''}` });
    }
  });
};
