import React, { FC, useState } from 'react';
import { AppToolTip } from 'src/components/AppToolTip';
import { Comment } from 'src/components/Comment';
import { Button } from 'src/components/Button';
import { stateToHTML } from 'draft-js-export-html';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { useForm } from 'react-hook-form';
import { setFavoritePolygons, setSelectedPolygon } from 'src/store/polygons';
import { sleep } from 'src/utils';
import { geoJSONToCoords, getPolygonName, removeQueryParams } from 'src/utils/helpers';
import { Input } from 'src/components/Input';
import { setOpenedDropBlock } from 'src/store/common';
import { EditorState } from 'draft-js';
import { PolygonType } from 'src/config/types';
import PolygonsService from 'src/services/PolygonsService';

export const NewFavoritePolygonForm: FC = () => {
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<PolygonType>();

  const { selectedPolygon, favoritePolygons } = useAppSelector(state => state.polygons);

  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState<EditorState>(EditorState.createEmpty());

  const handleCancel = async e => {
    e?.stopPropagation();
    e?.preventDefault();
    await sleep(100);
    removeQueryParams();
    dispatch(setOpenedDropBlock({}));
    dispatch(setSelectedPolygon({ polygon: null }));
  };

  const onSubmit = async data => {
    setIsLoading(true);

    try {
      const isMulti = selectedPolygon?.geometry.includes('MULTIPOLYGON');
      const coordinates = geoJSONToCoords(selectedPolygon?.geometry || '');

      const params = {
        ...(selectedPolygon?.cadaster_number && {
          cadaster_number: selectedPolygon?.cadaster_number,
        }),
        ...(data?.name && {
          name: data?.name,
        }),
        ...(!selectedPolygon?.cadaster_number && {
          square: selectedPolygon?.square,
        }),
        geometry: {
          type: isMulti ? 'MultiPolygon' : 'Polygon',
          coordinates: isMulti ? [coordinates] : coordinates,
        },
        comments: stateToHTML(comment.getCurrentContent()),
        status: selectedPolygon?.status || 1,
      };

      const res = await PolygonsService.sendingToWork(params);

      dispatch(setSelectedPolygon({ polygon: null }));
      dispatch(setOpenedDropBlock({}));
      dispatch(setFavoritePolygons([...favoritePolygons, res]));
    } catch (e) {
      console.log(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      id="favorite-form"
      onSubmit={handleSubmit(onSubmit)}
      className="w-72 t-lg:w-96 bg-white dark:bg-blue-800 z-2400 p-3 t-lg:p-4 rounded-lg flex flex-col relative"
    >
      {selectedPolygon?.cadaster_number ? (
        <AppToolTip
          checkWidth
          text={getPolygonName(selectedPolygon)}
          classNames="font-gothampro-500 text-xs t-lg:text-sm dark:text-white truncate h-7"
        />
      ) : (
        <Input
          data={{ name: 'name', placeholder: 'Название участка', required: true }}
          errors={errors}
          register={register}
          classNames="w-full mb-3"
        />
      )}

      <div className="mb-1 font-gothampro-400 text-grey-500 text-extra-xs">Комментарий</div>
      <Comment comment={comment} disabled={isLoading} setComment={setComment} />

      <div className="flex gap-2 mt-4">
        <Button variant="blue" size="big" classNames="w-full max-w-[22rem]">
          Отправить в работу
        </Button>
        <Button size="big" variant="grey" onClick={handleCancel}>
          Отмена
        </Button>
      </div>
    </form>
  );
};
